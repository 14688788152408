/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

const responsive = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const circular = css`
  border-radius: var(--round-element-radius, 100%);
`

export const Image = props => <img {...props} />

export const ResponsiveImage = ({ customCss = [], ...props }) => <Image {...props} css={[responsive, ...customCss]} />

export const CircleImage = ({ customCss = [], ...props }) => <Image {...props} css={[circular, ...customCss]} />
