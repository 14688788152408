/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useMemo } from 'react'
import { flexColumnCentered } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'
import { Card } from './Card'
import { ResponsiveImage } from '../Image/Image'
import { CircleThumbnail } from '../Thumbnails/Thumbnails'
import { PartnersIcon } from '../Icons/Icons'

const baseCard = css`
  text-align: center;
  max-width: 230px;
  width: 100%;
  box-sizing: border-box;
  color: ${colors.gray3};
  font-size: 15px;
`

const defautMargin = css`
  margin: 5px 0 15px 0;
`
const grayBorder = css`
  border: 1px solid ${colors.gray9};
`

const titleStyle = css`
  font-size: 20px;
  font-weight: 500;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

const iconGreyBackground = css`
  background-color: ${colors.gray12};
  color: ${colors.gray3};
`

const initialsCss = css`
  font-size: 2rem;
`

const iconStyle = css`
  font-size: 32px;
  color: ${colors.gray4};
`

export const AvatarCard = ({ image, icon: Icon = PartnersIcon, title, description, children, initialsFallback = false, ...other }) => {
  const initials = useMemo(() => {
    if (!title) return ''

    const namesArray = title.split(' ')
    return namesArray.length > 1
      ? `${namesArray[0][0]}${namesArray[1][0]}`
      : `${namesArray[0][0]}${namesArray[0][1]}`
  }, [title])

  return (
    <Card css={[baseCard, flexColumnCentered]} {...other}>
      <CircleThumbnail css={[defautMargin, image ? grayBorder : iconGreyBackground, initialsFallback && initialsCss]} size={83}>
        {image
          ? <ResponsiveImage src={image} />
          : initialsFallback
            ? initials
            : <Icon css={iconStyle} />}
      </CircleThumbnail>
      <p css={[defautMargin, titleStyle]} className='AvatarCard__title' title={title}>{title}</p>
      {Boolean(description) && <p css={[defautMargin]} className='AvatarCard__description'>{description}</p>}
      {children}
    </Card>
  )
}
