export const normalizeString = (str = '', options) => {
  const { lowercase = true, removeSpaces = false } = options || {}

  const casedStr = lowercase ? str?.toLowerCase() : str

  const strWithSpacesNormalized = removeSpaces
    ? casedStr.replace(/\s*/g, '')
    : casedStr.replace(/\s+/g, ' ')

  return strWithSpacesNormalized
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}
