import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { GetListFlowMovementsParams, GetListFlowMovementsResponse } from '@/types/domains/FullFinancial'

const fullFinancialScheduleService = authenticatedService('v1/flow_movements')

const getListFlowMovements = async (
  searchParams: GetListFlowMovementsParams
): Promise<GetListFlowMovementsResponse> => {
  return await fullFinancialScheduleService.get(
    searchParams
  ).then(extractData)
}

fullFinancialScheduleService.setServiceMethods(
  { getListFlowMovements }
)

export const fullFinancialScheduleDomain = fullFinancialScheduleService.initialize
