/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Label } from '../Label/Label'
import { clickable, option } from '../../assets/styles/styles'
import { flexHorizontallyCentered } from '../../assets/styles/layout'

const style = css`
  ${option};
  border-radius: 5px;
`

export const Checkbox = ({ customCss = [], ...props }) => (
  <input type='checkbox' css={[style, ...customCss]} {...props} />
)

const label = css`
  font-weight: normal;
  user-select: none;
  text-align: left;
`

const setFirst = css`
  order: -1;
`

export const CheckboxInputGroup = ({ id, disabled, children, ...other }) => (
  <div css={flexHorizontallyCentered}>
    <Label css={disabled ? label : [label, clickable]} htmlFor={id}>
      {children}
    </Label>
    <Checkbox css={setFirst} id={id} disabled={disabled} {...other} />
  </div>
)
