import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import {
  RevenueListResponse,
  RevenueListSearchParams,
  PayloadRevenue,
  RevenueItem,
  RevenuesRes,
  RevenuesForgottenRes,
  RevenueAuditsParams,
  RevenueAuditsRes,
  AllowedStatus
} from '@/types/domains/Revenue'

const revenueService = authenticatedService('v1')

type ListParams = { searchParams: RevenueListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<RevenueListResponse> => {
  return revenueService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'revenues').then(extractData)
}

const create = (payload: PayloadRevenue): Promise<RevenueItem> => {
  return revenueService.post(payload, 'revenues').then(extractData)
}

const get = (revenueId: string): Promise<RevenueItem> => revenueService.get({}, `revenues/${revenueId}`).then(extractData)

const update = (revenue: PayloadRevenue, revenueId: string): Promise<RevenuesRes | any> => {
  return revenueService.patch(revenue, `revenues/${revenueId}`)
}

const getAllowedStatus = async (revenueId: string): Promise<AllowedStatus[]> => {
  return await revenueService.get({}, `revenues/${revenueId}/allowed_status`).then(extractData)
}

const getRevenueInitialStatus = (): Promise<Record<string, string>> => {
  return revenueService.get({}, 'revenues_initial_status').then(extractData)
}

const getRevenuesForgotten = async (): Promise<RevenuesForgottenRes> => {
  return await revenueService.get({}, 'revenues/forgotten').then(extractData)
}

const getRevenueAudits = async (params: RevenueAuditsParams, revenueId: string): Promise<RevenueAuditsRes> => {
  return await revenueService.get(params, `revenues/${revenueId}/audit`).then(extractData)
}

revenueService.setServiceMethods(
  {
    list,
    create,
    get,
    update,
    getAllowedStatus,
    getRevenueInitialStatus,
    getRevenuesForgotten,
    getRevenueAudits
  }
)

export const revenueDomain = revenueService.initialize
