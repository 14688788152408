import { css } from '@emotion/core'

export const contentStyle = css`
  width: 100%;
  margin: 20px 0 40px 0;
`

export const marginRight20 = css`
  margin-right: 20px;
`
