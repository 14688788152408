// @ts-check
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFragment React.Fragment */
import { css, jsx } from '@emotion/core'
import React from 'react'

const itemStyles = css`
  display: inline-grid;
  opacity: 1;
  transition: opacity 2s ease, width 1s 0.2s ease, margin 2s ease;
  overflow: visible;
  position: relative;
`
const hiddenStyles = css`
  width: 0;
  opacity: 0;
  pointer-events: none;
`
const caroselContainerStyles = css`
  display: flex;
`

const CarouselItem = React.Fragment

/**
 * @type { React.FC<{ children: JSX.Element[], itemsPerPage?: number, itemsWidth?: number, gap?: number, page: number, onWheel: function (number): void, disableControls?: boolean }> & { CarouselItem: React.FC } }
 */
const CarouselViewPort = ({
  children,
  itemsPerPage = 1,
  itemsWidth = 200,
  page = 0,
  onWheel = () => {},
  gap,
  disableControls
}) => {
  if (!Array.isArray(children)) {
    throw TypeError('Carousel expect multiple children')
  }

  const firstItem = page * itemsPerPage
  const lastItemExclusive = firstItem + itemsPerPage
  const rest = children.length % itemsPerPage
  const paddingItems =
    (lastItemExclusive + itemsPerPage >= children.length && rest > 0)
      ? itemsPerPage - rest
      : 0

  const items = children.concat(
    Array.from({ length: paddingItems }, () => <></>)
  )

  const toDOM = (x, i) => {
    const hidden = !(i < lastItemExclusive && i >= firstItem)
    const testID = hidden ? 'hidden' : 'active'
    return (
      <div
        css={[
          css`
            width: ${itemsWidth};
            margin-left: ${!hidden && !(i === firstItem) && gap}px;
          `,
          itemStyles,
          hidden && hiddenStyles
        ]}
        key={i}
        data-testid={testID}
      >
        {x?.props?.children || x}
      </div>
    )
  }
  return (
    <div
      className='carosel-container'
      css={[
        caroselContainerStyles,
        css`
          width: ${(itemsWidth + gap) * (itemsPerPage - 1) + itemsWidth - gap * 2}px;
        `,
        disableControls && { pointerEvents: 'none' }
      ]}
      onWheel={e => {
        e.preventDefault()
        const pagesToMove = Math.round(e.deltaY / 3)
        onWheel(pagesToMove)
      }}
    >
      {items.map(toDOM)}
    </div>
  )
}

CarouselViewPort.CarouselItem = CarouselItem

export { CarouselViewPort, CarouselItem }
