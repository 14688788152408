/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { containerHorizontalSteps, containerStep, stepStyle, stepTitle, dividerLine } from './HorizontalSteps.style'

const activeStepStyle = stepStyle(colors.primary, colors.white, colors.primary, '')
const pastStepsStyle = stepStyle(colors.primary, colors.white, colors.primary, 0.6)
const nextStepsStyle = stepStyle('', colors.gray1, colors.gray8, '')

const activeLabelStepStyle = stepTitle(colors.primary, '')
const pastLabelStepsStyle = stepTitle(colors.primary, 0.6)
const nextLabelStepsStyle = stepTitle(colors.gray6, '')

const Step = ({ number, title, noDividerLine, activeStep, pastStep }) => {
  return (
    <div css={containerStep}>
      <div css={(activeStep && activeStepStyle) || (pastStep && pastStepsStyle) || nextStepsStyle}>{number}</div>
      <small css={(activeStep && activeLabelStepStyle) || (pastStep && pastLabelStepsStyle) || nextLabelStepsStyle}>{title}</small>
      <div css={!noDividerLine && dividerLine} />
    </div>
  )
}

export const HorizontalSteps = ({ steps, activeStep, ...other }) => {
  return (
    <div css={containerHorizontalSteps} {...other}>
      {steps.map((item, index) =>
        <Step
          key={item}
          number={index + 1}
          title={item}
          noDividerLine={index === steps.length - 1}
          activeStep={index + 1 === activeStep}
          pastStep={index + 1 < activeStep}
        />
      )}
    </div>
  )
}
