import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { RefundListResponse, RefundListSearchParams } from '@/types/domains/Refund'

const refundService = authenticatedService('v1/pay')

type ListParams = { searchParams: RefundListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<RefundListResponse> => {
  return refundService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'refunds').then(extractData)
}

refundService.setServiceMethods({ list })

export const refundDomain = refundService.initialize
