import { authenticatedService } from '@/interfaces/authenticatedService'
import { SubordinatePayload } from '@/types/domains/Subordinate'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { convertToFormData } from '@/utils/form-data'

const subordinateService = authenticatedService('/v1/subordinates')

subordinateService.setServiceMethods({
  getSubordinate (props: { gateway?: string; partnerCompanyId?: string } = {}) {
    const { gateway = 'Braspag', partnerCompanyId = '' } = props
    const params = { gateway, partner_company_id: partnerCompanyId }
    return subordinateService.get({ ...cleanPayload(params) }).then(extractData)
  },
  createSubordinate (payload: SubordinatePayload) {
    return subordinateService.postFormData(convertToFormData(payload))
  }
})

export const subordinateDomain = subordinateService.initialize
