/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { weekDay } from './DayMonthSelector.style'
import {
  CalendarHeader,
  CalendarSelector,
  CalendarTable
} from '../Calendar.components'
import { buildWeekArray } from './DayMonthSelector.utils'
import { MONTH_LIST, DAY_OF_WEEK_LIST } from '../../../../constants/date'
import { SortDownIcon } from '../../../Icons/Icons'
import dayjs from 'dayjs'

const WeekDay = props => <td {...props} css={weekDay} />

const WeeksRow = ({ lang = 'pt_BR' }) => {
  const weekDayList = DAY_OF_WEEK_LIST[lang] || DAY_OF_WEEK_LIST.pt_BR

  return (
    <tr>
      {weekDayList.map(weekDay => <WeekDay key={weekDay}>{weekDay}</WeekDay>)}
    </tr>
  )
}

export const DayMonthHeader = ({
  onMonthChange: emitMonthChange = identity,
  onYearChange: emitYearChange = identity,
  onHeaderClick: emitHeaderClick = identity,
  selectedMonth = 0,
  selectedYear = 0,
  lang = 'pt_BR'
}) => {
  const moveNextMonth = () => {
    if (selectedMonth < 12) {
      emitMonthChange(selectedMonth + 1)
    } else {
      emitMonthChange(1)
      emitYearChange(selectedYear + 1)
    }
  }

  const movePreviousMonth = () => {
    if (selectedMonth > 1) {
      emitMonthChange(selectedMonth - 1)
    } else {
      emitMonthChange(12)
      emitYearChange(selectedYear - 1)
    }
  }

  const selectedMonthTitle = MONTH_LIST[lang]?.[selectedMonth - 1] || MONTH_LIST.pt_BR[selectedMonth - 1]

  return (
    <CalendarHeader
      onNextClick={moveNextMonth}
      onPreviousClick={movePreviousMonth}
      onHeaderClick={emitHeaderClick}
    >
      <span>
        <strong>{selectedMonthTitle}</strong> {selectedYear} <SortDownIcon />
      </span>
    </CalendarHeader>
  )
}

/**
 * @param { Date } a
 * @param { Date } b
 */
function dayGreaterThanOrEqual (a, b) {
  return dayjs(a).format('YYYYMMDD') >= dayjs(b).format('YYYYMMDD')
}

/**
 * @param { Date } a
 * @param { Date } b
 */
function dayLessThanOrEqual (a, b) {
  return dayjs(a).format('YYYYMMDD') <= dayjs(b).format('YYYYMMDD')
}

/**
 * @param { Date } a
 * @param { Date } b
 */
function isToday (date) {
  const today = dayjs()
  return (
    today.get('date') === date.getUTCDate() &&
    today.get('month') === date.getUTCMonth() &&
    today.get('year') === date.getUTCFullYear()
  )
}

export const CalendarDayMonthSelector = ({
  onDateChange: emitDateChange = identity,
  onMonthChange: emitMonthChange = identity,
  onYearChange: emitYearChange = identity,
  onHeaderClick: emitHeaderClick = identity,
  selectedDate,
  selectedMonth,
  selectedYear,
  minDate,
  maxDate,
  markToday = false,
  lang = 'pt_BR'
}) => {
  const [weeksList, setWeeksList] = useState([])
  const selectedDateISO = useMemo(() => selectedDate && dayjs(selectedDate).format('YYYY-MM-DD'), [selectedDate])
  const isSelectedDate = dayDate => selectedDateISO === dayjs(dayDate).format('YYYY-MM-DD')
  const isDisabled = dayDate => !dayGreaterThanOrEqual(dayDate, minDate) || !dayLessThanOrEqual(dayDate, maxDate)

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      setWeeksList(buildWeekArray(selectedYear, selectedMonth))
    }
  }, [selectedYear, selectedMonth])
  return (
    <React.Fragment>
      <DayMonthHeader
        onMonthChange={emitMonthChange}
        onHeaderClick={emitHeaderClick}
        onYearChange={emitYearChange}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        lang={lang}
      />
      <CalendarTable>
        <WeeksRow lang={lang} />
        {weeksList.map((weekDaysList, weekIndex) => (
          <tr key={`week-${weekIndex}`}>
            {weekDaysList.map((weekDay, dayIndex) => (
              <td key={`day-${weekIndex}-${dayIndex}`}>
                {weekDay.label && (
                  <CalendarSelector
                    selected={isSelectedDate(weekDay.date)}
                    onClick={() => emitDateChange(weekDay.date)}
                    disabled={isDisabled(weekDay.date)}
                    today={markToday && isToday(weekDay.date)}
                  >
                    {weekDay.label}
                  </CalendarSelector>
                )}
              </td>
            ))}
          </tr>
        ))}
      </CalendarTable>
    </React.Fragment>
  )
}
