import styled from '@emotion/styled'

export const CursorGlowContainer = styled.div`
   > *:first-child:hover {
    position: relative;

    ::before{
      background: radial-gradient(
        400px circle at var(--mouse-x) var(--mouse-y),
        rgba(255, 255, 255, 0.1),
        transparent 20%
      );
      border-radius: inherit;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
`
