/**
 * Binary version of [compose function]{@link compose} with type safety
 * ensured (in ts, or when using @ts-check directive).
 * @global
 * @param { function (B) : C } f
 * @param { function (A, ...AA): B } g
 * @returns { function (A, ...AA) : C }
 * @template A, AA, B, C
 */
export const compose = (f, g) => (x, ...xs) => f(g(x, ...xs))
