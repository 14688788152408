/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { colors } from '../../assets/styles/colors'
import { BREAK_POINTS } from '../Layout'

const navItem = css`
  cursor: pointer;
  padding: 20px 30px;
  background-color: ${colors.white};
  border: 0.5px solid ${colors.gray10};
  border-bottom-width: 1px;
  border-radius: 10px 10px 0 0;
  margin: 0 10px;
  color: ${colors.gray4};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  box-shadow: inset 0 -6px 6px -6px ${colors.gray10};
`

const selectedNavItem = css`
  margin-bottom: -1px;
  z-index: 3;
  border-bottom: ${colors.white};
  box-shadow: none;
`

const underlineNavItem = css`
  background: none;
  border: none;
  box-shadow: none;
  padding: 20px 5px;
  font-size: 18px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 12px;
  }
`

const underlineSelectedNavItem = css`
  color: ${colors.primary};
  border-bottom: 3px solid ${colors.primary};
`

export const NavItem = ({ navId = '', selected = false, onClick: emitClickEvent = identity, children, ...props }) => {
  const onClickNav = () => emitClickEvent(navId)

  return (
    <div css={[navItem, selected && selectedNavItem]} onClick={onClickNav} {...props}>
      {children}
    </div>
  )
}

export const UnderlineNavItem = ({ navId = '', selected = false, onClick: emitClickEvent = identity, children, ...props }) => {
  return (
    <NavItem
      css={[underlineNavItem, selected && underlineSelectedNavItem]}
      navId={navId}
      selected={selected}
      onClick={emitClickEvent}
      {...props}
    >
      {children}
    </NavItem>
  )
}
