/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../../assets/styles/colors'
import { Row } from '../../../Layout/Row/Row'
import PropTypes from 'prop-types'

const linkColor = css`
  color: ${colors.gray1};
  padding: 5px;
`

const linkMargin = css`
  font-size: 21px;
  margin-right: 15px;
`

const linkTitle = css`
  display: flex;
  align-items: center;
`

export const UserTooltipLink = ({
  label = '',
  icon: Icon = () => null
}) => (
  <Row css={linkColor}>
    <div css={linkMargin}>
      <Icon />
    </div>
    <div css={linkTitle}>{label}</div>
  </Row>
)

UserTooltipLink.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node
}

UserTooltipLink.defaultProps = {
  label: '',
  icon: () => null
}
