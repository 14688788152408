/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { flex } from '../../assets/styles/layout'
import { marginBottom } from '../../assets/styles/global'
import { ContactPhone } from './ContactPhone/ContactPhone'
import { AddLabel } from '../AddLabel/AddLabel'
import { identity } from '@bonitour/common-functions'

const marginBottom20 = marginBottom(20)
const marginBottom30 = marginBottom(30)

const emptyPhone = { number: '' }

export const ContactForm = ({
  phones: phoneList = [],
  phonesErrors = [],
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  onPhoneRemove: emitPhoneRemove = identity,
  onPhoneAdd: emitPhoneAdd = identity,
  maxPhones,
  flag
}) => {
  const addPhone = () => emitPhoneAdd(emptyPhone)
  const removePhone = index => () => emitPhoneRemove(index)
  return (
    <>
      <div css={marginBottom20}>
        {phoneList.map((phone, index) => (
          <ContactPhone
            key={`contact-phone-${index}`}
            contact={phone}
            errors={phonesErrors[index]}
            index={index}
            onBlur={emitBlurEvent}
            onChange={emitChangeEvent}
            onDelete={removePhone(index)}
            flag={flag}
          />
        ))}
      </div>
      {(maxPhones > phoneList.length) &&
        <AddLabel css={[flex, marginBottom30]} onClick={addPhone}>
          Novo telefone
        </AddLabel>}
    </>
  )
}
