/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CSSTransition } from 'react-transition-group'

const slideAnimation = (classNames, duration) => css`
  left: 0;
  transition: transform ${duration}ms linear;
  transform: translateX(-100%);

  &.${classNames}-enter-active {
    transform: translateX(0);
  }
  &.${classNames}-enter-done {
    transform: translateX(0);
  }
  &.${classNames}-exit-active {
    transform: translateX(-100%);
  }
`

export const SlideInOut = ({
  in: inProp,
  timeout: duration = 250,
  classNames = 'slide',
  children
}) => (
  <CSSTransition
    in={inProp}
    timeout={duration}
    classNames={classNames}
    css={slideAnimation(classNames, duration)}
  >
    {children}
  </CSSTransition>
)
