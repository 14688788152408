import { css } from '@emotion/core'
import { scrollBar } from '../../assets/styles/addons'
import { colors } from '../../assets/styles/colors'
import { marginRight } from '../../assets/styles/global'

const marginRight10 = marginRight(10)
export const width200 = css`
  width: 200px;
`

export const exportStyle = css`
  display: flex;
  flex-direction: column;
`

export const exportText = css`
  font-size: 14px;
  text-align: left;
  line-height: 1.25;
  color: ${colors.gray5};
`

export const scheduleSelect = css`
  width: 100%;
  cursor: pointer;
`

export const multiSelectStyle = css`
  ${width200};
`

export const collapsableIcon = css`
  ${marginRight10};
  transition: 0.2s transform ease-in;
`

export const collapsableIconOpen = css`
  ${marginRight10};
  transform: rotate(90deg);
`

export const multiSelectCardStyle = css`
  margin-top: 15px;
  padding: 15px;
`

export const cardTitle = css`
  text-align: left;
  font-size: 16px;
  margin-bottom: 5px;
`

export const selectStyle = css`
  overflow-y: auto;
  width: 100%;
  height: 150px;
  ${scrollBar(5)};
  padding: 10px 0;
`

export const exportButtonStyle = css`
  margin-top: 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
  width: 100%;
`

export const baseExportToggle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const exportIconStyle = css`
  margin-right: 7px;
  margin-top: 2px;
  color: ${colors.primary};

  i {
    font-weight: bold;

    ::before {
      color: ${colors.primary};
    }
  }
`

export const exportAllButtonStyle = css`
  margin-top: 15px;
`

export const exportAllIconStyle = css`
  margin-right: 7px;
  margin-top: 2px;
  color: ${colors.white};

  i {
    font-weight: bold;
  }

  i::before {
    color: ${colors.white};
  }
`
export const multiSelectInput = css`
  cursor: pointer;
  background-color: ${colors.white} !important;
  pointer-events: none;
`

export const link = css`
  color: ${colors.primary};
`
