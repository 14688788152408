import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const containerHorizontalSteps = css`
  display: flex;
  flex-direction: row;
`

export const containerStep = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 4em;
  position: relative;
`

export const stepStyle = (backgrounColor, numberColor, borderColor, opacity) => css`
  background-color: ${backgrounColor};
  color: ${numberColor};
  width: 38px;
  height: 38px;
  border-radius: var(--round-element-radius, 360px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border: 1px solid ${borderColor};
  opacity: ${opacity};
  font-size: 18px;
`

export const stepTitle = (color, opacity) => css`
  color: ${color};
  font-size: 10px;
  opacity: ${opacity};
  width: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
`

export const dividerLine = css`
  height: 1px;
  background-color: ${colors.gray8};
  color: red;
  position: absolute;
  top: 20px;
  left: 50px;
  width: 65px;
`
