import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  BankAccountBillingListResponse,
  BankAccountBillingParams,
  BankAccountBillingItem,
  PayloadBankAccount,
  BankAccountAuditParams,
  BankAccountAuditRes
} from '@/types/domains/BankAccountBilling'

const bankAccountBillingService = authenticatedService('v1/bank_accounts')

const list = (): Promise<BankAccountBillingListResponse> => {
  return bankAccountBillingService.get({}).then(extractData)
}

const listPaginated = async (
  { pagination }: BankAccountBillingParams
): Promise<BankAccountBillingListResponse> => {
  return await bankAccountBillingService.get({
    ...pagination
  }).then(extractData)
}

const get = (bankAccountId: string): Promise<BankAccountBillingItem> => {
  return bankAccountBillingService.get({}, bankAccountId).then(extractData)
}

const update = (
  bankAccount: PayloadBankAccount, bankAccountId: string): Promise<BankAccountBillingItem> => {
  return bankAccountBillingService.patch(bankAccount, bankAccountId)
}

const getBankAccountAudit = async (params: BankAccountAuditParams, bankAccountId: string): Promise<BankAccountAuditRes> => {
  return await bankAccountBillingService.get(params, `/${bankAccountId}/audit`).then(extractData)
}

bankAccountBillingService.setServiceMethods({ list, listPaginated, get, update, getBankAccountAudit })

export const bankAccountBillingDomain = bankAccountBillingService.initialize
