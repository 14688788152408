import { css } from '@emotion/core'
import { BREAK_POINTS } from '../../Layout/Layout.style'

export const tableBodyCellFixed = css`
  position: sticky;
  right: 0;
  
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    padding: 5px;
    box-shadow: -3px 0px 15px -5px rgb(0 0 0 / 25%);
  }
`
