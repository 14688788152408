/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'

const hidden = { display: 'none' }

export const ConditionalView = ({ isVisible = false, children }) => {
  const [mayRender, setMayRender] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setMayRender(true)
    }
  }, [isVisible])

  if (!mayRender) {
    return null
  }

  return React.Children.map(
    children,
    child => React.cloneElement(child, { ...child.props, style: { ...child.props.style, ...(isVisible ? {} : hidden) } })
  )
}
