import { authenticatedService } from '@/interfaces/authenticatedService'
import { GetCashDepositsParams, GetCashDepositsRes } from '@/types/domains/CashDeposit'
import { extractData } from '@/utils/data-extractor'

const cashDepositsService = authenticatedService('v1/cash_deposits')

const getCashDeposits = async (
  searchParams: GetCashDepositsParams
): Promise<GetCashDepositsRes> => {
  return await cashDepositsService.get(searchParams, '').then(extractData)
}

cashDepositsService.setServiceMethods({ getCashDeposits })

export const cashDepositDomain = cashDepositsService.initialize
