import { authenticatedService } from '@/interfaces/authenticatedService'
import { ISOTime } from '@/types/domains/Abstractions'
import { extractData } from '@/utils/data-extractor'
import { LockedServiceResponse } from '../../types/domains/Service'

const serviceService = authenticatedService('/v1/services')

function get () {
  return serviceService.get()
}

function getById (serviceId: string) {
  return serviceService.get({}, serviceId)
}

function getDailyMonitoringReservations (serviceId: string, date: ISOTime) {
  return serviceService.get({ date }, `${serviceId}/daily_monitoring_reservations`).then(extractData)
}

function getAdditionalInformation () {
  return serviceService.get({}, '/additional_information').then(extractData)
}

const getIsLockedService = (serviceId: string): Promise<LockedServiceResponse> => serviceService.get({}, `${serviceId}/locked`).then(extractData)

serviceService.setServiceMethods({ get, getById, getDailyMonitoringReservations, getAdditionalInformation, getIsLockedService })

export const serviceDomain = serviceService.initialize
