/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CSSTransition } from 'react-transition-group'

const fadeAnimation = (classNames, duration) => css`
  transition: opacity ${duration}ms linear;
  opacity: 0;
  visibility: hidden;

  &.${classNames}-enter-active {
    opacity: 1;
    visibility: visible;
  }
  &.${classNames}-enter-done {
    opacity: 1;
    visibility: visible;
  }
  &.${classNames}-exit-active {
    opacity: 0;
    visibility: visible;
  }
`

export const FadeInOut = ({
  in: inProp,
  timeout: duration = 150,
  classNames = 'fade',
  children,
  ...other
}) => (
  <CSSTransition
    in={inProp}
    timeout={duration}
    classNames={classNames}
    css={fadeAnimation(classNames, duration)}
    {...other}
  >
    {children}
  </CSSTransition>
)
