import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import {
  PaymentListResponse,
  PaymentListSearchParams
} from '@/types/domains/Payment'

const paymentService = authenticatedService('v1/pay')

type ListParams = { searchParams: PaymentListSearchParams; pagination: MetaOnRequest }

const getById = (id: string, gateway: string): Promise<any> => paymentService.get({}, `payment?external_payment_id=${id}&gateway=${gateway}`).then(extractData)

const list = async ({ searchParams, pagination }: ListParams): Promise<PaymentListResponse> => {
  return paymentService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'payments').then(extractData)
}

paymentService.setServiceMethods({
  list,
  getById
})

export const paymentDomain = paymentService.initialize
