import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { Alert, AlertRes } from '@/types/domains/Alert'
import { cleanPayload } from '@/utils/clean-payload'

const alertService = authenticatedService('v1/alert_configurations')

const list = (companyId: string): Promise<any> => {
  const params = { company_id: companyId }
  return alertService.get({ ...cleanPayload(params) }).then(extractData)
}

const create = (alert: Alert): Promise<AlertRes | any> => {
  return alertService.post(alert).then(extractData)
}

const update = (alert: Alert, alertId: string): Promise<AlertRes | any> => {
  return alertService.patch(alert, alertId)
}

alertService.setServiceMethods({ list, create, update })

export const alertDomain = alertService.initialize
