/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { marginBottom } from '../../assets/styles/global'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'
import { H1 } from '../Headings/Headings'
import { ArrowThickLeftIcon } from '../Icons/Icons'

const marginBottom20 = marginBottom(20)

const backButton = css`
  font-size: 26px;
  margin-right: 10px;
  color: ${colors.gray1};
  cursor: pointer;
`

const headerPageContainer = css`
  display: flex;
  flex-wrap: wrap;
`

const titleStyle = css`
  margin-bottom: 0;
  text-overflow: ellipsis;
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3;
`

const subtitleStyle = css`
  font-size: 16px;
  color: ${colors.gray3};
  font-weight: 500;
  margin-top: -4px;
`

export function HeaderPage ({ onBack, title, subtitle, children = '', customCss = [] }) {
  return (
    <div css={[flexHorizontallyCentered, headerPageContainer, marginBottom20, ...customCss]}>
      <ArrowThickLeftIcon css={backButton} onClick={onBack} />
      <H1 css={titleStyle}>
        {title}
        {subtitle && <p css={subtitleStyle}>{subtitle}</p>}
      </H1>
      {children}
    </div>

  )
}
