import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'

export const dayWidth = '36px'
export const calendar = css`
  width: 340px;
`

export const body = css`
  table-layout: fixed;
  width: 100%;
`

export const header = css`
  justify-content: stretch;
  padding: 10px 15px 15px;
  color: ${colors.gray1};
`

export const titleContainer = css`
  font-size: 25px;
  flex-grow: 1;
  text-align: center;

  span {
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;

    i {
      margin-left: 5px;
      font-size: 15px;
    }

    &:hover {
      color: ${colors.white};
      background-color: ${colors.primaryVariant};
    }
  }

  strong {
    font-weight: 500;
  }
`

export const selectedStyle = css`
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.primary};
  box-shadow: 0 0 10px #00000033;
`

export const disabledStyle = css`
  color: ${colors.gray8};

  &:hover {
    color: ${colors.gray8};
    background-color: transparent;
    box-shadow: unset;
    cursor: unset;
  }
`

export const todayStyle = css`
  border: 1px solid tomato;
`

export const boxInsideTableData = css`
  padding: 5px;
`

export const labelInsideTableDate = css`
  text-align: center;
  color: ${colors.gray4};
  display: block;
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
  border-radius: var(--round-element-radius, 50%);
  width: ${dayWidth};
  height: ${dayWidth};
  user-select: none;

  &:hover {
    ${selectedStyle}
  }
`
