import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractInnerData } from '@/utils/data-extractor'

const categoryService = authenticatedService('/v1/categories')

const list = () => categoryService.get().then(extractInnerData)

categoryService.setServiceMethods({ list })

export const categoryDomain = categoryService.initialize
