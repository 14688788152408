/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { container, indexesContainer, indexActive, defaultCursor } from './Pagination.style'
import { useMemo } from 'react'

function PaginationItem ({ children, isActive = false, isClickable = true, ...other }) {
  return (
    <span
      {...other}
      aria-current={isActive}
      css={[isActive && indexActive, isClickable || defaultCursor]}
    >
      {children}
    </span>
  )
}

const generateLowerBound = (position = 1, range = 1) => {
  const maxRange = position - range
  const lowerBound = []
  if (position !== 1) {
    lowerBound.push(1)
  }
  if (maxRange > 1) {
    lowerBound.push('...')
  }
  for (let index = range; index > 0; index -= 1) {
    const newPosition = position - index
    if (newPosition > 1) {
      lowerBound.push(newPosition)
    }
  }
  return lowerBound
}

const generateUpperBound = (position = 1, range = 1, max = 1) => {
  const maxRange = position + range
  const upperBound = []
  for (let index = 1; index <= range; index += 1) {
    const newPosition = position + index
    if (newPosition < max) {
      upperBound.push(newPosition)
    }
  }
  if (maxRange < max) {
    upperBound.push('...')
  }
  if (position !== max) {
    upperBound.push(max)
  }
  return upperBound
}

const buildPages = (position, range, max) => generateLowerBound(position, range).concat(position).concat(generateUpperBound(position, range, max))

export const Pagination = ({
  quantity = 1,
  active = 1,
  itemRange = 2,
  onChange: emitChangeEvent = identity,
  ...props
}) => {
  const availablePages = useMemo(() => buildPages(active, itemRange, quantity), [active, itemRange, quantity])
  const onClick = index => () => emitChangeEvent(index)

  if (!quantity) {
    return null
  }

  return (
    <nav role='navigation' aria-label='Pagination Navigation' css={container} {...props}>
      <span>Página</span>

      <div css={indexesContainer}>
        {
          availablePages.map((page, index) => {
            const isClickable = page !== '...'
            return (
              <PaginationItem
                key={isClickable ? page : `ellipsis-on-${index}`}
                isActive={page === active}
                aria-label={isClickable ? `Va para página ${page}` : 'Intervalo entre páginas'}
                onClick={isClickable ? onClick(page) : () => {}}
                isClickable={isClickable}
              >
                {page}
              </PaginationItem>
            )
          })
        }
      </div>
    </nav>
  )
}
