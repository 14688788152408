import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { BankAccount, BankAccountByPaymentMethodResponse, BankAccountRes } from '@/types/domains/BankAccount'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { cleanPayload } from '@/utils/clean-payload'

const bankAccountService = authenticatedService('v1/bank_accounts')
const bankAccountByPaymentMethodService = authenticatedService('v1/bank_accounts_by_payment_method')

const create = (bankAccount: BankAccount): Promise<BankAccountRes | any> => {
  return bankAccountService.post(bankAccount).then(extractData)
}

const list = (pagination: MetaOnRequest): Promise<any> => {
  return bankAccountService.get({ ...pagination }).then(extractData)
}

const getById = (bankAccountId: string): Promise<BankAccountRes | any> => {
  return bankAccountService.get({}, bankAccountId).then(extractData)
}

const update = (bankAccount: BankAccount, bankAccountId: string): Promise<BankAccountRes | any> => {
  return bankAccountService.patch(bankAccount, bankAccountId)
}

const toggleEnabled = (bankAccountId: string): Promise<BankAccountRes | any> => {
  return bankAccountService.patch({}, `${bankAccountId}/toggle_enabled`)
}

const getBankAccountByPaymentMethod = (paymentMethod: string, companyId: string): Promise<BankAccountByPaymentMethodResponse> => {
  const params = { payment_method: paymentMethod, company_id: companyId }
  return bankAccountByPaymentMethodService.get({ ...cleanPayload(params) }, '').then(extractData)
}

bankAccountService.setServiceMethods({ create, list, getById, update, toggleEnabled })
bankAccountByPaymentMethodService.setServiceMethods({ getBankAccountByPaymentMethod })

export const bankAccountDomain = bankAccountService.initialize
export const bankAccountByPaymentMethodDomain = bankAccountByPaymentMethodService.initialize
