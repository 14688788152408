import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { VoucherSearchParams, VoucherPayload, VoucherTotalsPayload } from '@/types/domains/VoucherReport'

const voucherReportService = authenticatedService('v1/vouchers_report')
const voucherReportTotalsService = authenticatedService('v1/vouchers_report/totalizers')

type ListParams = { searchParams: VoucherSearchParams; pagination: MetaOnRequest }

const listVouchers = ({ searchParams, pagination }: ListParams): Promise<VoucherPayload> => {
  return voucherReportService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const totals = ({ searchParams }: ListParams): Promise<VoucherTotalsPayload> => {
  return voucherReportTotalsService.get({
    ...cleanPayload(searchParams)
  }).then(extractData)
}

voucherReportService.setServiceMethods({ listVouchers })
voucherReportTotalsService.setServiceMethods({ totals })

export const voucherReportDomain = voucherReportService.initialize
export const voucherReportTotalsDomain = voucherReportTotalsService.initialize
