/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { CalendarHeader, CalendarTable, CalendarSelector } from '../Calendar.components'
import { SortUpIcon } from '../../../Icons/Icons'
import { MONTH_MAPPED_ABREV_LIST, MONTH_ABREV_LIST } from '../../../../constants/date'

export const MonthHeader = ({
  onHeaderClick: emitHeaderClick = identity,
  lang = 'pt_BR'
}) => {
  const headerLabel = useMemo(() => {
    const dictionary = MONTH_ABREV_LIST[lang] || MONTH_ABREV_LIST.pt_BR

    const firstMonth = dictionary[0]
    const lastMonth = dictionary[dictionary.length - 1]

    return `${firstMonth} - ${lastMonth}`
  }, [lang])

  return (
    <CalendarHeader onHeaderClick={emitHeaderClick}>
      <span>
        <strong>{headerLabel}</strong>
        <SortUpIcon />
      </span>
    </CalendarHeader>
  )
}

export const CalendarMonthSelector = ({
  onMonthChange: emitMonthChange = identity,
  onHeaderClick: emitHeaderClick = identity,
  selectedMonth,
  lang = 'pt_BR'
}) => {
  const monthMappedAbrevList = MONTH_MAPPED_ABREV_LIST[lang] || MONTH_MAPPED_ABREV_LIST.pt_BR

  return (
    <>
      <MonthHeader
        onHeaderClick={emitHeaderClick}
        lang={lang}
      />
      <CalendarTable>
        {monthMappedAbrevList.map((monthsList, lineIndex) => (
          <tr key={`months-${lineIndex}`}>
            {monthsList.map((month, columnIndex) => {
              const monthNumber = (lineIndex * 4) + (columnIndex + 1)
              return (
                <td key={`month-${lineIndex}-${columnIndex}`}>
                  <CalendarSelector
                    selected={monthNumber === selectedMonth}
                    onClick={() => emitMonthChange(monthNumber)}
                  >
                    {month}
                  </CalendarSelector>
                </td>
              )
            })}
          </tr>
        ))}
      </CalendarTable>
    </>
  )
}
