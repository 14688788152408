/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import { useMemo } from 'react'
import { CloseIcon } from '../Icons/Icons'

import {
  bodyAdjustForOverlay,
  contentContainer,
  mainContainer,
  mobileSelectHeader
} from './MobileDrawer.style'

/**
 * @desc Full Cover Menu
 *
 * @typedef MobileDrawerProps
 * @prop { string } title - The name above the drawer
 * @prop { boolean } isOpen
 * @prop { () => void } handleClose
 * @prop { 'top' | 'bottom' | 'right' | 'left' } [popOrigin]
 * @prop { import('@emotion/core').ArrayInterpolation } [customCss]
 */

/**
 * @desc Full Cover Menu for Mobile use
 *
 * @type { React.FC<MobileDrawerProps> }
 */
export const MobileDrawer = ({
  title = 'Please set Title param',
  isOpen = false,
  handleClose = () => { console.error('Need handleClose param') },
  popOrigin = 'bottom',
  customCss = [],
  children
}) => {
  const popAnimation = useMemo(() => {
    switch (popOrigin) {
    case 'top':
      return {
        start: css`transform: translateY(-100vh);`,
        end: css`transform: translateY(0vh);`
      }

    case 'bottom':
      return {
        start: css`transform: translateY(100vh);`,
        end: css`transform: translateY(0vh);`
      }

    case 'left':
      return {
        start: css`transform: translateX(-100vw);`,
        end: css`transform: translateX(0vw);`
      }

    case 'right':
      return {
        start: css`transform: translateX(100vw);`,
        end: css`transform: translateX(0vw);`
      }

    default:
      return {
        start: css`transform: translateY(-100vh);`,
        end: css`transform: translateY(0vh);`
      }
    }
  }, [popOrigin])

  return (
    <div css={[
      mainContainer,
      popAnimation.start,
      isOpen && popAnimation.end,
      ...customCss
    ]}
    >
      <button
        css={mobileSelectHeader}
        type='button'
        onClick={handleClose}
        className='component-mobile-drawer-header'
      >
        <CloseIcon />
        <span>{title}</span>
      </button>
      <div
        css={contentContainer}
        className='component-mobile-drawer-content'
      >
        {children}
      </div>
      {isOpen && (<Global styles={bodyAdjustForOverlay} />)}
    </div>
  )
}
