import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { BREAK_POINTS } from '../Layout'

export const noMargin = css`
  margin: 0;
`

export const backdrop = css`
  width: 100%;
  height: calc(100% + 60px);
  z-index: 10;
`

export const container = css`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0px 20px;
  width: calc(100% - 40px);
  border-radius: 10px 10px 0 0;
  background-color: ${colors.white};
  z-index: 15;
`

export const content = css`
  margin: 0 60px 30px 60px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin: 20px;
  }
`

export const header = css`
  margin: 30px 60px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin: 20px;
  }
`

export const closeIcon = css`
  font-size: 24px;
  color: ${colors.gray3};
  cursor: pointer;
  background-color: ${colors.primary};
  border-radius: var(--small-round-element-radius, 50%);
  height: 30px;
  width: 30px;
  min-width: 30px;
  color: ${colors.white};
  font-size: 17px;
  position: absolute;
  right: 70px;
  z-index: 1;
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    right: 20px;
  }
`

export const transition = (className, duration) => css`
  transition: transform ${duration}ms linear;

  &.${className}-enter {
    transform: translateY(100%);
  }
  &.${className}-enter-active {
    transform: translateY(0);
  }
  &.${className}-exit-active {
    transform: translateY(100%);
  }
`
