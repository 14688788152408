/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { BellThickIcon } from '../../../Icons/Icons'
import { colors } from '../../../../assets/styles/colors'

const iconContainer = css`
  position: relative;
  font-size: 20px;
`

const hasNotificationMenu = css`
  background-color: ${colors.primary};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
`

export const NotificationIcon = ({ hasNotification }) => (
  <div css={iconContainer}>
    <BellThickIcon />
    {hasNotification && <span css={hasNotificationMenu} />}
  </div>
)

NotificationIcon.propTypes = {
  hasNotification: PropTypes.bool
}

NotificationIcon.defaultProps = {
  hasNotification: false
}
