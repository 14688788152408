import { authenticatedService } from '@/interfaces/authenticatedService'
import { PaymentProviderRes } from '@/types/domains/PaymentProvider'
import { extractData } from '@/utils/data-extractor'

const paymentProvidersService = authenticatedService('v1/payment_providers')

const listProviders = async (): Promise<PaymentProviderRes> => {
  return paymentProvidersService.get({}).then(extractData)
}

paymentProvidersService.setServiceMethods({ list: listProviders })

export const paymentProvidersDomain = paymentProvidersService.initialize
