/** @jsxRuntime classic */
/** @jsx jsx */
import { compose } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { Fragment } from 'react'

import { DangerAlert, SuccessAlert, LightInfoAlert, WarningAlert, InfoAlert } from '../Alert/Alert'

import { GhostButton, Button } from '../Buttons/Buttons'

import {
  closeIcon,
  progressBarLowerLayer,
  progressBarUpperLayer,
  toastContent,
  closeGrayIcon,
  continueButton,
  cancelButton
} from './Toast.style'

global.React = { Fragment }

const ToastProgressBar = ({ duration = 5000 }) => {
  const durationOnSeconds = `${duration / 1000}s`
  return (
    <>
      <div css={progressBarLowerLayer} style={{ animationDuration: durationOnSeconds }} />
      <div css={progressBarUpperLayer} className='color_progressbar_variant' style={{ animationDuration: durationOnSeconds }} />
    </>
  )
}

const ToastContentWrapper = ({ duration = 5000, remove = () => {}, children }) => {
  return (
    <>
      <div css={toastContent}>{children}</div>
      <button css={closeIcon} onClick={remove}>
        <i className='b-font-close b-font-close-danger' />
      </button>
      <ToastProgressBar duration={duration} />
    </>
  )
}

export const DangerToast = props => (
  <DangerAlert>
    <ToastContentWrapper {...props} />
  </DangerAlert>
)

export const SuccessToast = props => (
  <SuccessAlert>
    <ToastContentWrapper {...props} />
  </SuccessAlert>
)

export const InfoToast = props => (
  <InfoAlert>
    <ToastContentWrapper {...props} />
  </InfoAlert>
)

export const LightInfoToast = props => (
  <LightInfoAlert css={closeGrayIcon}>
    <ToastContentWrapper {...props} />
  </LightInfoAlert>
)

export const WarningToast = props => (
  <WarningAlert>
    <ToastContentWrapper {...props} />
  </WarningAlert>
)

export const ConfirmToast = ({
  children,
  cancel,
  confirm,
  remove,
  confirmLabel = 'Continuar',
  cancelLabel = 'Desfazer'
}) => (
  <WarningAlert>
    <div css={toastContent}>{children}</div>
    <GhostButton css={cancelButton} className='cancelButtonVariant' onClick={compose(cancel, remove)}>
      {cancelLabel}
    </GhostButton>
    <Button css={continueButton} className='confirmButtonVariant' onClick={compose(confirm, remove)}>
      {confirmLabel}
    </Button>
  </WarningAlert>
)
