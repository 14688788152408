import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { MyDayPayload, MyDaySearchParams, MyDayTotalizersPayload } from '@/types/domains/MyDay'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'

const myDayService = authenticatedService('v2/tickets/daily_monitoring')
const myDayTotalizersService = authenticatedService('v2/tickets/daily_monitoring_totalizers')

type ListParams = { searchParams: MyDaySearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<MyDayPayload> => {
  return myDayService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then((data: any) => {
    return extractData(data)
  })
}

const csvExport = ({ searchParams, pagination }: ListParams): Promise<string> => {
  return myDayService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'csv').then(extractData)
}

const xlsxExport = ({ searchParams, pagination }: ListParams): Promise<string> => {
  return myDayService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'xlsx', {
    responseType: 'arraybuffer'
  }).then(extractData)
}

const totals = ({ searchParams }: ListParams): Promise<MyDayTotalizersPayload> => {
  return myDayTotalizersService.get({
    ...cleanPayload(searchParams)
  }).then(extractData)
}

myDayService.setServiceMethods({ list, csvExport, xlsxExport })
myDayTotalizersService.setServiceMethods({ totals })

export const myDayDomain = myDayService.initialize
export const myDayTotalizersDomain = myDayTotalizersService.initialize
