/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { flexCenteredSpaceBetweenRow, flexColumnCentered } from '../../assets/styles/layout'
import { Button, GhostButton } from '../Buttons/Buttons'
import { marginRight20, messageStyle } from './ConfirmDialog.style'
import { Dialog } from '../Dialog/Dialog'

export const ConfirmDialog = ({
  title = '',
  message = '',
  buttonLabelConfirm = 'Confirmar',
  buttonLabelCancel = 'Cancelar',
  isVisible = false,
  successCallback = identity,
  cancelCallback = identity,
  onClose = null,
  className = 'confirmDialog',
  children,
  customButtonCss = [],
  customCss = [],
  ...other
}) => {
  return (
    <Dialog
      isVisible={isVisible}
      onClose={onClose || cancelCallback}
      title={title}
      className={className}
      customButtonCss={customButtonCss}
      {...other}
    >
      <div css={[flexColumnCentered, ...customCss]}>
        {children}
        {message && <span css={messageStyle}>{message}</span>}
        <div css={flexCenteredSpaceBetweenRow}>
          <GhostButton css={[marginRight20, ...customButtonCss]} onClick={cancelCallback}>{buttonLabelCancel}</GhostButton>
          <Button css={customButtonCss} onClick={successCallback}>{buttonLabelConfirm}</Button>
        </div>
      </div>
    </Dialog>
  )
}
