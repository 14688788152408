/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { genericContainer } from './Layout.style'

export const navbarContainer = css`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background: ${colors.white};
  flex: 0 1 auto;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px -2px 6px #00000029;
  z-index: 5;
`

const headerContainer = css`
  ${genericContainer}
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Header = ({ children, customCss = [], ...props }) => (
  <nav {...props} css={[navbarContainer, ...customCss]}>
    <div css={headerContainer}>
      {children}
    </div>
  </nav>
)
