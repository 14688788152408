import React, { useEffect, useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { DangerToast, SuccessToast, InfoToast, LightInfoToast, WarningToast, ConfirmToast } from '../Toast/Toast'

const typeRelation = {
  danger: DangerToast,
  success: SuccessToast,
  info: InfoToast,
  'light-info': LightInfoToast,
  warning: WarningToast,
  confirm: ConfirmToast
}

const Toast = ({
  type,
  children,
  duration,
  remove,
  confirm = identity,
  cancel = identity,
  confirmLabel = 'Continuar',
  cancelLabel = 'Desfazer'
}) => {
  const ToastComponent = typeRelation[type] || DangerToast
  const removeRef = useRef()
  removeRef.current = remove

  useEffect(() => {
    const id = setTimeout(() => removeRef.current(), duration)
    return () => clearTimeout(id)
  }, [duration])

  return (
    <ToastComponent
      duration={duration}
      remove={remove}
      confirm={confirm}
      cancel={cancel}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
    >
      {children}
    </ToastComponent>
  )
}

export const ToastsWrapper = ({ toasts, remove, cssStyle }) => {
  return (
    <div css={cssStyle}>
      {toasts.map(t => (
        <Toast
          key={t.id}
          remove={() => remove(t.id)}
          duration={t.duration}
          type={t.type}
          confirm={t.confirm}
          cancel={t.cancel}
          confirmLabel={t.confirmLabel}
          cancelLabel={t.cancelLabel}
        >
          {t.content}
        </Toast>
      ))}
    </div>
  )
}
