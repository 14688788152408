import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const horizontalPadding = '20px'

const focusBorderColor = `var(
    --highlight-text--primary, var(
      --clr-primary, var(
        --primary-color, ${colors.indigo2}
      )
    )
  )`

export const disabled = css`
  background-color: ${colors.gray11};
  -webkit-text-fill-color: ${colors.gray6};
  color: ${colors.gray6};
  &:focus-visible {
    border-color: ${colors.gray7};
  }
`

// +1px font-size to fix input height
export const baseStyle = css`
  font-size: 15px;
  color: ${colors.gray3};
  font-family: inherit;
  border: 1px solid ${colors.gray8};

  padding: 10px ${horizontalPadding};
  margin: 0;
  box-sizing: border-box;

  min-width: 50px;

  &::placeholder{
    color: ${colors.gray6};
    opacity: 1;
  }

  &:read-only {
    ${disabled}
  }

  &:disabled {
    ${disabled}
  }

  &:focus-visible {
    border-color: ${focusBorderColor};
  }
`

export const errorInput = css`
  border-color: ${colors.danger};
  &:focus-visible {
    outline: 1px solid ${colors.danger};
    outline-offset: -3px;
  }
`

/**
 * Input with Addon
 */

export const minWidthToBuildBorder = css`
  min-width: 10px;
`

export const removeVerticalSpacing = verticalOrientation => css`
  padding-${verticalOrientation}: 0;
  margin-${verticalOrientation}: 0;
  border-${verticalOrientation}: none;
  border-top-${verticalOrientation}-radius: unset;
  border-bottom-${verticalOrientation}-radius: unset;
`

export const inputContainer = css`
  display: inline-flex;
  flex-direction: row;
`

export const overwritePadding = css`
  padding: 0 10px;
`

export const inputAddon = css`
  font-size: 12px;
  border: 1px solid ${colors.gray8};
  background: white;
  color: ${colors.gray5};
  align-self: stretch;

  input:focus-visible + &,
  :has(+ input:focus-visible) {
    border-color: ${focusBorderColor};
  }
`

export const width100 = css`
  width: 100%;
`

export const documentSelect = css`
  width: 100px;
  border-color: transparent;
  padding: 10px 12px 10px 3px;
  display: flex;
  [class*="-selectArrowIcon"] {
    right: 0;
  }
  input {
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    margin: auto;
    text-overflow: ellipsis;
  }
`

export const percentageInputSuffixStyle = css`
  width: 100%;
  color: ${colors.gray3};
  font-size: 15px;
  flex: 1;
  padding: 0;
  justify-content: flex-start;
  cursor: text;
`

export const percentageInputStyle = css`
  width: 100%;
  max-width: 70px;
`

export const passwordWithToggleSuffix = css`
  cursor: pointer;

  .toggle__button {
    cursor: pointer;
    font-size: 14px;
    border: none;
    background-color: transparent;

    .toggle__icon {
      vertical-align: middle;
    }
  }
`
