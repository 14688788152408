import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

type PartnershipGroup = {
  name: string;
  commissioning: number;
  deadline: number;
  deadline_type: string;
  invoice_policy: {
    invoiced: boolean;
    invoice_closing_period?: string;
    invoice_closing_day?: string;
    payment_day?: string;
    payment_guarantee: {
      no_show: boolean;
      installments: number;
    };
  };
  commercial_partner_ids: Array<string>;
  image?: string;
}

type Partner = {
  id?: string;
  email?: string;
}

const partnershipService = authenticatedService('/v1/partnership_groups')

const list = function () {
  return partnershipService.get().then(extractData)
}

const get = async function (id: string) {
  return partnershipService.get({}, id).then(extractData)
}

const create = function (data: PartnershipGroup) {
  return partnershipService.post(data).then(extractData)
}

const update = function (data: any, groupId: string) {
  return partnershipService.put(data, groupId)
}

const invite = function ({ email, id }: Partner, groupId: string) {
  if (!email && !id) {
    return Promise.reject(new Error('No invite information provided'))
  }

  const partner = {
    comercial_partner_email: email,
    comercial_partner_id: id
  }

  return partnershipService.post(partner, `${groupId}/invite`)
}

partnershipService.setServiceMethods({ get, create, invite, list, update })

export const partnershipGroupDomain = partnershipService.initialize
