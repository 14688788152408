/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { marginTop } from '../../assets/styles/global'
import { flexCenteredSpaceBetweenRow } from '../../assets/styles/layout'
import { BREAK_POINTS } from '../Layout'

const marginTop15 = marginTop(15)
const marginTop20 = marginTop(20)

const labelContainer = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

const infoLabel = css`
  font-size: 14px;
  color: ${colors.gray3};
`

const extraMessageStyle = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${marginTop20}
  }
`

const imageFormatLabel = css`
  padding: 5px;
  margin-left: 5px;
  background-color: ${colors.gray11};
  border-radius: 5px;
`

export const UploadFootnote = ({
  typeList = [],
  extraMessage = '',
  ...props
}) => {
  return (
    <div {...props} css={[flexCenteredSpaceBetweenRow, marginTop15, labelContainer]}>
      <p css={infoLabel}>Formatos compatíveis:
        {typeList.map(key => (<span css={imageFormatLabel} key={key}>{key}</span>))}
      </p>
      <p css={[infoLabel, extraMessageStyle]}>{extraMessage}</p>
    </div>
  )
}
