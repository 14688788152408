import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  CreateCompanyConfigPayload,
  CompanyConfigItem
} from '@/types/domains/CompanyConfig'

const companyConfigService = authenticatedService('v1/company_configurations')

const getById = (id: string): Promise<any> => companyConfigService.get({}, id).then(extractData)

const create = (payload: CreateCompanyConfigPayload): Promise<any> => {
  return companyConfigService.post(payload).then(extractData)
}

const update = (companyConfig: any, companyConfigId: string): Promise<CompanyConfigItem> => {
  return companyConfigService.patch(companyConfig, companyConfigId)
}

companyConfigService.setServiceMethods({
  getById,
  create,
  update
})

export const companyConfigDomain = companyConfigService.initialize
