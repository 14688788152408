export const isFileTypeValid = (acceptedTypes, files) => {
  if (acceptedTypes) {
    const acceptedFiles = acceptedTypes.replace(/\s/g, '').split(',')
    const acceptedFilesExtensions = acceptedFiles
      .filter((value) => value.startsWith('.'))
      .map((ext) => ext.toLowerCase())
    const notAcceptedFile = files.find(
      ({ type, name }) => {
        const safeName = (name.match(/\.[^.]*$/)?.[0] || '')?.toLowerCase()
        return !acceptedFiles.includes(type) && !acceptedFilesExtensions.includes(safeName)
      }
    )
    if (notAcceptedFile) {
      return false
    }
  }
  return true
}

const hasFiledPassedLimits = (size, limit) => Math.round(size / 1048576) > limit

export const isFileSizeValid = (sizeLimit, files) => {
  if (sizeLimit) {
    const notAcceptedFile = files.find(({ size }) => hasFiledPassedLimits(size, sizeLimit))
    if (notAcceptedFile) {
      return false
    }
  }
  return true
}
