/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'

const selectOption = css`
  margin: 5px 0;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;

  &:hover {
    background: ${colors.gray13};
  }
`

const selectedOption = css`
  background: ${colors.gray10};
`

const focusedOption = css`
  background: ${colors.gray13};
`

export const SelectOption = React.forwardRef((props, ref) => {
  const {
    children,
    selected = false,
    isFocused = false,
    index,
    value,
    optionIcon,
    ...rest
  } = props

  const OptionIcon = optionIcon

  return (
    <div
      {...rest}
      ref={ref}
      role='option'
      tabIndex='0'
      css={[selectOption, isFocused && focusedOption, selected && selectedOption]}
      className='component-drawer-item'
      aria-posinset={index}
      aria-selected={selected}
      value={value}
    >
      {optionIcon && <OptionIcon />}
      {children}
    </div>
  )
})
