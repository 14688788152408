import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors.js'

export const clickable = css`
  cursor: pointer;
`

export const width5 = css`
  width: 5%;
`

export const width95 = css`
  width: 95%;
`

export const iconSize = css`
  font-size: 23px;
`

export const containedInput = css`
  width: 200px;
`

export const sameLineInput = css`
  margin: 0 10px;
`

export const alignAtEnd = css`
  justify-content: flex-end;
`

export const customCardPadding = css`
  padding: 10px 20px;
`

export const overwriteSizes = css`
  width: 45px;
  background-size: 40px;
`

export const stylishCard = css`
  border: none;
  color: ${colors.gray3};

  &:nth-of-type(odd) {
    background-color: ${colors.gray11};
  }
`
