/* eslint-disable no-undef */
/**
 * Download a .pdf file from server
 * @param { String } data value to be downloaded
 */
export const exportPdf = (fileName = 'file', data) => {
  const url = URL.createObjectURL(new Blob([Buffer.from(data, 'binary')],
    { type: 'application/pdf' })
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.pdf`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
