import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { BinamikInvoiceSearchParams, BinamikInvoicePayload } from '@/types/domains/BinamikInvoice'

const binamikInvoiceService = authenticatedService('v1/binamik/invoices')

type ListParams = { searchParams: BinamikInvoiceSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<BinamikInvoicePayload> => {
  return binamikInvoiceService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const updateStatus = (invoiceId: string) => {
  return binamikInvoiceService.patch({}, `${invoiceId}/mark_as_paid`)
}

const getInvoiceById = (invoiceId: string) => {
  return binamikInvoiceService.get({}, `${invoiceId}`).then(extractData)
}

binamikInvoiceService.setServiceMethods({ list, updateStatus, getInvoiceById })

export const binamikInvoiceDomain = binamikInvoiceService.initialize
