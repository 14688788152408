import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { BREAK_POINTS } from '../../Layout/Layout.style'

export const tableHeaderCell = css`
  padding: 30px 20px;
  font-weight: bold;
  background-color: ${colors.gray12};
`

export const tableHeaderCellFixed = css`
  position: sticky;
  right: 0;
  top: 0;
  
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    padding: 5px;
    text-align: center;
    box-shadow: -3px 0px 15px -5px rgb(0 0 0 / 25%);
  }
`
