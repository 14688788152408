import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { VisualizedReservationsReportQuery } from '@/types/domains/Reservation'

const reservationsService = authenticatedService('/v1/ally/reservations')

const listVisualizedReservations = (queryParams: VisualizedReservationsReportQuery) => {
  return reservationsService.get(queryParams, 'visualized_reservations_report')
    .then(extractData)
}

reservationsService.setServiceMethods({ listVisualizedReservations })

export const reservationsDomain = reservationsService.initialize
