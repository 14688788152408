import { useRef, useState, useEffect, useCallback } from 'react'

/**
 *
 * @param { Number } timeout
 */

export const useHover = (timeout = 0) => {
  const [isVisible, setVisibilityState] = useState(false)
  const [nodeRef, setNodeRef] = useState(null)
  const timer = useRef()

  const showOnHover = useCallback(() => {
    clearTimeout(timer.current)
    setVisibilityState(true)
  }, [])

  const hideOnHover = useCallback(() => {
    timer.current = setTimeout(() => {
      setVisibilityState(false)
    }, timeout)
  }, [timeout])

  // callback ref
  const ref = useCallback(node => {
    if (node !== null) {
      node.addEventListener('mouseover', showOnHover)
      node.addEventListener('mouseout', hideOnHover)
    }
    setNodeRef(node)
  }, [hideOnHover, showOnHover])

  useEffect(() => {
    if (nodeRef) {
      return () => {
        nodeRef.removeEventListener('mouseover', showOnHover)
        nodeRef.removeEventListener('mouseout', hideOnHover)
      }
    }
  }, [hideOnHover, showOnHover, nodeRef])

  return [ref, isVisible]
}
