/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react'
import { jsx } from '@emotion/core'
import { baseFont } from '../../../assets/styles/styles'
import { calendar } from './Calendar.style'
import { CalendarDayMonthSelector } from './DayMonthSelector/DayMonthSelector'
import { identity } from '@bonitour/common-functions'
import { splitDate } from '../../../utils/date'
import { CalendarYearSelector } from './YearSelector/YearSelector'
import { CalendarMonthSelector } from './MonthSelector/MonthSelector'

export const Calendar = ({
  date,
  onChange: emitChangeEvent = identity,
  children,
  minDate = new Date(-10e13),
  maxDate = new Date(+10e13),
  'mark-today': mark_today = false,
  markToday = mark_today,
  lang = 'pt_BR'
}) => {
  const [currentYear, currentMonth] = splitDate(date)
  const [selectedMonth, setMonth] = useState(currentMonth)
  const [selectedYear, setYear] = useState(currentYear)
  const [selectStep, setSelectStep] = useState('calendar')

  const switchToMonthSelector = () => setSelectStep('month')
  const switchToYearSelector = () => setSelectStep('year')
  const switchToCalendarSelector = () => setSelectStep('calendar')

  const modifyOnlySelectedYear = year => {
    switchToCalendarSelector()
    setYear(year)
  }

  const modifyOnlySelectedMonth = month => {
    switchToYearSelector()
    setMonth(month)
  }

  return (
    <div css={[calendar, baseFont]}>
      {selectStep === 'calendar'
        ? (
          <CalendarDayMonthSelector
            selectedDate={date}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            onHeaderClick={switchToMonthSelector}
            onDateChange={emitChangeEvent}
            onMonthChange={setMonth}
            onYearChange={setYear}
            minDate={minDate}
            maxDate={maxDate}
            markToday={markToday}
            data-testid='CalendarDayMonthSelector'
            lang={lang}
          />
        )
        : selectStep === 'month'
          ? (
            <CalendarMonthSelector
              onMonthChange={modifyOnlySelectedMonth}
              onHeaderClick={switchToYearSelector}
              selectedMonth={selectedMonth}
              data-testid='CalendarMonthSelector'
              lang={lang}
            />
          )
          : (
            <CalendarYearSelector
              onYearChange={modifyOnlySelectedYear}
              onHeaderClick={switchToCalendarSelector}
              selectedYear={selectedYear}
              data-testid='CalendarYearSelector'
            />
          )}
      <div>{children}</div>
    </div>
  )
}
