import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { Activity, ActivityUpdate, ActivityAbout } from '@/types/domains/Activity'

const activityService = authenticatedService('/v1')
const activityServiceV3 = authenticatedService('/v3/services')

const sellableList = (enabled: boolean) => activityService.get({ enabled }, 'sellable_services').then(extractData)

const get = (serviceId: string) => activityService.get({}, `services/${serviceId}`).then(extractData)

const create = (activity: Activity): Promise<any> => activityService.postFormData(activity, 'services').then(extractData)

const update = (activity: ActivityAbout, serviceId: string) => activityService.patch(activity, `services/${serviceId}`)

const updateAsFormData = (activity: ActivityUpdate, activityId: string) => activityService.patchFormData(activity, `services/${activityId}`).then(extractData)

const list = (enabled: boolean) => activityServiceV3.get({ enabled }).then(extractData)

activityService.setServiceMethods({ sellableList, get, create, update, updateAsFormData })
activityServiceV3.setServiceMethods({ list })

export const activityDomain = activityService.initialize
export const activityDomainV3 = activityServiceV3.initialize
