/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { TableBodyCell } from '../Table/Table'
import { TooltipOnHover } from '../Tooltip/Tooltip'
import { centerPosition, resetPosition, truncateField } from './TruncateCell.style'
import { useMemo } from 'react'

export const TruncateCell = ({
  text,
  toolipText = '',
  tooltipStyle = [],
  tooltipPosition = 'center',
  size,
  label = '',
  children,
  ...other
}) => {
  const defaultTooltipStyle = useMemo(() => [resetPosition, tooltipPosition === 'center' ? centerPosition : ''], [tooltipPosition])
  return (
    <TableBodyCell {...other}>
      <TooltipOnHover css={tooltipStyle} tooltipStyle={defaultTooltipStyle} text={toolipText || text} position={tooltipPosition}>
        <div className='truncate-cell' css={truncateField(size)}>{label} {children || text}</div>
      </TooltipOnHover>
    </TableBodyCell>
  )
}

TruncateCell.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  label: PropTypes.string
}
