/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { CursorGlowContainer } from './CursorGlow.styles'

export const CursorGlow = ({
  children,
  elementQuerySelector
}) => {
  const handleOnMouseMove = useCallback(
    event => {
      const { currentTarget: target } = event

      const rect = target.getBoundingClientRect()

      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      target.style.setProperty('--mouse-x', `${x}px`)
      target.style.setProperty('--mouse-y', `${y}px`)
    },
    []
  )

  useEffect(() => {
    const element = document.querySelector(elementQuerySelector)

    if (!element) return new Error(`Element not found: ${elementQuerySelector}`)

    element.addEventListener('mousemove', handleOnMouseMove)

    return () => {
      element.removeEventListener('mousemove', handleOnMouseMove)
    }
  })

  return (
    <CursorGlowContainer>
      {children}
    </CursorGlowContainer>
  )
}

CursorGlow.propTypes = {
  children: PropTypes.node.isRequired,
  elementQuerySelector: PropTypes.string.isRequired // example: '.exampleClass' | '#exampleId'
}
