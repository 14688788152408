import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  GatewayPaymentsListResponse,
  GatewayPaymentsListSearchParams,
  ImportGatewayPaymentsFileParams,
  ImportGatewayPaymentsResponse,
  DeleteGatewayPaymentsParams,
  DeleteGatewayPaymentsResponse
} from '@/types/domains/GatewayPayments'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'

const gatewayPaymentsService = authenticatedService('v1/gateway_payments')
const gatewayPaymentsImportService = authenticatedService('v1/import_gateway_payments')

type ListParams = { searchParams: GatewayPaymentsListSearchParams; }

const list = async ({ searchParams }: ListParams): Promise<GatewayPaymentsListResponse> => {
  return await gatewayPaymentsService.get(
    cleanPayload(searchParams)
  ).then(extractData)
}

const importGatewayPayments = ({ searchParams }: ListParams): Promise<ImportGatewayPaymentsResponse> => {
  return gatewayPaymentsImportService.get({
    ...cleanPayload(searchParams)
  }).then(extractData)
}

const importGatewayPaymentsFile = async (params: ImportGatewayPaymentsFileParams): Promise<ImportGatewayPaymentsResponse> => {
  return gatewayPaymentsImportService.postFormData(params, 'import_file').then(extractData)
}

const deleteGatewayPayments = async (params:DeleteGatewayPaymentsParams): Promise<DeleteGatewayPaymentsResponse> => {
  return gatewayPaymentsService.remove(params, 'delete').then(extractData)
}

gatewayPaymentsService.setServiceMethods({ list, deleteGatewayPayments })

gatewayPaymentsImportService.setServiceMethods({ importGatewayPayments, importGatewayPaymentsFile })

export const gatewayPaymentsDomain = gatewayPaymentsService.initialize
export const gatewayPaymentsImportDomain = gatewayPaymentsImportService.initialize
