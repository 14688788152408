import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'

export const footer = css`
  display: grid;
  align-items: center;
  height: 57px;
  box-sizing: border-box;
  color: ${colors.gray4};
  background: ${colors.gray10};
  font-size: 15px;
  padding: 10px;
  font-weight: 500;
  user-select: none;
  flex: 0 1 57px;
  width: 100%;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self:center;
  }
  
  & > *:last-child {
    justify-self: right;
  }

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    height: auto;

    div {
      margin-top: 5px;
    }
  }
`

export const customFooter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const logoStyles = css`
  margin: 0 10px 3px 8px;
`

export const contentStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    height: auto;

    img, svg {
      margin: 5px auto;
    }

    div {
      margin-top: 5px;
    }
  }
`

export const policies = css`
  margin-right: 20px;

  @media (max-width: 1100px) {
    margin: 0;
  }
`

export const policiesLink = css`
  text-decoration: none;
  color: ${colors.gray4};
`
