import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'

export const tableBody = css`
  tr:nth-of-type(even) {
    td {
      background-color: ${colors.gray14};
    }
  }
  tr:hover {
    td {
      background-color: ${colors.gray11} !important;
    }
  }
`

export const tableBodyEmptyCell = css`
  background-color: ${colors.gray14};
  padding: 20px;
  text-align: center;
  border-radius: 0 0 15px 15px;
`

export const tableBodyCenteredCell = css`
  display: flex;
  padding: 10px;
  align-self: center;
  align-items: center;
  justify-content: center;
`
