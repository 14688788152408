import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  GetCreditCardConciliationsParams,
  GetCreditCardConciliationsRes,
  PostAutoCreditCardConciliationParams,
  PostAutoCreditCardConciliationRes,
  PostManualCreditCardConciliationParams,
  PostManualCreditCardConciliationRes,
  DeleteCreditCardConciliationsParams
} from '@/types/domains/CreditCardConciliation'
import { extractData } from '@/utils/data-extractor'

const creditCardConciliationService = authenticatedService('v1/creditcard_conciliations')

const getCreditCardConciliations = async (
  searchParams: GetCreditCardConciliationsParams
): Promise<GetCreditCardConciliationsRes> => {
  return creditCardConciliationService.get(searchParams, '').then(extractData)
}

const postAutoCreditCardConciliation = async (
  params: PostAutoCreditCardConciliationParams
): Promise<PostAutoCreditCardConciliationRes> => {
  return await creditCardConciliationService.post(
    params, 'auto_conciliation'
  ).then(extractData)
}

const postManualCreditCardConciliation = async (
  params: PostManualCreditCardConciliationParams
): Promise<PostManualCreditCardConciliationRes> => {
  return await creditCardConciliationService.post(
    params, 'manual_conciliation'
  ).then(extractData)
}

const deleteCreditCardConciliations = async (
  params: DeleteCreditCardConciliationsParams
): Promise<void> => {
  return await creditCardConciliationService.remove(
    params, 'delete'
  ).then(extractData)
}

creditCardConciliationService.setServiceMethods(
  {
    getCreditCardConciliations,
    postAutoCreditCardConciliation,
    postManualCreditCardConciliation,
    deleteCreditCardConciliations
  }
)

export const creditCardConciliationDomain = creditCardConciliationService.initialize
