import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { SearchCompanyPayload } from '@/types/domains/Company'

const sellerService = authenticatedService('/v1/companies')

sellerService.setServiceMethods({
  list (companyId: string): Promise<any> {
    return sellerService.get({}, `${companyId}/sellers/all`)
      .then(extractData)
  },
  getPaginatedList (companyId: string, perPage: string, page: string): Promise<any> {
    return sellerService.get({ per_page: perPage, page }, `${companyId}/sellers`)
      .then(extractData)
  },
  searchCompany (company: string, companyId: string, perPage: string, page: string): Promise<SearchCompanyPayload> {
    return sellerService.get({ per_page: perPage, page, company_name: company }, `${companyId}/sellers`).then(extractData)
  }
})

export const sellerDomain = sellerService.initialize
