import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { roundedEdges } from '../../assets/styles/styles'
import { BREAK_POINTS } from '../Layout'

export const datePickerContainer = css`
  box-sizing: border-box;
  min-height: 40px;
  font-size: 14px;
  background: ${colors.white};
  color: ${colors.gray3};
  border: 1px solid ${colors.gray8};
  padding: 10px 20px;
  margin: 0;
  ${roundedEdges}
  user-select: none;
  position: static;
  display: inline-flex;
  width: 160px;
  outline: none;
`

export const coverMobile = css`
  display: none;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: initial;
    z-index: 5;
  }
`

export const preventHeaderIndexStyle = css`
  @media screen and (max-height: ${BREAK_POINTS.smallTablet}) {
    margin-top: 80px;
    .component-popover-arrow {
      display: none;
    }
  }
`

export const datePickerPopover = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0px;
    padding: 0px;

    .component-popover-arrow {
      display: none;
    }
  }
`

export const fullWidthStyle = css`
  width:100%;
`

export const datePickerWrapper = css`
  display: inline-flex;
  width: 100%;
`

export const disabledStyle = css`
  pointer-events: none;
  background-color: ${colors.gray11};
  color: ${colors.gray4};

  &::after {
    content: '';
  }
`

export const angleSelector = css`
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  border-radius: var(--round-element-radius, 50%);
  margin: -5px;
  padding: 5px 6px;
`

export const hoverShadow = css`
  &:hover {
    box-shadow: 0 0 6px #000000cc;
  }
`

export const angleSelectorIcon = css`
  transition: 0.2s transform ease;
`

export const rotateSelector = css`
  transform: rotate(-180deg);
`

export const error = css`
  border-color: ${colors.danger};
`

export const dateInput = css`
  &:read-only {
    background-color: transparent;
    -webkit-text-fill-color: ${colors.gray3};
    color: ${colors.gray3};
  }
`
