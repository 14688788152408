import React, { useState } from 'react'
import { css } from '@emotion/core'
import { pureSplice, identity } from '@bonitour/common-functions'
import { UploadDropzone } from './UploadDropzone/UploadDropzone'
import { UploadPreviewCard } from './UploadPreviewCard/UploadPreviewCard'
import { colors } from '../../assets/styles/colors'
import { flexColumn } from '../../assets/styles/layout'
import { UploadIcon } from '../Icons/Icons'

const uploadMessage = css`
  color: ${colors.gray3};
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  text-align: center;

  i {
    font-size: 35px;
    color: ${colors.gray3};
  }
`

const uploadInline = css`
  min-width: 120px;
  min-height: 40px;
  border-radius: var(--round-element-radius, 20px);

  div {
    flex-direction: column;
  }
`

const addMoreMessage = css`
  display: flex;
  flex-direction: column;
  margin: 30px;
  color: ${colors.gray3};
  font-size: 12px;
  i {
    font-size: 35px;
  }
`

function buildFileObject (file) {
  const id = `${+new Date()}-${Math.random() * 1000}`
  const src = file.type && file.type.includes('image') && URL.createObjectURL(file)
  return { id, file, src, name: file.name }
}

export const UploadContent = ({
  files = [],
  disabled,
  isImageHidden,
  deleteItem,
  isIconHidden,
  emptyMessage,
  emptyDisabledMessage,
  multiple
}) => {
  const hasFiles = files?.length

  if (hasFiles) {
    return (
      <>
        {files.map((file, index) => (
          <UploadPreviewCard
            key={file.id}
            file={file}
            disabled={disabled}
            isImageHidden={isImageHidden}
            index={index}
            onDelete={deleteItem}
          />
        ))}
        {multiple && (
          <div css={addMoreMessage}>
            <UploadIcon />
            Adicionar mais arquivos
          </div>
        )}
      </>
    )
  }
  return (
    <span css={[flexColumn, uploadMessage]}>
      {!isIconHidden && <i className='b-font-upload' />}
      {disabled ? emptyDisabledMessage : emptyMessage}
    </span>
  )
}

export function Upload ({
  multiple = false,
  accept = '',
  files = [],
  limitMbSize,
  emptyMessage = 'Arraste ou clique para adicionar',
  emptyDisabledMessage = 'Nenhum arquivo foi adicionado.',
  isIconHidden = false,
  isImageHidden = false,
  disabled: isDisabled = false,
  error = false,
  onChange: emitChangeEvent = identity,
  onError: emitErrorEvent = identity,
  ...other
}) {
  const [loading, setLoading] = useState(false)

  const onFileAdd = sentFiles => {
    setLoading(true)
    emitChangeEvent(files?.concat(sentFiles.map(buildFileObject)))
    setLoading(false)
  }

  const deleteItem = index => {
    emitChangeEvent(pureSplice(files, index, 1))
  }

  const isDropzoneDisabled = (files?.length >= 1 && !multiple) || loading || isDisabled
  const isContentDisabled = loading || isDisabled

  return (
    <UploadDropzone
      onError={emitErrorEvent}
      error={error}
      onChange={onFileAdd}
      disabled={isDropzoneDisabled}
      limitMbSize={limitMbSize}
      multiple={multiple}
      accept={accept}
      {...other}
    >
      <UploadContent
        files={files}
        disabled={isContentDisabled}
        isImageHidden={isImageHidden}
        deleteItem={deleteItem}
        isIconHidden={isIconHidden}
        emptyMessage={emptyMessage}
        multiple={multiple}
        emptyDisabledMessage={emptyDisabledMessage}
      />
    </UploadDropzone>
  )
}

export function UploadInline ({
  multiple = false,
  accept = '',
  files = [],
  limitMbSize,
  emptyMessage = 'Clique para adicionar',
  onChange: emitChangeEvent = identity,
  onError: emitErrorEvent = identity,
  isIconHidden = true,
  ...other
}) {
  return (
    <Upload
      {...other}
      css={uploadInline}
      multiple={multiple}
      accept={accept}
      files={files}
      limitMbSize={limitMbSize}
      emptyMessage={emptyMessage}
      onChange={emitChangeEvent}
      onError={emitErrorEvent}
      isIconHidden={isIconHidden}
      isImageHidden
    />
  )
}
