/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { weekDay, dayMonthHeader, tdPadding } from './DayMonthSelector.style'
import { CalendarSelector, CalendarTable } from '../ExpandedCalendar.components'
import { buildWeekArray } from './DayMonthSelector.utils'
import { MONTH_LIST, DAY_OF_WEEK_LIST } from '../../../constants/date'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const WeekDay = props => <td {...props} css={weekDay} />

const WeeksRow = ({ lang = 'pt_BR' }) => {
  const weekDayList = DAY_OF_WEEK_LIST[lang] || DAY_OF_WEEK_LIST.pt_BR

  return (
    <tr>
      {weekDayList.map(weekDay => <WeekDay key={weekDay}>{weekDay}</WeekDay>)}
    </tr>
  )
}

const DayMonthHeader = ({
  selectedMonth = 0,
  selectedYear = 0,
  lang = 'pt_BR'
}) => {
  const selectedMonthTitle = MONTH_LIST[lang]?.[selectedMonth - 1] || MONTH_LIST.pt_BR[selectedMonth - 1]
  return (
    <span css={dayMonthHeader}>
      {selectedMonthTitle}/{selectedYear}
    </span>
  )
}

/**
 * @param { Date } a
 * @param { Date } b
 */
function dayGreaterThanOrEqual (a, b) {
  return dayjs.utc(a).format('YYYYMMDD') >= dayjs.utc(b).format('YYYYMMDD')
}

/**
 * @param { Date } a
 * @param { Date } b
 */
function dayLessThanOrEqual (a, b) {
  return dayjs.utc(a).format('YYYYMMDD') <= dayjs.utc(b).format('YYYYMMDD')
}

/**
 * @param { Date } a
 * @param { Date } b
 */

export const CalendarDayMonthSelector = ({
  onDateChange: emitDateChange = identity,
  firstDate,
  secondDate,
  selectedMonth,
  selectedYear,
  minDate,
  maxDate,
  moveDays = [],
  lang = 'pt_BR'
}) => {
  const [weeksList, setWeeksList] = useState([])
  const [listDaysSmallWidth, setListDaysSmallWidth] = useState([])

  const selectedDateISO = useMemo(
    () => firstDate && firstDate.toISOString().slice(0, 10),
    [firstDate]
  )
  const isSelectedDate = dayDate =>
    selectedDateISO === dayDate.toISOString().slice(0, 10)

  const isDisabled = dayDate => !dayGreaterThanOrEqual(dayDate, minDate) || !dayLessThanOrEqual(dayDate, maxDate)

  const secondDateISO = useMemo(
    () => secondDate && secondDate.toISOString().slice(0, 10),
    [secondDate]
  )
  const isSelectedSecondDate = dayDate =>
    secondDateISO === dayDate.toISOString().slice(0, 10)

  const hasMove = ({ date }) => moveDays.find((elem) => elem === date.toString())

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      setWeeksList(buildWeekArray(selectedYear, selectedMonth))
    }
    getListDaysSmallWidth(buildWeekArray(selectedYear, selectedMonth))
    // eslint-disable-next-line
  }, [selectedYear, selectedMonth])

  const getListDaysSmallWidth = useCallback((weeksListParam = []) => {
    const list = []
    weeksListParam.forEach((item) => {
      const element = item.reverse().find(({ label }) => label !== '')
      list.push(element)
    })
    setListDaysSmallWidth(list)
    // eslint-disable-next-line
  }, [selectedYear, selectedMonth])

  const handleSelectedDays = useCallback(({ date }) => {
    if (!firstDate || !secondDate) return
    if (date >= firstDate && date <= secondDate) {
      return true
    } else {
      return false
    }
  }, [firstDate, secondDate])

  const handleSelectedFirstDay = useCallback(({ date }) => {
    if (!firstDate || !secondDate) return
    if (date === firstDate) {
      return true
    } else {
      return false
    }
  }, [firstDate, secondDate])

  const handleSelectedSecondDay = useCallback(({ date }) => {
    if (!firstDate || !secondDate) return
    if (date === secondDate) {
      return true
    } else {
      return false
    }
  }, [firstDate, secondDate])

  return (
    <React.Fragment>
      <DayMonthHeader
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        lang={lang}
      />
      <CalendarTable>
        <WeeksRow lang={lang} />
        {weeksList.map((weekDaysList, weekIndex) => (
          <tr key={`week-${weekIndex}`}>
            {weekDaysList.map((weekDay, dayIndex) => (
              <td css={tdPadding} key={`day-${weekIndex}-${dayIndex}`}>
                {weekDay.label && (
                  <CalendarSelector
                    selectedFirstDate={isSelectedDate(weekDay.date)}
                    selectedSecondDate={isSelectedSecondDate(weekDay.date)}
                    onClick={() => emitDateChange(weekDay.date)}
                    disabled={isDisabled(weekDay.date)}
                    weekDay={weekDay}
                    selectedDays={handleSelectedDays(weekDay)}
                    selectedFirstDay={handleSelectedFirstDay(weekDay)}
                    selectedSecondDay={handleSelectedSecondDay(weekDay)}
                    hasMove={hasMove(weekDay)}
                    label={weekDay.label}
                    listDaysSmallWidth={listDaysSmallWidth}
                  >
                    {weekDay.label}
                  </CalendarSelector>
                )}
              </td>
            ))}
          </tr>
        ))}
      </CalendarTable>
    </React.Fragment>
  )
}
