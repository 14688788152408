import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import {
  PayloadExpenseRevenue,
  ExpenseRevenueListResponse,
  ExpenseRevenueListSearchParams,
  ExpenseRevenueItem,
  ExpenseRevenueAuditParams,
  ExpenseRevenueAuditRes
} from '@/types/domains/ExpenseRevenue'

const expenseRevenueService = authenticatedService('v1/expense_revenues')

type ListParams = { searchParams: ExpenseRevenueListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<ExpenseRevenueListResponse> => {
  return expenseRevenueService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const create = (payload: PayloadExpenseRevenue): Promise<any> => {
  return expenseRevenueService.post(payload).then(extractData)
}

const update = (expenseRevenue: PayloadExpenseRevenue, expenseRevenueId: string): Promise<ExpenseRevenueItem> => {
  return expenseRevenueService.put(expenseRevenue, expenseRevenueId)
}

const get = (expenseRevenueId: string): Promise<ExpenseRevenueItem> => expenseRevenueService.get({}, expenseRevenueId).then(extractData)

const remove = (expenseRevenueId: string): Promise<any> => expenseRevenueService.remove({}, expenseRevenueId)

const getExpenseRevenueAudit = async (params: ExpenseRevenueAuditParams, expenseRevenueId: string): Promise<ExpenseRevenueAuditRes> => {
  return await expenseRevenueService.get(params, `/${expenseRevenueId}/audit`).then(extractData)
}

expenseRevenueService.setServiceMethods({ list, create, get, update, remove, getExpenseRevenueAudit })

export const expenseRevenueDomain = expenseRevenueService.initialize
