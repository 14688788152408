import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { ListTimeZonesRes } from '@/types/domains/TimeZone'

const timeZoneService = authenticatedService('v1/timezones')

const list = (searchParams: Record<string, any>): Promise<ListTimeZonesRes> => timeZoneService.get({
  ...cleanPayload(searchParams)
}).then(extractData)

timeZoneService.setServiceMethods({ list })

export const timeZoneDomain = timeZoneService.initialize
