import React, { useRef } from 'react'
import { css, Global } from '@emotion/core'

import { actionBarWrapper } from './FloatingActionBar.styles'
import { useElementBoundingClientRect } from './useElementBoundingClientRect'

export const FloatingActionBar = ({
  children,
  actionChildren = null
}) => {
  const wrapper = useRef(null)

  const { height: elementHeight } = useElementBoundingClientRect(wrapper, 'bounding')

  return (
    <div css={actionBarWrapper} ref={wrapper}>
      <div className='bar__content'>
        <div className='bar__content_left'>{children}</div>
        {actionChildren && (
          <div className='bar__content_button'>{actionChildren}</div>
        )}
      </div>
      <Global
        styles={css`
          #__TOASTS_PORTAL__ {
            margin-bottom: 4rem !important;
          }
          body {
            margin-bottom: ${elementHeight}px;
          }
        `}
      />
    </div>
  )
}
