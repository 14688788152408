/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { flexRowCentered } from '../../assets/styles/layout'
import { relative } from '../../assets/styles/styles'
import { BaseTooltip } from '../Tooltip/Tooltip'
import { DateIntervalIndicator } from './DateIntervalIndicator'

export const BLANK_SPACE = 'blank_space'

const background = color =>
  css`
    background-color: ${color};
  `

const softBorderRadius = css`
  border-radius: 10px;
`

const customTooltip = css`
  position: absolute;
  bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
`

const timelinePeriod = css`
  .timeline-period__tooltip,
  .timeline-period__interval {
    display: none;
  }
  &:hover {
    .timeline-period__tooltip,
    .timeline-period__interval {
      display: initial;
    }
  }
`

export const TimelinePeriod = ({ dateInterval, ...other }) => {
  return (
    <div css={[relative, flexRowCentered, softBorderRadius, background(dateInterval.color), timelinePeriod]} {...other}>
      {dateInterval.name !== BLANK_SPACE && (
        <React.Fragment>
          <BaseTooltip
            position='top'
            className='timeline-period__tooltip'
            css={customTooltip}
            color={dateInterval.color}
          >
            {dateInterval.name}
          </BaseTooltip>
          <DateIntervalIndicator className='timeline-period__interval' dateInterval={dateInterval} />
        </React.Fragment>
      )}
    </div>
  )
}
