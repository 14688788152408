import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  TermsCreatePayload,
  TermsCreateResponse,
  TermsResponse,
  TermResponse,
  TermsUpdatePayload,
  AllyRegionsResponse
} from '@/types/domains/Terms'
import { extractData } from '@/utils/data-extractor'

const companyTermsService = authenticatedService('/v1/ally')

const getAllCheckinSettings = async (): Promise<TermsResponse> => {
  return companyTermsService.get({}, 'checkin_settings')
    .then(extractData)
}

const getCheckinSettings = async (settingId: string): Promise<TermResponse> => {
  return companyTermsService.get({}, `checkin_settings/${settingId}`)
    .then(extractData)
}

const removeCheckinSettings = async (settingId: string): Promise<void> => {
  return companyTermsService.remove({}, `checkin_settings/${settingId}`)
    .then(extractData)
}

const createCheckinSettings = async (payload: TermsCreatePayload): Promise<TermsCreateResponse> => {
  return companyTermsService.post(payload, 'checkin_settings')
    .then(extractData)
}

const getAllyRegions = async (): Promise<AllyRegionsResponse> => {
  return companyTermsService.get({}, 'checkin_settings/ally_regions')
    .then(extractData)
}

const updateCheckinSetting = async (settingId: string, payload: TermsUpdatePayload): Promise<void> => {
  return companyTermsService.patch(payload, `checkin_settings/${settingId}`)
}

companyTermsService.setServiceMethods({
  getAllCheckinSettings,
  removeCheckinSettings,
  createCheckinSettings,
  getAllyRegions,
  getCheckinSettings,
  updateCheckinSetting
})

export const companyTermsDomain = companyTermsService.initialize
