import { getUniqueValues } from './array.js'
import { compose } from './compose.js'

const cpfRegex = /^([\d_]{3})([\d_]{3})([\d_]{3})([\d_]{2})$/g
const cpfMask = '$1.$2.$3-$4'

/**
 * Formats a 14 length number into human readable Cpf
 * @param { String } cpf number of 14 digits length
 * @returns { String } formatted cpf
 */
export const formatCpf = cpf => cpf.replace(cpfRegex, cpfMask)

/**
 * Masks given value to look like brazilian Cpf, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format (\d{2}).(\d{3}).?(\d{3})/?(\d{4})-?(\d{2})
 */
export const maskCpf = (value = '') =>
  formatCpf(
    value
      .replace(/\D/g, '')
      .replace(/(\d{11})[\w\W]/, '$1')
      .padStart(11, '_')
  )

/**
 * Sums CPF digits based on specification rules
 * @private
 * @param { Array<Number> } digits CPF digits
 * @returns { Number } Sum of all it's digits multiplied by the right weight
 */
const sumCpfDigits = digits =>
  digits.map((digit, index, { length }) => digit * (length + 1 - index)).reduce((acc, curr) => acc + curr)

/**
 * Based on a given number, returns the next CPF digit
 * Rules are: (11 - `sum` ) module eleven
 * @private
 * @param { Number } sum Previous digits sum
 * @returns { Number } next digit
 */
const calculateCpfDigit = sum => (sum % 11 < 2 ? 0 : 11 - (sum % 11))

/**
 * gets the next CPF digits based on specification rules
 * @private
 * @param { Array<Number> } cpfNumbers Array of numbers to sum
 * @returns { Number } next digit
 */
const getDigit = compose(calculateCpfDigit, sumCpfDigits)

/**
 * Validates a cpf passed through here
 * @global
 * @param { String } [cpf=''] CPF string
 * @returns { Boolean } wheter it's valid or not
 */
export const validateCpf = (cpf = '') => {
  const cpfArray = cpf
    .replace(/\D/g, '')
    .split('')
    .map(Number)
  if (cpfArray.length !== 11 || getUniqueValues(cpfArray).length === 1) {
    return false
  }
  const cpfNumbers = cpfArray.filter((_, i) => i < 9)
  const firstDigit = getDigit(cpfNumbers)
  const secondDigit = getDigit([...cpfNumbers, firstDigit])

  return [firstDigit, secondDigit].join('') === cpfArray.filter((_, i) => i >= 9).join('')
}
