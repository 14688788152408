import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { BREAK_POINTS } from '../../Layout'

export const table = css`
  background: ${colors.white};
  table-layout: auto;
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
`

export const tableCard = css`
  thead {
    display: none;
  }

  tr {
    display: block;
    border: 1px solid ${colors.gray11};
    border-radius: 10px;    
    margin: 10px 0;
    overflow: hidden;
    
    &:nth-of-type(even) {
      td {
        background-color: ${colors.white} !important;
      }
    }
  }

  td {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: none;
    padding: 10px;

    .truncate-cell {
      width: 100%;
    }

    &.table-body-empty {
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }

    &:before {
      content: attr(data-label);
      text-align: left;
      font-weight: bold;
      max-width: 45%;
      color: ${colors.gray3};
      margin-bottom: 10px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  @media (min-width: ${BREAK_POINTS.tableUp}) {
    thead {
      display: table-header-group;
    }

    tr {
      display: table-row;
      border: 0;

      &:nth-of-type(even) {
        td {
          background-color: ${colors.gray14} !important;
        }
      }
    }

    td {
      display: table-cell;

      &:before {
        display: none;
      }
    }
  }
`
