import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

const mobileSelectHeaderHeight = '52px'

const buttonsBaseCss = css`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
`

export const mainContainer = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100vw;
  height: 100vh;

  box-sizing: border-box;
  background: ${colors.white};

  transition: all 400ms;

  z-index: 99;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
`

export const mobileSelectHeader = css`
  ${buttonsBaseCss}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  z-index: 999;

  width: 100%;
  height: ${mobileSelectHeaderHeight};

  box-shadow: 0 3px 5px -5px rgba(0, 0, 0, 0.25);

  font-size: 20px;
  color: ${colors.gray3};
  font-weight: 700;

  padding-left: 22px;

  span {
    padding-left: 32px;
  }
`

export const contentContainer = css`
  padding: 20px;

  height: 100%;
`

export const bodyAdjustForOverlay = css`
  body {
    overflow: hidden !important;
  }

  footer {
    opacity: 0;
  }
`
