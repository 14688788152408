/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../../assets/styles/colors'
import { memo, useState, useCallback } from 'react'
import { SortRightIcon } from '../../../Icons/Icons'

const menuItem = css`
  font-size: 14px;
  font-weight: 800;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px 0;
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${colors.white};
  color: ${colors.gray5};
  letter-spacing: 0px;
`

const activeMenuItem = css`
  background-color: ${colors.gray12};
`

const menuItemIcon = css`
  display: inline-flex;
  padding: 5px 10px 5px 20px;
  font-size: 24px;
  width: 55px;
  box-sizing: border-box;
  [class*="IconWrapper"] {
    max-width: 100%;
    overflow: visible;
  }
`

export const MenuItem = ({
  label = '',
  icon: Icon = () => null,
  isActive = false,
  iconSize = '',
  children,
  ...props
}) => {
  return (
    <div css={[menuItem, isActive && activeMenuItem]} {...props}>
      <span css={[menuItemIcon, iconSize]}>
        <Icon />
      </span>
      <span>
        {label}
      </span>
      {children}
    </div>
  )
}

const collapsableIcon = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: 0.2s transform ease-in;
`

const collapsableIconOpen = css`
  transform: rotate(90deg);
`

const subMenuContainer = css`
  margin-top: -10px;
  overflow: hidden;
  max-height: 0;
  transition: 0.4s max-height ease;
`

const openedSubMenuContainer = css`
  max-height: 600px;
  overflow: initial;
`

export const CollapsableMenuItem = memo(({
  label = '',
  icon: Icon = () => null,
  isActive = false,
  isOpenedAsDefault = false,
  children,
  ...props
}) => {
  const [isOpened, setOpenedState] = useState(isOpenedAsDefault)
  const toggleOpen = useCallback(() => setOpenedState(previous => !previous), [])

  return (
    <>
      <MenuItem
        label={label}
        icon={Icon}
        isActive={isActive}
        onClick={toggleOpen}
        {...props}
      >
        <SortRightIcon css={[collapsableIcon, isOpened && collapsableIconOpen]} />
      </MenuItem>
      <div css={[subMenuContainer, isOpened && openedSubMenuContainer]}>
        {children}
      </div>
    </>
  )
})

const subMenuItem = css`
  font-weight: 500;
  padding: 10px 0;
  margin: 1px 0;
`

export const SubMenuItem = (props) => (<MenuItem css={subMenuItem} {...props} />)
