import { tail } from './array'

/** @typedef { string | number | symbol } Keys */

/**
 * Set values deep in a project, adding the missing structures if necessary
 * @param { Object } [data={}] Main structure where value will be added
 * @param { String | Array<Keys> } [path=''] The path that will be followed to add value
 * @param { * } [value=''] Value that will be inserted
 * @returns { Object } Data procesed with the value in it
 */
export const deepSetter = (data = {}, path = '', value = '') => {
  if (!path || !data) return data
  const arrayPath = Array.isArray(path) ? path : path.toString().split(/[.[\]]+/)

  const processedData = arrayPath.slice(0, -1).reduce((acc, key, index) => {
    if (Object(acc[key]) !== acc[key]) {
      acc[key] = Math.abs(arrayPath[index + 1]) >> 0 === +arrayPath[index + 1] ? [] : {}
    }
    return acc[key]
  }, data)
  processedData[tail(arrayPath)] = value

  return data
}
