import { ReservationId } from '@/types/domains/Reservation'
import { extractData } from '@/utils/data-extractor'
import { identity } from '@bonitour/common-functions'
import { ApprovePaymentTransactionPayload } from '@/types/domains/Payment'

export const ReservationAdvanceTransactionRoutes = (reservationService = identity) => ({
  getAdvanceTransactions (reservationId: ReservationId) {
    return reservationService.get({}, `${reservationId}/advance_financial_transactions`).then(extractData)
  },
  getAdvanceTransactionsById (reservationId: string, paymentId: string) {
    return reservationService.get({}, `${reservationId}/advance_payments/${paymentId}`).then(extractData)
  },
  createPayment (reservationId: ReservationId, payload = {}) {
    return reservationService.post(payload, `${reservationId}/advance_payments`).then(extractData)
  },
  approvePaymentTransaction (reservationId: ReservationId, paymentId: string, payload: ApprovePaymentTransactionPayload = {}) {
    return reservationService.patch(payload, `${reservationId}/advance_payments/${paymentId}/approve`)
  },
  rejectPaymentTransaction (reservationId: ReservationId, paymentId: string) {
    return reservationService.patch({ }, `${reservationId}/advance_payments/${paymentId}/reject`)
  }
})
