import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { AcquirerRes, Acquirer, AcquirerV2, AcquirersByPaymentMethodPayload, AcquirersByPaymentMethodResponse } from '@/types/domains/Acquirer'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'

const acquirerService = authenticatedService('v1/acquirers')
const acquirerServiceV2 = authenticatedService('v2/acquirers')

const list = async (searchParams: Record<string, any>, pagination: MetaOnRequest, companyId: string): Promise<any> => {
  const params = { ...searchParams, company_id: companyId }

  return acquirerService.get({
    ...cleanPayload(params),
    ...pagination
  }).then(extractData)
}

const listAll = async (params: AcquirersByPaymentMethodPayload): Promise<AcquirersByPaymentMethodResponse> => {
  return acquirerService.get(cleanPayload(params), 'non_paginated').then(extractData)
}

const get = async (acquirerId: string): Promise<AcquirerRes | any> => acquirerService.get({}, acquirerId).then(extractData)

const create = async (acquirer: Acquirer): Promise<AcquirerRes | any> => {
  return acquirerService.post(acquirer)
    .then(extractData)
}

const createV2 = async (acquirer: AcquirerV2): Promise<AcquirerRes | any> => {
  return acquirerServiceV2.post(acquirer)
    .then(extractData)
}

const update = async (acquirer: Acquirer, acquirerId: string): Promise<AcquirerRes | any> => {
  return acquirerService.patch(cleanPayload(acquirer), acquirerId)
}

const updateV2 = async (acquirer: AcquirerV2, acquirerId: string): Promise<AcquirerRes | any> => {
  return acquirerServiceV2.patch(acquirer, acquirerId)
}

const remove = async (acquirerId: string): Promise<any> => acquirerService.remove({}, acquirerId)

acquirerService.setServiceMethods({ list, listAll, get, create, update, remove })
acquirerServiceV2.setServiceMethods({ create: createV2, update: updateV2 })

export const acquirerDomain = acquirerService.initialize
export const acquirerDomainV2 = acquirerServiceV2.initialize
