import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  CreateCombinedExperiencePayload,
  UpdateCombinedExperiencePayload,
  ListCombinedExperiencesParams,
  CreateCombinedExperienceResponse,
  ListCombinedExperiencesResponse,
  GetCombinedExperienceResponse,
  UpdateCombinedExperienceResponse,
  GetSellableCombinedExperienceResponse
} from '@/types/domains/CombinedExperience'

const combinedExperienceService = authenticatedService('/v1/experiences')
const sellableCombinedExperienceService = authenticatedService('/v1/sellable_experiences')

const create = (combinedExperience: CreateCombinedExperiencePayload): Promise<CreateCombinedExperienceResponse> => combinedExperienceService.post(combinedExperience).then(extractData)

const list = (pagination: ListCombinedExperiencesParams): Promise<ListCombinedExperiencesResponse> =>
  combinedExperienceService.get(pagination)
    .then(extractData)

const get = (serviceId: string): Promise<GetCombinedExperienceResponse> =>
  combinedExperienceService.get({}, `${serviceId}`).then(extractData)

const update = (serviceId: string, combinedExperience: UpdateCombinedExperiencePayload): Promise<UpdateCombinedExperienceResponse> =>
  combinedExperienceService.patch(combinedExperience, serviceId).then(extractData)

const listSellable = (pagination: ListCombinedExperiencesParams): Promise<ListCombinedExperiencesResponse> =>
  sellableCombinedExperienceService.get(pagination)
    .then(extractData)

const getSellable = (serviceId: string): Promise<GetSellableCombinedExperienceResponse> =>
  sellableCombinedExperienceService.get({}, `${serviceId}`).then(extractData)

combinedExperienceService.setServiceMethods({
  create,
  get,
  update,
  list
})

sellableCombinedExperienceService.setServiceMethods({
  getSellable,
  listSellable
})

export const combinedExperienceDomain = combinedExperienceService.initialize
export const sellableCombinedExperienceDomain = sellableCombinedExperienceService.initialize
