import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { PartnershipAccountEntry, PartnershipAccountEntryResponse, PartnershipAccountInfo, ShowTransactionResponse, PartnershipAccountTotalizers, PartnershipAccountParams, ColumnsToExportResponse } from '@/types/domains/PartnershipAccount'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'

const partnershipAccountService = authenticatedService('v1')
const partnershipAccountRequestURL = 'partnership_account'

interface ListTransactionsReqProps {
  pagination: MetaOnRequest;
  startDate?: string;
  endDate?: string;
  paymentType?: string
}

interface GetTotalizersReqProps {
  startDate?: string;
  endDate?: string;
  paymentType?: string;
}

type ExportParams = {
  searchParams: PartnershipAccountParams;
  pagination: MetaOnRequest;
}

const createEntry = (entry: PartnershipAccountEntry): Promise<PartnershipAccountEntryResponse | any> =>
  partnershipAccountService.post(entry, `${partnershipAccountRequestURL}_transactions`).then(extractData)

const getById = (partnershipAccountId: string): Promise<PartnershipAccountEntry | any> =>
  partnershipAccountService.get({}, `${partnershipAccountRequestURL}/${partnershipAccountId}`).then(extractData)

const listTransactions = (partnershipAccountId: string, props: ListTransactionsReqProps): Promise<any> => {
  return partnershipAccountService.get(props || {}, `${partnershipAccountRequestURL}/${partnershipAccountId}/transactions`).then(extractData)
}

const list = (searchParams: Record<string, any>, pagination: MetaOnRequest, companyId: string, type: string): Promise<any> => {
  const params = { ...searchParams }
  return partnershipAccountService.get({ ...cleanPayload(params), ...pagination }, `companies/${companyId}/${partnershipAccountRequestURL}s/${type}`).then(extractData)
}

const getTransactionById = (partnershipAccountId: string, partnershipTransactionId: string): Promise<ShowTransactionResponse> => {
  return partnershipAccountService.get({}, `${partnershipAccountRequestURL}/${partnershipAccountId}/transactions/${partnershipTransactionId}`).then(extractData)
}

const updateTransaction = (partnershipAccountId: string, transactionId: string, partnershipAccountInfo: PartnershipAccountInfo): Promise<any> => {
  return partnershipAccountService.patch(partnershipAccountInfo, `${partnershipAccountRequestURL}/${partnershipAccountId}/transactions/${transactionId}`)
}

const getTotalizers = (partnershipAccountId: string, props: GetTotalizersReqProps): Promise<PartnershipAccountTotalizers | any> =>
  partnershipAccountService.get(props, `${partnershipAccountRequestURL}/${partnershipAccountId}/transactions_totalizers`).then(extractData)

const csvExport = (
  { searchParams, pagination }: ExportParams,
  partnershipAccountId: string
): Promise<string> => {
  return partnershipAccountService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, `${partnershipAccountRequestURL}/${partnershipAccountId}/transactions_export/csv`).then(extractData)
}

const xlsxExport = (
  { searchParams }: ExportParams,
  partnershipAccountId: string
): Promise<string> => {
  return partnershipAccountService.get({
    ...cleanPayload(searchParams)
  }, `${partnershipAccountRequestURL}/${partnershipAccountId}/transactions_export/xlsx`, { responseType: 'arraybuffer' }).then(extractData)
}

const getColumnsToExport = (): Promise<ColumnsToExportResponse> => {
  return partnershipAccountService.get({}, `${partnershipAccountRequestURL}/pt-br/attributes_to_export`).then(extractData)
}

const getPaymentsType = (): Promise<any> => {
  return partnershipAccountService.get({}, '/partnership_account_transactions/types').then(extractData)
}

partnershipAccountService.setServiceMethods({
  createEntry,
  getById,
  list,
  listTransactions,
  getTransactionById,
  updateTransaction,
  getTotalizers,
  csvExport,
  xlsxExport,
  getColumnsToExport,
  getPaymentsType
})

export const partnershipAccountDomain = partnershipAccountService.initialize
