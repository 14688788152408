/**
 * Formats a date into human readable date
 * @param { String } [date=''] Date in format YYYY-MM-DD.*
 * @returns { String } formatted date
 */
export const formatDate = (date = '') => date.replace(/(\d{4})-(\d{2})-(\d{2})[\w\W]*/, '$3/$2/$1')

/**
 * Formats a date into human readable date as a input
 * @param { String } date Date in format YYYY-MM-DD or any of these spaces filled by _
 * @returns { String } formatted date
 */
export const formatInputDate = (date = '') => date.replace(/([0-2-_][\d_]|3[0-1])([0_][\d_]|[1_][0-2_])([\d_]{4})/, '$1/$2/$3')

/**
 * Masks given date to look a like a date, it doesn't validate the date, because it was bad to the UX
 * @param { String } date date to format
 * @returns { String } String in format ([0-2-_][\d_]|3[0-1])\/([0_][\d_]|[1_][0-2_])\/([\d_]{4})
 */
export const maskDate = (date = '') => {
  return formatInputDate(date
    .replace(/\D/g, '')
    .replace(/(\d{8})[\w\W]/, '$1')
    .padStart(8, '_'))
}
