/**
 * @desc Formats money into brazilian currency string
 * @param { String | Number } [money=0] A numeric value to convert to our currency
 * @param { String  } [currency='BRL'] String currency code
 * @param { String  } language String language code
 * @returns { String } [money=0] formatted money
 */
export const formatMoney = (money = 0, currency = 'BRL', language = 'pt-br') =>
  Number(money).toLocaleString(language, {
    style: 'currency',
    currency
  })

/**
 * @desc Unformats brazilian currency into number
 * @param { String } [money=''] A currency value to convert to numeric
 * @returns { Number } unformatted money
 */
export const unformatMoney = (money = '') => {
  const regex = /[A-Z$\s]+/g
  const commaRegex = /,/g

  // Remove currency symbols, spaces, and commas
  const stripped = String(money).replace(regex, '')

  // Replace commas with periods
  const withPeriods = stripped.replace(commaRegex, '.')

  // Only replace dots that are followed by digits
  const withoutExtraDots = withPeriods.replace(/\.+(\d|$)/g, '.$1')

  // Remove any dots that are followed by other dots
  const withoutConsecutiveDots = withoutExtraDots.replace(/\.(?=.*\.)/g, '')

  // Return the number
  return Number(withoutConsecutiveDots)
}

const thousandDelimiter = /([\w]+)(\d{3})(\.?[\w]*)/g

/**
 * @desc Given a amount, fills the thousands delimiters
 * @private
 * @param { String } amount amount to separate
 * @param { String } [separator='.'] character to append
 * @returns { String } amount with thousands separated
 */
const fillThousands = (amount, separator = '.') =>
  thousandDelimiter.test(amount)
    ? fillThousands(amount.replace(thousandDelimiter, `$1${separator}$2$3`))
    : amount

/**
 * @desc Formats money into brazilian currency string
 * @param { String | Number } [money=0] A numeric value to convert to our currency
 * @returns { String } formatted money
 */
export const formatInputMoney = (money = 0) =>
  `R$ ${fillThousands(
    Number(money)
      .toFixed(2)
      .replace('.', ',')
  )}`

/**
 * @desc Masks given value to look like brazilian currency, either formatting it, or rejecting it's characters
 * @param { String } [value='0'] value to format
 * @returns { String } String in format R$ (\d{1-3}(\.\d{3})*)+,\d{2}
 */
export const maskMoney = (value = '0') =>
  formatInputMoney(
    value
      .replace(/\.(\d)$/, '.$10')
      .replace(/\D/g, '')
      .padStart(3, '0')
      .replace(/([\d]*)(\d\d)/, '$1.$2')
  )

/**
 * @desc Formats money value into cents without float operations
 * @example
 * // returns 52330
 * toCents(523.3)
 * @example
 * // returns 52329.99999999999
 * 523.3 * 100
 * @param { String | Number } [value=0] A numeric value to convert to cents
 * @returns { Number } integer cents value
 */
export const toCents = (value = 0) => {
  // Polyfill from https://github.com/behnammodi/polyfill/blob/master/string.polyfill.js
  if (!String.prototype.padEnd) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(String.prototype, 'padEnd', {
      configurable: true,
      writable: true,
      /**
       * @param { Number } targetLength target string length
       * @param { String } padString string to pad to the end
       * @returns string padded on the end
       */
      value: function (targetLength, padString) {
        targetLength = targetLength >> 0 // floor if number or convert non-number to 0
        padString = String(typeof padString !== 'undefined' ? padString : ' ')
        if (this.length > targetLength) {
          return String(this)
        } else {
          targetLength = targetLength - this.length
          if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
          }
          return String(this) + padString.slice(0, targetLength)
        }
      }
    })
  }

  const valueStr = typeof value === 'string' || value instanceof String
    ? value.replace(/^[^\d.,]*/g, '') // Remove Currency Symbols
    : value.toFixed(4)

  const commasAndDots = valueStr.split('').reduce((acc, current) => {
    if (current === ',') return { ...acc, comma: acc.comma + 1 }
    if (current === '.') return { ...acc, dot: acc.dot + 1 }
    return acc
  }, { comma: 0, dot: 0 })
  if (commasAndDots.comma > 1 && commasAndDots.dot > 1) { // values with multiple commas and dots are invalid
    throw new Error('Invalid value')
  }

  const isValueValid = valueStr.match(/^\d?([.,\d]*)[\d.,]$/) // values that have any char other than , . or numbers are invalid
  if (!isValueValid) {
    throw new Error('Invalid value')
  }

  const commaFirstIndex = valueStr.indexOf(',')
  const dotFirstIndex = valueStr.indexOf('.')
  const separator = dotFirstIndex > commaFirstIndex ? '.' : ','

  const safeValue = separator === '.' ? valueStr.replace(/[,]/g, '') : valueStr.replace(/[.]/g, '')

  return parseInt(
    (safeValue + separator) // Parse Number to String, Ensure decimal places string exists
      .split(separator) // Split integer from decimals
      .slice(0, 2) // Keep only integer and decimal strings
      .map((val, i) => i
        ? val.padEnd(2, '0').substring(0, 2) // Fix to 2 decimal digits
        : val // Keep integer part untouched
      )
      .join('') // Merge strings
  ) || 0 // Change NaN with 0
}
