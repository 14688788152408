/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { LoadingContainer } from './styles'
import { LoadingAnimation } from '../Loading/LoadingAnimation'

export const Loading = () => (
  <LoadingContainer>
    <LoadingAnimation />
  </LoadingContainer>
)
