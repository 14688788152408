/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { pickTextColorBasedOnBgColor } from '@bonitour/common-functions'
import { Manager, Reference, Popper } from 'react-popper'
import { baseFont, softnedEdges } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'
import { flexRowCentered } from '../../assets/styles/layout'
import { TooltipArrow } from './TooltipArrow'

const tooltipBubble = css`
  ${baseFont}
  ${softnedEdges}
  ${flexRowCentered}
  text-align: center;
  margin: 5px;
  padding: 5px;
  box-shadow: 0 0 20px #00000033;
  min-height: 20px;
  min-width: 50px;
  z-index: 10;
`

const relative = css`
  position: relative;
`

const tooltipOnHover = css`
  position: absolute;
  bottom: 25px;
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
`

const tooltipOnHoverContainer = size => css`
  .tooltip-on-hover {
    display: none;
  }

  &:hover {
    .tooltip-on-hover {
      display: initial;
      width: ${isNaN(Number(1)) ? size : `${size}px`};
      white-space: initial;
    }
  }
`

const setColors = (color = colors.gray4) => css`
  color: ${pickTextColorBasedOnBgColor(color, colors.white, colors.gray2)};
  background-color: ${color};
`

export const BaseTooltip = React.forwardRef(
  (
    { arrowProps, position, children, color, customCss = [], ...other },
    ref
  ) => (
    <div
      {...other}
      css={[tooltipBubble, setColors(color), ...customCss]}
      ref={ref}
    >
      {children}
      <TooltipArrow position={position} color={color} {...arrowProps} />
    </div>
  )
)

const Tooltip = ({ position = 'bottom', ...other }) => {
  return (
    <Popper placement={position}>
      {({ ref, style, placement, arrowProps }) => (
        <BaseTooltip
          ref={ref}
          style={style}
          arrowProps={arrowProps}
          data-placement={placement}
          position={placement}
          {...other}
        />
      )}
    </Popper>
  )
}

const LightTooltip = props => <Tooltip {...props} color={colors.white} />

const TooltipOnHover = ({
  text = '',
  size = 'initial',
  children,
  position = 'top',
  css = undefined,
  customCss = undefined,
  tooltipStyle = [],
  ...other
}) => {
  return (
    <div
      css={[
        relative,
        flexRowCentered,
        tooltipOnHoverContainer(size),
        ...(Array.isArray(css) ? css : [css]),
        ...(Array.isArray(customCss) ? customCss : [customCss])
      ]}
      {...other}
    >
      <BaseTooltip
        position={position}
        className='tooltip-on-hover'
        css={[tooltipOnHover, ...tooltipStyle]}
      >
        {text}
      </BaseTooltip>
      {children}
    </div>
  )
}

export { Manager, Reference, Tooltip, LightTooltip, TooltipOnHover }
