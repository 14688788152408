export function isDocumentElement (el) {
  return [document.documentElement, document.body, window].indexOf(el) > -1
}

function scrollElement (el, top) {
  if (isDocumentElement(el)) {
    window.scrollTo(0, top)
    return
  }

  el.scrollTop = top
}

export function scrollIntoView (menuEl, focusedEl) {
  if (!focusedEl || !menuEl) {
    return
  }
  const menuRect = menuEl.getBoundingClientRect()
  const focusedRect = focusedEl.getBoundingClientRect()
  const overScroll = focusedEl.offsetHeight / 3

  if ((focusedRect.bottom + overScroll) > menuRect.bottom) {
    scrollElement(
      menuEl,
      Math.min(
        focusedEl.offsetTop +
          focusedEl.clientHeight -
          menuEl.offsetHeight +
          overScroll,
        menuEl.scrollHeight
      )
    )
  } else if ((focusedRect.top - overScroll) < menuRect.top) {
    scrollElement(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0))
  }
}
