/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { H3 } from '../../Headings/Headings'
import { BREAK_POINTS } from '../../Layout'
import { colors } from '../../../assets/styles/colors'
import { CashierListIcon, RoleIndicatorIcon } from '../../Icons/Icons'
import { flexCenteredSpaceBetweenRow, flexRowCentered } from '../../../assets/styles/layout'

const noMargin = css`
  margin: 0;
`

const marginRight20 = css`
  margin-right: 20px;
`

const tableOptions = css`
  display: none;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${flexCenteredSpaceBetweenRow};
    margin-bottom: 20px;
  }
`

const tableOptionIcon = css`
  font-size: 20px;
  color: ${colors.gray4};
`

const tableOptionIconSelected = css`
  color: ${colors.primary};
`

export const TableOption = ({
  title = '',
  cardSelected = false,
  onClickTableCardOption: emitClickTableCardOption = identity,
  onClickTableListOption: emitClickTableListOption = identity
}) => {
  return (
    <div css={tableOptions}>
      <H3 css={noMargin}>{title}</H3>
      <div css={flexRowCentered}>
        <RoleIndicatorIcon css={[tableOptionIcon, marginRight20, cardSelected && tableOptionIconSelected]} onClick={emitClickTableCardOption} />
        <CashierListIcon css={[tableOptionIcon, !cardSelected && tableOptionIconSelected]} onClick={emitClickTableListOption} />
      </div>
    </div>
  )
}
