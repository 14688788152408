import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { FinancialScheduleSearchParams, FinancialScheduleListResponse } from '@/types/domains/FinancialSchedule'

const financialScheduleService = authenticatedService('v1/creditcard_movements')

type ListParams = { searchParams: FinancialScheduleSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<FinancialScheduleListResponse> => {
  return financialScheduleService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

financialScheduleService.setServiceMethods({ list })

export const financialScheduleDomain = financialScheduleService.initialize
