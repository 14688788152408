import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { flexRowCentered, flex } from '../../../assets/styles/layout'

export const dropzone = css`
  width: 100%;
  min-height: 120px;
  border: 1px dashed ${colors.gray7};
  color: ${colors.gray3};
  cursor: pointer;
  border-radius: 10px;
  align-items: stretch;
  ${flex};

  input {
    display: none;
  }
`

export const dropzoneError = css`
  border-color: ${colors.red1}
`

export const dropzoneDisabled = css`
  background-color: ${colors.gray7};
  cursor: not-allowed;
`

export const dropzoneFileDragging = css`
  background-color: ${colors.gray9};
`

export const dropzoneContent = css`
  ${flexRowCentered}
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 0 0 100%;
  position: relative;
  max-width:100%;
`

export const dropzoneMessage = css`
  color: ${colors.gray3};
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  text-align: center;

  i {
    font-size: 35px;
    color: ${colors.gray3};
    margin-bottom: 20px;
  }
`
