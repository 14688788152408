/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H2 } from '../../Headings/Headings'
import { CircleThumbnail, InfoSwatch } from '../../Thumbnails/Thumbnails'
import { header, indicatorStyle, completed, titleStyle, topicsStyle, toolTipStyle } from './CollapsableSectionHeader.style'
import { flexRowCentered } from '../../../assets/styles/layout'

export const CollapsableSectionHeader = ({
  indicator,
  title,
  topics,
  isExpanded,
  isCompleted,
  tooltip,
  ...other
}) => (
  <div css={header} {...other}>
    <div css={flexRowCentered}>
      <CircleThumbnail size={35} css={[indicatorStyle, isCompleted && completed]}>
        {indicator}
      </CircleThumbnail>

      <H2 css={titleStyle}>{title}</H2>

      {tooltip &&
        <InfoSwatch tooltip={tooltip} size={250} css={toolTipStyle} />}
    </div>
    {isExpanded || <span css={topicsStyle}>{topics}</span>}
  </div>
)
