/* eslint-disable no-undef */
/**
 * Download a .xlsx file from server
 * @param { String } data value to be downloaded
 */
export const exportXlsx = (fileName = 'file', data) => {
  const url = URL.createObjectURL(new Blob([data],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
