/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

export const EmptyResult = ({ message = 'Nenhum item foi encontrado' }) => (
  <div
    className='empty_result'
  >
    <span>{message}</span>
  </div>
)
