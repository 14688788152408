/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CircleThumbnail } from '../Thumbnails/Thumbnails'
import { BellIcon } from '../Icons/Icons'
import { flexColumnCentered, flexRowCentered } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'

const emptyResult = css`
  margin: auto;
  max-width: 330px;
  padding: 50px;
  color: ${colors.info};
  text-align: center;
`

const emptyResultSubtitle = css`
  color: ${colors.info};
  font-weight: lighter;
`

const largeIcon = iconSize => css`
  font-size: ${iconSize}px;
  color: ${colors.gray8};
`

const emptyResultTitle = css`
  color: ${colors.gray3};
  margin: 20px 0;
  font-size: 23px;
  font-weight: 500;
`

const lightBackground = css`
  background-color: ${colors.white};
`
const darkBackground = css`
  background-color: ${colors.gray13};
`

const EmptyResultIcon = ({ iconSize = 140, ...other }) => (
  <CircleThumbnail {...other} css={flexRowCentered} size={iconSize * 1.5}>
    <BellIcon css={largeIcon(iconSize)} />
  </CircleThumbnail>
)

export const EmptyResult = ({
  title = 'Título sem resultado',
  subtitle = 'Subtitulo sem resultado',
  children,
  ...other
}) => (
  <div css={[flexColumnCentered, emptyResult]} {...other}>
    {children}
    <h2 css={emptyResultTitle}>{title}</h2>
    <p css={emptyResultSubtitle}>{subtitle}</p>
  </div>
)

export const LightEmptyResult = props => (
  <EmptyResult {...props}>
    <EmptyResultIcon css={lightBackground} {...props} />
  </EmptyResult>
)

export const DarkEmptyResult = props => (
  <EmptyResult {...props}>
    <EmptyResultIcon css={darkBackground} {...props} />
  </EmptyResult>
)
