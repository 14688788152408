import React, { useMemo } from 'react'

import { createNumberRangeArray } from '../../utils/createNumberRangeArray'

import { PageButton } from './PageSelector.styles'

const SIBLINGS_COUNT = 1

export const PageSelector = ({ onPageChange, currentPage, totalPages }) => {
  const previousPages = useMemo(
    () =>
      currentPage > 1
        ? createNumberRangeArray(currentPage - SIBLINGS_COUNT, currentPage - 1)
        : [],
    [currentPage]
  )

  const nextPages = useMemo(
    () =>
      currentPage < totalPages
        ? createNumberRangeArray(
          currentPage + 1,
          Math.min(currentPage + SIBLINGS_COUNT, totalPages)
        )
        : [],
    [currentPage, totalPages]
  )

  return (
    <>
      {currentPage > 1 + SIBLINGS_COUNT && (
        <>
          <PageButton onClick={() => onPageChange(1)}>1</PageButton>
          {currentPage > 3 + SIBLINGS_COUNT
            ? (
              <p>...</p>
            )
            : currentPage > 2 + SIBLINGS_COUNT
              ? (
                <PageButton onClick={() => onPageChange(2)}>2</PageButton>
              )
              : null}
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map((page) => (
          <PageButton
            onClick={() => onPageChange(page)}
            key={`Pagination ${page}`}
          >
            {page}
          </PageButton>
        ))}

      <PageButton className="selected">{currentPage}</PageButton>

      {nextPages.length > 0 &&
        nextPages.map((page) => (
          <PageButton
            onClick={() => onPageChange(page)}
            key={`Pagination ${page}`}
          >
            {page}
          </PageButton>
        ))}

      {currentPage + SIBLINGS_COUNT < totalPages && (
        <>
          {currentPage + 2 + SIBLINGS_COUNT < totalPages &&
          nextPages[nextPages.length - 1] !== totalPages - 1
            ? (
              <p>...</p>
            )
            : currentPage + 1 + SIBLINGS_COUNT < totalPages
              ? (
                <PageButton onClick={() => onPageChange(totalPages - 1)}>
                  {totalPages - 1}
                </PageButton>
              )
              : null}
          <PageButton onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </PageButton>
        </>
      )}
    </>
  )
}
