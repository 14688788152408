/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import dayjs from 'dayjs'

const indicatorWrapper = color => css`
  position: absolute;
  top: 20px;
  white-space: nowrap;
  color: ${color};
`

const emptyColor = colors.gray12

const formatDayMonth = date => dayjs(date).format('DD/MM/YY')

export const DateIntervalIndicator = ({ dateInterval, ...other }) => {
  const { color = emptyColor, initialDate = dayjs(), finalDate = dayjs() } = dateInterval
  const initialDateString = formatDayMonth(initialDate)
  const finalDateString = formatDayMonth(finalDate)

  return <span css={indicatorWrapper(color)} {...other}>{`${initialDateString} - ${finalDateString}`}</span>
}
