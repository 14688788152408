/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { identity } from '@bonitour/common-functions'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { GhostCircleButton } from '../Buttons/Buttons'
import { AngleRightIcon, AngleLeftIcon } from '../Icons/Icons'
import { colors } from '../../assets/styles/colors'
import {
  header,
  titleContainer,
  boxInsideTableData,
  labelInsideTableDate,
  disabledStyle,
  selectedSecondStyle,
  selectedFirstStyle,
  body,
  selectedDayStyle,
  widthFitContent,
  borderRadiusLeft,
  borderRadiusRight,
  width80,
  containerSpan,
  dot,
  redDot,
  container,
  selectedEqualStyle
} from './ExpandedCalendar.style'

const grayLetter = labelInsideTableDate(colors.gray6)
const whiteLetter = labelInsideTableDate(colors.white)

export const CalendarHeader = ({
  onPreviousClick,
  onNextClick,
  onHeaderClick,
  children
}) => {
  return (
    <div css={[header, flexHorizontallyCentered]}>
      {onPreviousClick && (
        <GhostCircleButton onClick={onPreviousClick}>
          <AngleLeftIcon />
        </GhostCircleButton>
      )}
      <div css={titleContainer}>
        <span onClick={onHeaderClick}>{children}</span>
      </div>
      {onNextClick && (
        <GhostCircleButton onClick={onNextClick}>
          <AngleRightIcon />
        </GhostCircleButton>
      )}
    </div>
  )
}

export const CalendarSelector = ({
  listDaysSmallWidth,
  weekDay,
  disabled,
  children,
  selectedFirstDate,
  selectedSecondDate,
  onClick: emitClickEvent = identity,
  selectedDays,
  selectedFirstDay,
  selectedSecondDay,
  hasMove,
  label,
  ...other
}) => {
  const equalDay = selectedFirstDate === selectedSecondDate

  // eslint-disable-next-line
  const isDaysSmallWidth = useCallback(() => listDaysSmallWidth.find(({ number }) => number === weekDay.number), [selectedDays])

  return (
    <div {...other} css={boxInsideTableData}>
      <div css={[
        widthFitContent,
        selectedDays && selectedDayStyle,
        selectedFirstDate && borderRadiusLeft,
        selectedSecondDate && borderRadiusRight,
        (!selectedSecondDay && !selectedSecondDate && !isDaysSmallWidth()) && width80
      ]}
      >
        <div
          onClick={!disabled ? emitClickEvent : identity}
          css={[
            container,
            disabled && disabledStyle,
            selectedDays ? whiteLetter : grayLetter,
            (selectedFirstDate && !equalDay) && selectedFirstStyle,
            (selectedSecondDate && !equalDay) && selectedSecondStyle,
            (selectedSecondDate && selectedFirstDate && equalDay) && selectedEqualStyle
          ]}
        >
          <div css={containerSpan}>
            {children}
            {hasMove ? <div css={[dot, redDot]} /> : <div css={dot} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export const CalendarTable = props => (
  <table css={body} cellSpacing='0' cellPadding='0'>
    <tbody {...props} />
  </table>
)
