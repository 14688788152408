const timeRegex = /^([0-1][\d]|[2][0-3])(?:(\d{2}?)$)/g
const timeMask = '$1:$2'

/**
 * Formats a 4 length number into human readable Time
 * @param { String } time number of 4 digits length
 * @returns { String } formatted time
 */
export const formatTime = time => time.replace(timeRegex, timeMask)

/**
 * Cleans string and add 0 to the start of the string
 * @private
 * @param {*} time
 */
const padTime = (time = '') => time.replace(/\D/g, '').padStart(4, '0')

/**
 * Checks if given time is a valid time
 * @private
 * @param { String } time given time
 * @returns { Boolean } is valid
 */
const isValidInput = (time = '') => /^([0-1][\d]|[2][0-3])(?:(\d{2}?)$)/g.test(time)

/**
 * Masks given time, either formatting it, or rejecting it's characters
 * @param { String } [time=''] given time
 * @returns { String } masked time
 */
export const maskTime = (time = '') => {
  const filledTime = padTime(time)
  if (isValidInput(filledTime)) {
    return formatTime(filledTime)
  }
  return maskTime(filledTime.substring(1, time.length))
}

/**
 * Validates a time passed through here
 * @global
 * @param { String } [time=''] Time string
 * @returns { Boolean } wheter it's valid or not
 */
export function validateTime (time = '') {
  const pattern = /^([0-1]\d|2[0-3]):[0-5][0-9]$/g
  return pattern.test(time)
}
