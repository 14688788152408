import { authenticatedService } from '@/interfaces/authenticatedService'
import { PartnershipRefund, PartnershipRefundResponse } from '@/types/domains/ProviderRefund'
import { extractData } from '@/utils/data-extractor'

const prodiverRefundService = authenticatedService('/v1/reservations')

prodiverRefundService.setServiceMethods(
  {
    create (refund: PartnershipRefund, reservationId: string): Promise<PartnershipRefundResponse> {
      return prodiverRefundService.postFormData(refund, `${reservationId}/advance_refunds`)
    },
    getById (reservationId: string, refundId: string): Promise<PartnershipRefundResponse> {
      return prodiverRefundService.get({}, `${reservationId}/advance_refunds/${refundId}`).then(extractData)
    },
    approveRefundTransaction (reservationId: string, paymentId: string) {
      return prodiverRefundService.patch({ }, `${reservationId}/advance_refunds/${paymentId}/approve`)
    },
    rejectRefundTransaction (reservationId: string, paymentId: string) {
      return prodiverRefundService.patch({ }, `${reservationId}/advance_refunds/${paymentId}/reject`)
    }
  }
)

export const providerRefundDomain = prodiverRefundService.initialize
