/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TimelineBar } from './TimelineBar'
import { TimelinePeriod } from './TimelinePeriod'
import { head, tail } from '@bonitour/common-functions'
import dayjs from 'dayjs'

const calculateDiffInDays = (initialDate = new Date(), finalDate = new Date()) =>
  dayjs(finalDate).diff(initialDate, 'day')

const widthInPercentage = (contentWidth, containerWidth) =>
  css`
    width: ${(contentWidth || 1 / containerWidth || 1) * 100}%;
  `

const TimelineContent = ({ periodList }) => {
  const { initialDate: referenceInitialDate } = head(periodList)
  const { finalDate: referenceFinalDate } = tail(periodList)
  const timelineDayCount = calculateDiffInDays(referenceInitialDate, referenceFinalDate)

  return periodList.map((dateInterval, index) => {
    const intervalDayCount = calculateDiffInDays(dateInterval.initialDate, dateInterval.finalDate) || 1

    return (
      <TimelinePeriod
        key={index}
        css={widthInPercentage(intervalDayCount, timelineDayCount)}
        dateInterval={dateInterval}
      />
    )
  })
}

export const Timeline = ({ periodList = [], ...other }) => (
  <TimelineBar {...other}>
    <TimelineContent periodList={periodList} />
  </TimelineBar>
)

export { BLANK_SPACE } from './TimelinePeriod'
