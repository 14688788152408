/**
 * @typedef { keyof T } Keyof
 * @template T
 */

/**
 * @callback changeObjectValue updates object value and triggers emitterFunction
 * @param { T[Keyof<T>] } value value to update
 * @returns { U } whatever emitterFunction returns
 * @template T, U
 */

/**
 * @callback changeValueByKey changes object value based on passed key
 * @param { Keyof<T> } key key to update with value to be passed later
 * @returns { changeObjectValue<T, U> } function to change object value
 * @template T, U
 */

/**
 * Curries object change so that you can set you emitter function and object, then the key to change,
 * then your value, and finally update your object
 * @global
 * @param { function(T) : U } emitterFunction function to emit change event
 * @param { T } object object to be changed
 * @returns { changeValueByKey<T, U> } ChangeValueByKey function
 * @template T, U
 */
export const curriedObjectChange = (emitterFunction, object) => key => value =>
  emitterFunction({ ...object, [key]: value })
