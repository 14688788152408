// @ts-check
import React from 'react'

/**
 * @param { T } initialValue
 * @param { number } ms
 * @return { { value: T, waiting: boolean, update: function (function (T) : T, boolean | undefined) : void } }
 * @template T
 */
export function useThrottledState (initialValue, ms) {
  const [{ value, waiting }, setValueOrignal] = React.useState({
    value: initialValue,
    waiting: false
  })

  const lastCallRef = React.useRef(0)

  const update = React.useCallback(
    /**
     * @param { function (T) : T } valueUpdater
     * @param { boolean } force
     */
    (valueUpdater, force) => {
      if (force) {
        setValueOrignal(({ value, ...rest }) => ({
          ...rest,
          value: valueUpdater(value)
        }))
      } else if (lastCallRef.current + ms <= new Date().getTime()) {
        setValueOrignal(({ value, ...rest }) => ({
          ...rest,
          value: valueUpdater(value),
          waiting: true
        }))
        setTimeout(() => {
          setValueOrignal(state => ({
            ...state,
            waiting: false
          }))
        }, ms)
        lastCallRef.current = new Date().getTime()
      }
    },
    [lastCallRef, setValueOrignal, ms]
  )

  return {
    value,
    waiting,
    update
  }
}
