/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { BarsIcon } from '../../Icons/Icons'

const hamburguer = css`
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
`

export const Hamburguer = props => (
  <BarsIcon {...props} css={hamburguer} />
)
