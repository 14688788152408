import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { OfflineExperience, OfflineExperienceItem, OfflineExperienceListResponse, OfflineExperienceSearchParams } from '@/types/domains/OfflineExperience'

const offlineExperienceService = authenticatedService('/v1/offline_experiences')

const get = (serviceId: string): Promise<OfflineExperienceItem> =>
  offlineExperienceService.get({}, `${serviceId}`).then(extractData)

const create = (offlineExperience: OfflineExperience): Promise<OfflineExperienceItem> =>
  offlineExperienceService.postFormData(offlineExperience).then(extractData)

type ListParams = { searchParams: OfflineExperienceSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<OfflineExperienceListResponse> => {
  return offlineExperienceService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const update = (serviceId: string, offlineExperience: OfflineExperience): Promise<OfflineExperienceItem> =>
  offlineExperienceService.patch(offlineExperience, serviceId).then(extractData)

const updateAsFormData = (serviceId: string, offlineExperience: OfflineExperience) =>
  offlineExperienceService.patchFormData(offlineExperience, serviceId).then(extractData)

offlineExperienceService.setServiceMethods({ get, create, list, update, updateAsFormData })

export const offlineExperienceDomain = offlineExperienceService.initialize
