/** @jsxRuntime classic */
/** @jsx jsx */
import { forwardRef } from 'react'
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { relative } from '../../assets/styles/styles'
import { scrollBar } from '../../assets/styles/addons'
import { BREAK_POINTS } from '../Layout'

const container = arrowOffset => css`
  position: absolute;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.2);
  top: 15px;
  right: -30px;
  max-width: 500px;
  padding: 5px 10px 5px 15px;
  z-index: 1;

  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${colors.white};
    right: ${arrowOffset}px;
    top: calc(0% - 9px);
  }
`

const drawerContainer = css`
  background: ${colors.white};
  border: 1px solid ${colors.gray11};
  padding: 20px;
  border-radius: 10px;
`

const itemList = css`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px;
  padding-right: 9px;

  ${scrollBar(3)};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 200px;
  }
`

export const Drawer = forwardRef(({ customCss = [], children, mobileHeader, arrowOffset = 15, ...other }, ref) => {
  const MobileHeader = mobileHeader

  return (
    <div {...other} css={[relative, ...customCss]} className='component-drawer'>
      {mobileHeader && <MobileHeader />}
      <div css={[drawerContainer, container(arrowOffset)]} className='component-drawer-container'>
        <div css={itemList} ref={ref} className='component-drawer-list'>{children}</div>
      </div>
    </div>
  )
})
