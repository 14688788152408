/**
 * Normalize strings
 * @param { string } value Value that will be normalized
 */
export const normalizeString = (str) => {
  const accentsMap = {
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ'
  }
  return Object.keys(accentsMap).reduce((acc, curr) => {
    return acc.replace(new RegExp(accentsMap[curr], 'g'), curr)
  }, str.toLowerCase())
}
