import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

const additionInformationService = authenticatedService('/v1/services/additional_information')

const list = (type: string) => additionInformationService.get({ type }).then(extractData)

additionInformationService.setServiceMethods({ list })

export const additionInformationDomain = additionInformationService.initialize
