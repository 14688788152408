import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'

const vendorService = authenticatedService('/v1/companies')

vendorService.setServiceMethods({
  list (companyId: string): Promise<any> {
    return vendorService.get({}, `${companyId}/vendors/all`)
      .then(extractData)
  }
})

export const vendorDomain = vendorService.initialize
