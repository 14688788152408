import styled from '@emotion/styled'

import { BREAK_POINTS } from '../Layout'
import { colors } from '../../assets/styles/colors'

export const PaginationBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 3.125rem;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column-reverse;
    justify-content: flex-start;

    margin-top: 0.5rem;

    > div {
      margin-top: 2.5rem;
    }
  }
`

export const PerPageSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .per_page__selector {
    margin: 0 0.75ch;

    input {
      max-width: 1.75rem;
    }
  }

  .selector__text {
    font-size: 0.875rem;
    color: ${colors.gray5};
  }
`

export const PageSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  user-select: none;

  &.hide_selection {
    display: none;
  }

  .page_selectors__btns_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    min-height: 2.5rem;
    background: ${colors.gray11};
    border-radius: var(--round-element-radius);

    padding: 0.25rem 1rem;
    margin-left: 0.75rem;
  }

  .page_selector__label {
    color: ${colors.gray5};
  }
`
