import { css } from '@emotion/core'

export const tableBodyRow = css`
  td:first-of-type {
    border-radius: 10px 0px 0px 10px;
  }

  td:last-of-type {
    border-radius: 0px 10px 10px 0px;
  }
`
