/** @jsxRuntime classic */
/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { colors } from '../../../../assets/styles/colors'
import { flexHorizontallyCentered } from '../../../../assets/styles/layout'
import { CircleThumbnail } from '../../../../components/Thumbnails/Thumbnails'

const container = css`
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${colors.gray13};
`

const notReadBackground = css`
  background-color: ${colors.gray13};
`

const titleContainer = css`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.gray3};
  margin-top: 1px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  max-height: 30px;
`

const textContainer = css`
  width: calc(100% - 60px);
  padding: 5px;
`

const subtitleContainer = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-top: 1px;
  margin-bottom: 4px;
  width: 100%;
  text-overflow: ellipsis;
  height: 25px;
  overflow: hidden;
  max-width: 100%;
  word-wrap: break-word;
`

const imageContainer = css`
  box-sizing: border-box;
  margin-right: 10px;
  background-color: ${colors.gray10};
  font-weight: bold;
  color: ${colors.gray5};
  text-transform: uppercase;
`

export const NotificationItem = ({ isRead, initials, title, subtitle, ...props }) => {
  return (
    <div css={[flexHorizontallyCentered, container, !isRead && notReadBackground]} {...props}>
      <CircleThumbnail css={imageContainer} size={40}>
        {initials}
      </CircleThumbnail>
      <div css={textContainer}>
        <p css={titleContainer}>{title}</p>
        <p css={subtitleContainer}>{subtitle}</p>
      </div>
    </div>
  )
}

NotificationItem.propTypes = {
  isRead: PropTypes.bool,
  initials: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

NotificationItem.defaultProps = {
  isRead: false,
  initials: '',
  title: '',
  subtitle: ''
}
