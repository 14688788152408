import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { identity } from '@bonitour/common-functions'
import { buttonStyle, divContainer, inputErrorStyle, inputStyle, libStyle } from './PhoneSelector.style'
import pt from 'react-phone-input-2/lang/pt.json'
import es from 'react-phone-input-2/lang/es.json'

export const PhoneSelector = ({
  locale = 'pt',
  defaultCountry = 'br',
  defaultDDI = '55',
  defaultFormat = '+.. (..) .........',
  value = defaultDDI,
  onChange = identity,
  customCss = [],
  preferredCountries = ['br'],
  placeholder = '',
  cropPlaceholder = !placeholder,
  error = false,
  customId = '',
  ...other
}) => {
  const localization = useMemo(() => {
    const localizations = { pt, es }
    const lang = locale.toLowerCase().split(/[^a-z]/)
    return localizations[lang[0]] // undefined defaults to en
  }, [locale])

  const PhoneInputComponent = useMemo(
    () => typeof PhoneInput?.default === 'function' ? PhoneInput.default : PhoneInput,
    []
  )

  const [currentDDI, setDDI] = useState(defaultDDI)
  const [currentFormat, setFormat] = useState('+.. (..) .........')

  const handleOnChange = useCallback((value, data, event, formattedValue) => {
    const isEventFromCountryList = event._reactName !== 'onChange'
    const newValue = (!isEventFromCountryList || value.startsWith(data.dialCode))
      ? value
      : data.dialCode
    onChange(newValue, data, formattedValue)
    setDDI(data.dialCode || defaultDDI)
    setFormat(data.format || defaultFormat)
  }, [defaultDDI, defaultFormat, onChange])

  const defaultPlaceholder = useMemo(
    () => currentFormat
      .replace(new RegExp(`\\+\\.{${currentDDI.length}}`), `+${currentDDI}`)
      .replace(/\./g, '0'),
    [currentDDI, currentFormat]
  )

  const placeholderText = useMemo(() => {
    const text = placeholder || defaultPlaceholder
    const charsToCrop = value.length ? value.length + 1 : 0
    const croppedPlaceholder = text.substring(charsToCrop)
    return (cropPlaceholder ? croppedPlaceholder : text).replace(/\s/g, '\xa0')
  }, [cropPlaceholder, defaultPlaceholder, placeholder, value.length])

  const [everHadValue, setEverHadValue] = useState(Boolean(value))
  useEffect(() => {
    if (!everHadValue && value) {
      setEverHadValue(true)
    }
  }, [everHadValue, value])

  useEffect(() => {
    // PhoneInput starts with the default DDI on mount if value is empty
    if (!value && !everHadValue) onChange(defaultDDI)
  }, [defaultDDI, everHadValue, onChange, value])

  const elRef = useRef()

  useEffect(() => {
    if (customId && elRef.current) {
      elRef.current.querySelector('input.form-control')?.setAttribute('id', customId)
    }
  }, [customId])

  const placeholderMargin = useMemo(() =>
    value.length
      ? value.length + (!cropPlaceholder ? 1 : 0)
      : -1
  , [cropPlaceholder, value.length])

  return (
    <div ref={elRef} css={[divContainer({ ...other }), libStyle, ...customCss]}>
      <PhoneInputComponent
        localization={localization}
        enableSearch
        disableSearchIcon
        country={defaultCountry}
        buttonStyle={buttonStyle}
        inputStyle={!error ? inputStyle({ ...other }) : inputErrorStyle({ ...other })}
        placeholder=''
        value={value || ''}
        onChange={handleOnChange}
        preferredCountries={preferredCountries}
        searchPlaceholder=''
        {...other}
      />
      {value.length <= currentDDI.length
        ? (
          <span
            className='fake-placeholder'
            style={{ marginLeft: `${placeholderMargin}ch` }}
          >
            {placeholderText}
          </span>
        )
        : null}
    </div>
  )
}
