import { EmployeeInviteErrorMapping } from './error.map'
import { extractData } from '@/utils/data-extractor'
import { EmployeeInvitation } from '@/types/domains/Employee'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { parseError } from '@/utils/parse-error'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { cleanPayload } from '@/utils/clean-payload'

const employeesService = authenticatedService('v1/employees')

type ListParams = { filters: any; pagination: MetaOnRequest }

type ListByCompanyIdParams = { pagination: MetaOnRequest; company_id: string }

const getById = (userId: string): Promise<any> => employeesService.get({}, userId).then(extractData)

const getList = (): Promise<any> => employeesService.get().then(extractData)

const getPaginatedList = ({ filters, pagination }: ListParams): Promise<any> => {
  const params = { ...filters }
  return employeesService.get({
    ...cleanPayload(params),
    ...pagination
  }).then(extractData)
}

const getPaginatedAllList = (pagination: MetaOnRequest): Promise<any> => {
  return employeesService.get({ ...pagination }, 'all/info').then(extractData)
}

const getEmployeeListByCompanyId = ({ company_id, pagination }: ListByCompanyIdParams): Promise<any> => {
  return employeesService.get({ ...pagination, company_id }, 'all/info').then(extractData)
}

const toggleActive = (id: string): Promise<any> => {
  return employeesService.patch({}, `${id}/toggle_active`)
}

const editUserRoles = (userId: string, payload: any): Promise<any> => {
  return employeesService.put(payload, `${userId}/roles`)
}

const getInvite = (inviteId: string) => {
  return employeesService.get({}, `invite/${inviteId}`).then(extractData)
}

const inviteEmployee = (invitation: EmployeeInvitation) => {
  return employeesService.post(invitation, 'invite').catch(parseError(EmployeeInviteErrorMapping))
}

employeesService.setServiceMethods({ getById, getList, getPaginatedList, getPaginatedAllList, toggleActive, editUserRoles, getInvite, inviteEmployee, getEmployeeListByCompanyId })

export const employeesDomain = employeesService.initialize
