import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import type { Payment, PaymentLinkNonSplittedProps, PaymentLinkNonSplittedResponse, PaymentResponse, PaymentTransfer, PaymentTransferResponse, ReasonDelete } from '@/types/domains/Financial'

const reservationPaymentService = authenticatedService('/v1/reservations')
const reservationPaymentServiceV2 = authenticatedService('/v2/reservations')

reservationPaymentService.setServiceMethods({
  getById (reservationId: string, paymentId: string): Promise<PaymentResponse> {
    return reservationPaymentService.get({}, `${reservationId}/payments/${paymentId}`).then(extractData)
  },
  getPaymentLinkById (reservationId: string, paymentId: string): Promise<PaymentResponse> {
    return reservationPaymentService.get({}, `${reservationId}/payment_links/${paymentId}`).then(extractData)
  },
  create (payment: Payment, reservationId: string): Promise<any> {
    return reservationPaymentService.postFormData(payment, `${reservationId}/payments`)
      .then(extractData)
  },
  createLink (payment: Payment, reservationId: string): Promise<any> {
    return reservationPaymentService.postFormData(payment, `${reservationId}/payment_links`)
      .then(extractData)
  },
  createLinkMultiponto (payment: Payment, reservationId: string): Promise<any> {
    return reservationPaymentService.postFormData(payment, `${reservationId}/payment_link_multiponto`)
      .then(extractData)
  },
  createLinkNonSplitted (payment: PaymentLinkNonSplittedProps, reservationId: string): Promise<PaymentLinkNonSplittedResponse> {
    return reservationPaymentService.postFormData(payment, `${reservationId}/payment_link_non_splitted`)
      .then(extractData)
  },
  expireLink (reservationId: string, paymentId: string): Promise<any> {
    return reservationPaymentService.putFormData({}, `${reservationId}/payment_links/${paymentId}/mark_as_expired`)
  },
  edit (payment: Payment, reservationId: string, paymentId: string): Promise<any> {
    return reservationPaymentService.patchFormData(payment, `${reservationId}/payments/${paymentId}`)
      .then(extractData)
  },
  remove (reservationId: string, paymentId: string, paymentRemove: ReasonDelete): Promise<any> {
    return reservationPaymentService.remove(paymentRemove, `${reservationId}/payments/${paymentId}`)
      .then(extractData)
  },
  transfer (reservationId: string, transferData: PaymentTransfer): Promise<PaymentTransferResponse> {
    return reservationPaymentService.postFormData(transferData, `${reservationId}/payment_transfers`).then(extractData)
  }
})

reservationPaymentServiceV2.setServiceMethods({
  create (payment: Payment, reservationId: string): Promise<any> {
    return reservationPaymentServiceV2.postFormData(payment, `${reservationId}/payments`)
      .then(extractData)
  },
  getById (reservationId: string, paymentId: string): Promise<PaymentResponse> {
    return reservationPaymentServiceV2.get({}, `${reservationId}/payments/${paymentId}`).then(extractData)
  },
  getAdvancePaymentById (reservationId: string, paymentId: string) {
    return reservationPaymentServiceV2.get({}, `${reservationId}/payments/${paymentId}`).then(extractData)
  }
})

export const reservationPaymentDomain = reservationPaymentService.initialize
export const reservationPaymentDomainV2 = reservationPaymentServiceV2.initialize
