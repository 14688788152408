import { css, keyframes } from '@emotion/core'
import { resetButton } from '../../assets/styles/button'
import { colors } from '../../assets/styles/colors'

const progressBar = css`
  width: 100%;
  position: absolute;
  height: 5px;
  bottom: 0;
  left: 0;
`

const progressBarAnimation = keyframes`
  0% {
      width: 100%;
  }
  100% {
      width: 0%;
  }
`

const animationProgressBar = css`
  animation: ${progressBarAnimation};
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-iteration-count: 1;
`

export const closeIcon = css`
  cursor: pointer;
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  top: 30%;
  right: 25px;
  ${resetButton}
  i {
    color: #ffffff;
  }
`

export const closeGrayIcon = css`
  & > button i {
    color: #aaaaaa;
  }
`

export const toastContent = css`
  margin-right: 50px;
`

export const progressBarLowerLayer = css`
  ${progressBar}
  background: rgba(256,256,256,0.4);
`

export const progressBarUpperLayer = css`
  ${progressBar}
  filter: contrast(75%);
  z-index: 1;
  background: inherit;
  ${animationProgressBar}
`

export const continueButton = css`
  color: ${colors.warning};
  background-color: ${colors.white};
`
export const cancelButton = css`
  border: none;
  color: ${colors.white};
`
