/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { forwardRef } from 'react'
import { clickable } from '../../assets/styles/styles'
import { AngleDownIcon } from '../Icons/Icons'
import { selectArrowIcon, selectContainer, arrowOpenIndicator, selectError, selectDisabled } from './Select.style'

export const StandaloneSelect = forwardRef(
  ({ index, children, isOpened = false, error: hasError, disabled: isDisabled, customCss = [], ...other }, ref) => {
    return (
      <div
        {...other}
        ref={ref}
        css={[selectContainer, clickable, hasError && selectError, isDisabled && selectDisabled, ...customCss]}
        tabIndex='0'
        role='button'
      >
        {children}
        <AngleDownIcon css={[selectArrowIcon, isOpened && arrowOpenIndicator]} />
      </div>
    )
  }
)
