/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { Card } from '../../Card/Card'
import { Image } from '../../Image/Image'
import { CircleThumbnail } from '../../Thumbnails/Thumbnails'

const getFirstChar = (string = '') => string ? string[0] : ''

const container = css`
  display: flex;
  align-items: center;
  padding: 10px 5px;
  margin-bottom: 10px;
`
const title = css`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.gray3};
  margin-top: 1px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
  white-space: nowrap;
`

const fullWidth = css`
  width: 100%;
`

const imageContainer = css`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-color: ${colors.gray12};
  font-weight: bold;
  color: ${colors.gray5};
  text-transform: uppercase;
`

export const CompanyCard = ({ name, image }) => {
  return (
    <Card css={container}>
      <CircleThumbnail css={imageContainer} size={40}>
        {image ? <Image css={fullWidth} src={image} /> : getFirstChar(name)}
      </CircleThumbnail>
      <div>
        <p css={title}>{name}</p>
      </div>
    </Card>
  )
}
