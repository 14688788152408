/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { baseFont } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'

const styles = css`
  ${baseFont}
  display: inline-block;
  background-color: ${colors.gray12};
  color: ${colors.gray3};
  padding: 14px;
  border-radius: 23px;
`

export const Tag = ({ children, ...other }) => (
  <div css={styles} {...other}>
    {children}
  </div>
)
