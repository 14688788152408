import { getUniqueValues } from './array'
import { compose } from './compose'

const cnpjRegex = /^([\d_]{2})([\d_]{3})([\d_]{3})([\d_]{4})([\d_]{2})$/g
const cnpjMask = '$1.$2.$3/$4-$5'

/**
 * Formats a 14 length number into human readable Cnpj
 * @param { String } cnpj number of 14 digits length
 * @returns { String } formatted cnpj
 */
export const formatCnpj = cnpj => cnpj.replace(cnpjRegex, cnpjMask)

/**
 * Masks given value to look like brazilian CNPJ, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format (\d{2}).(\d{3}).?(\d{3})/?(\d{4})-?(\d{2})
 */
export const maskCnpj = (value = '') =>
  formatCnpj(
    value
      .replace(/\D/g, '')
      .replace(/(\d{14})[\w\W]/, '$1')
      .padStart(14, '_')
  )

/**
 * Gets the number to multiply digit with
 * @private
 * @param { Number } length number of digits to use as a base
 * @param { Number } index index of digit who will be multiplied
 * @returns { Number } multiplier
 */
const getMultiplier = (length, index) => {
  const multiplier = length - 7 - index
  return multiplier < 2 ? multiplier + 8 : multiplier
}

/**
 * Sums CPF digits based on specification rules
 * @private
 * @param { Array<Number> } digits CPF digits
 * @returns { Number } Sum of all it's digits multiplied by the right weight
 */
const sumCnpjDigits = digits =>
  digits.map((digit, index, { length }) => digit * getMultiplier(length, index)).reduce((acc, curr) => acc + curr)

/**
 * Based on a given number, returns the next CPF digit
 * Rules are: (11 - `sum` ) module eleven
 * @private
 * @param { Number } sum Previous digits sum
 * @returns { Number } next digit
 */
const calculateCnpjDigit = sum => (sum % 11 < 2 ? 0 : 11 - (sum % 11))

/**
 * gets the next CPF digits based on specification rules
 * @private
 * @param { Array<Number> } cnpjNumbers Array of numbers to sum
 * @returns { Number } next digit
 */
const getDigit = compose(calculateCnpjDigit, sumCnpjDigits)

/**
 * Validates a cpf passed through here
 * @global
 * @param { String } [cnpj=''] CPF string
 * @returns { Boolean } wheter it's valid or not
 */
export function validateCnpj (cnpj = '') {
  const cnpjArray = cnpj
    .replace(/\D/g, '')
    .split('')
    .map(Number)
  if (cnpjArray.length !== 14 || getUniqueValues(cnpjArray).length === 1) {
    return false
  }
  const cnpjNumbers = cnpjArray.filter((_, i) => i < 12)
  const firstDigit = getDigit(cnpjNumbers)
  const secondDigit = getDigit([...cnpjNumbers, firstDigit])

  return [firstDigit, secondDigit].join('') === cnpjArray.filter((_, i) => i >= 12).join('')
}
