import { deepGetOrElse } from 'deep-getter'
const encodeSemicolon = (value = '') => value.replace(/;/g, '%3B')

function buildCookieString (key, value, attributes) {
  const treatedKey = encodeSemicolon(key)
  const treatedValue = encodeURIComponent(value)

  const stringifiedAttributes = Object.entries(attributes).reduce((acc, [key, value]) => {
    if (value) {
      acc += `; ${key}`
      if (value !== true) {
        acc += `=${encodeSemicolon(value)}`
      }
      return acc
    }
    return acc
  }, '')

  return `${treatedKey}=${treatedValue}${stringifiedAttributes};`
}

const cookieKeyFilterFunction = key => cookie => cookie.indexOf(key) !== -1

export function cookieStorage (defaultAttributes = { path: '/' }, prefix = '') {
  const buildStorageKey = (key = '') => (prefix ? `${prefix}-${key}` : key)

  function setByKey (key, value, attributes = {}) {
    if (!window.document) {
      return
    }

    const cookieAttributes = {
      ...defaultAttributes,
      ...attributes
    }

    if (typeof cookieAttributes.expires === 'number') {
      cookieAttributes.expires = new Date(Date.now() + cookieAttributes.expires * 864e5)
    }
    if (cookieAttributes.expires) {
      cookieAttributes.expires = cookieAttributes.expires.toUTCString()
    }

    return (document.cookie = buildCookieString(key, value, cookieAttributes))
  }

  function getByKey (key) {
    if (!window.document || !key) {
      return
    }

    const storageKey = `${key}=`
    const cookies = deepGetOrElse(window, 'document.cookie', '').split('; ')
    const foundCookie = cookies.find(cookieKeyFilterFunction(storageKey))

    if (foundCookie) {
      return decodeURIComponent(foundCookie.split('=')[1])
    }
  }

  function removeByKey (key, attributes = {}) {
    return setByKey(key, '', { ...attributes, expires: -1 })
  }

  function get (key) {
    const storageKey = buildStorageKey(key)
    return getByKey(storageKey)
  }

  function set (key, value, attributes = {}) {
    const storageKey = buildStorageKey(key)
    return setByKey(storageKey, value, attributes)
  }

  function remove (key, attributes = {}) {
    const storageKey = buildStorageKey(key)
    return removeByKey(storageKey, '', { ...attributes, expires: -1 })
  }

  function clean (attributes = {}) {
    if (!window.document) {
      return
    }

    const prefixSearch = buildStorageKey()
    const cookies = deepGetOrElse(window, 'document.cookie', '').split('; ')
    const cookiePrefixes = cookies.map(cookie => cookie.split('=')[0]).filter(cookieKeyFilterFunction(prefixSearch))
    cookiePrefixes.forEach(cookie => removeByKey(cookie, attributes))
  }

  return { set, get, remove, clean }
}
