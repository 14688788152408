import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { Passenger, PassengerRes } from '@/types/domains/Passenger'

const passengerService = authenticatedService('v1/passengers')

const list = (searchParams: Record<string, any>): Promise<any> => {
  const params = { ...searchParams }
  return passengerService.get({ ...cleanPayload(params) }).then(extractData)
}

const get = (passengerId: string): Promise<any> => passengerService.get({}, passengerId).then(extractData)

const create = (passenger: Passenger): Promise<PassengerRes | any> => {
  return passengerService.post(passenger).then(extractData)
}

const update = (passenger: Passenger, passengerId: string): Promise<PassengerRes | any> => {
  return passengerService.patch(cleanPayload(passenger), passengerId)
}

passengerService.setServiceMethods({ list, get, create, update })

export const passengerDomain = passengerService.initialize
