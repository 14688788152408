/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { flexRow } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'

const timelineContainer = css`
  background-color: ${colors.gray12};
  border-radius: 10px;
  min-height: 10px;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
`

export const TimelineBar = props => <div css={[timelineContainer, flexRow]} {...props} />
