import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { PaymentLinksResponse, PaymentsResponse, RefundsResponse, TransactionResponse } from '@/types/domains/Transaction'

const financialTransactionsService = authenticatedService('/v1/reservations')

financialTransactionsService.setServiceMethods({
  list (reservationId: string): Promise<TransactionResponse> {
    return financialTransactionsService
      .get({}, `${reservationId}/financial_transactions`)
      .then(extractData)
  },

  paymentLinks (reservationId: string): Promise<PaymentLinksResponse> {
    return financialTransactionsService
      .get({}, `${reservationId}/payment_links`)
      .then(extractData)
  },

  payments (reservationId: string): Promise<PaymentsResponse> {
    return financialTransactionsService
      .get({}, `${reservationId}/payments`)
      .then(extractData)
  },

  refunds (reservationId: string): Promise<RefundsResponse> {
    return financialTransactionsService
      .get({}, `${reservationId}/refunds`)
      .then(extractData)
  }
})

export const financialTransactionsDomain = financialTransactionsService.initialize
