/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { BREAK_POINTS } from '../Layout'
import { scrollBar } from '../../assets/styles/addons'

const container = css`
  display: flex;
  margin: 0;
  padding: 0 15px;
  overflow-x: auto;
  overflow-y: hidden;
  ${scrollBar(0)};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    padding: 0;
  }
`

const underlineNavContainer = css`
  margin-top: 25px;
`

export const NavContainer = ({ selectedNav = '', onChange: emitChangeEvent = identity, children, ...props }) => {
  const onClickNav = value => emitChangeEvent(value)

  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {
      selected: Boolean(selectedNav) && selectedNav === child.props.navId,
      onClick: onClickNav
    })
  })

  return (
    <div css={container} {...props}>
      {childrenWithProps}
    </div>
  )
}

export const UnderlineNavContainer = ({ selectedNav = '', onChange: emitChangeEvent = identity, children, ...props }) => {
  return (
    <NavContainer css={underlineNavContainer} selectedNav={selectedNav} onChange={emitChangeEvent} {...props}>
      {children}
    </NavContainer>
  )
}
