/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useRef, useCallback, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { calendar, iconMonthSelectorStyle } from './ExpandedCalendar.style'
import { CalendarDayMonthSelector } from './DayMonthSelector/DayMonthSelector'
import { AngleRightIcon, AngleLeftIcon } from '../Icons/Icons'
import { Fab } from '../Buttons/Buttons'
import { flexRowCentered } from '../../assets/styles/layout'
import { baseFont } from '../../assets/styles/styles'
import { splitDate } from '../../utils/date'
import { identity } from '@bonitour/common-functions'

import { getLanguageCode } from '../../utils/getLanguageCode'

export const ExpandedCalendar = ({
  firstDate,
  secondDate,
  setFirstDate = identity,
  setSecondDate = identity,
  lang = 'pt_BR',
  moveDays
}) => {
  const minDate = new Date(-10e13)
  const maxDate = new Date(+10e13)
  const prevDateState = useRef()
  const [currentYear, currentMonth] = splitDate(firstDate)
  const [selectedMonth, setMonth] = useState(currentMonth)
  const [selectedYear, setYear] = useState(currentYear)

  const safeLang = useMemo(() => getLanguageCode(lang), [lang])

  const handleOnChange = useCallback((dateParam) => {
    if (!firstDate) {
      setFirstDate(dateParam)
    } else if (!secondDate) {
      if (dateParam < firstDate) {
        setFirstDate(dateParam)
        prevDateState.current = firstDate
        setSecondDate(prevDateState.current)
      } else {
        setSecondDate(dateParam)
      }
    } else {
      setFirstDate(dateParam)
      setSecondDate(undefined)
    }
  }, [firstDate, secondDate, setFirstDate, setSecondDate])

  const IconMonthSelector = ({ onClick = () => {}, icon }) => {
    return (
      <Fab css={iconMonthSelectorStyle} onClick={onClick}>
        {icon}
      </Fab>
    )
  }

  const moveNextMonth = () => {
    if (selectedMonth < 12) {
      setMonth(selectedMonth + 1)
    } else {
      setMonth(1)
      setYear(selectedYear + 1)
    }
  }

  const movePreviousMonth = () => {
    if (selectedMonth > 1) {
      setMonth(selectedMonth - 1)
    } else {
      setMonth(12)
      setYear(selectedYear - 1)
    }
  }

  return (
    <div css={flexRowCentered}>
      <IconMonthSelector
        icon={<AngleLeftIcon />}
        onClick={movePreviousMonth}
      />
      <div css={[calendar, baseFont]}>
        <CalendarDayMonthSelector
          firstDate={firstDate}
          secondDate={secondDate}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          onDateChange={handleOnChange}
          minDate={minDate}
          maxDate={maxDate}
          data-testid='CalendarDayMonthSelector'
          moveDays={moveDays}
          lang={safeLang}
        />
      </div>
      <IconMonthSelector
        icon={<AngleRightIcon />}
        onClick={moveNextMonth}
      />
    </div>
  )
}
