// @ts-check
/** @jsxRuntime classic */
/** @jsx jsx */
import { useThrottledState } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { css, jsx } from '@emotion/core'
import React, { useEffect } from 'react'
import { AngleRightIcon, AngleLeftIcon } from '../Icons/Icons'
import { CarouselViewPort } from './CarouselViewPort'

const carouselStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const circleAngleStyle = css`
  opacity: 1;
  transition: opacity 0.5s ease-out;
`

const hidden = css`
  opacity: 0;
  pointer-events: none;
`
/** @typedef { JSX.IntrinsicAttributes['css'] } Styles */
/** @typedef { { default: Styles, disabled: Styles } } CircleStyles */
/** @type { CircleStyles } */
const defaultCircleStyles = {
  default: {
    borderRadius: '100%',
    border: '3px solid #ffffff',
    backgroundColor: '#ffffff',
    color: '#444444',
    width: '20px',
    height: '20px',
    boxShadow: '0 0 5px #00000033'
  },
  disabled: {
    border: '3px solid #d0d0d0',
    backgroundColor: '#d0d0d0',
    color: '#606060'
  }
}

const directions = { left: 1, rigth: -1 }

/** @param { 'left' | 'right' } direction */
const translateX = direction => css`
  transform: translateX(${50 * directions[direction]}%);
`

const Carousel = React.forwardRef(
  /**
   * @param {{ itemsPerPage: number, itemsWidth: number, children: JSX.Element[], gap: number, circleStyles?: CircleStyles, translate?: boolean, onPageChange: function(number) : void }} props
   * @param ref
   */
  function Carousel (
    {
      children,
      itemsPerPage,
      itemsWidth,
      gap,
      onPageChange = identity,
      circleStyles = defaultCircleStyles,
      translate = false
    },
    ref
  ) {
    const {
      update: setPage,
      value: page,
      waiting: disableControls
    } = useThrottledState(0, 1000)
    const { length: items } = children

    const lastPage = items > 0 ? Math.ceil(items / itemsPerPage) - 1 : 0
    const firstPage = 0

    React.useEffect(() => {
      onPageChange(page)
      // eslint-disable-next-line
    }, [page])

    React.useEffect(() => {
      if (page > lastPage) {
        setPage(() => lastPage, true)
      }

      if (page < firstPage) {
        setPage(() => firstPage, true)
      }
    }, [page, items, lastPage, setPage])

    useEffect(() => {
      if (typeof ref !== 'function' && ref) {
        ref.current = item => {
          const newPage = Math.max(
            0,
            Math.min(lastPage, Math.floor(item / itemsPerPage))
          )
          setPage(() => newPage, true)
        }
      }
      // eslint-disable-next-line
    }, [ref, itemsPerPage])
    return (
      <div className='carousel' css={carouselStyles}>
        <AngleLeftIcon
          css={[
            circleStyles.default,
            circleAngleStyle,
            translate && translateX('left'),
            disableControls && circleStyles.disabled,
            page > firstPage || hidden
          ]}
          onClick={() => disableControls || setPage(p => p - 1, false)}
        />
        <CarouselViewPort
          page={page}
          itemsPerPage={itemsPerPage}
          itemsWidth={itemsWidth}
          onWheel={n => setPage(p => p + n, false)}
          gap={gap}
          disableControls={disableControls}
        >
          {children}
        </CarouselViewPort>
        <AngleRightIcon
          css={[
            circleStyles.default,
            circleAngleStyle,
            translate && translateX('right'),
            disableControls && circleStyles.disabled,
            page < lastPage || hidden
          ]}
          onClick={() => disableControls || setPage(p => p + 1, false)}
        />
      </div>
    )
  }
)

export { Carousel }
