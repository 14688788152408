/**
 * Checks if it's a mobile and applies the formatting
 * @private
 * @param { String } phone phone to apply initial formatting
 * @returns { String } formatted phone
 */
const applyFormatting = (phone) => {
  if (!validatePhone(phone)) {
    return phone
  }
  const filtered = phone.replace(/[^\d]/g, '')
  const isMobile = filtered.length === 9 || filtered.length === 11

  return isMobile
    ? filtered.replace(/(\d\d)?(\d)(\d{4})(\d{4})/, '($1) $2 $3-$4')
    : filtered.replace(/^0/, '').replace(/(\d\d(?=\d{8,}))?(\d{1,5})(\d{4})/, '($1) $2-$3')
}

/**
 * Applies a regex to format brazilian phones
 * @private
 * @param { String } phone phone to format
 * @returns { String } formatted phone
 */
const brazilianFormat = phone =>
  applyFormatting(phone)
    .replace(/^\s*(?:\(\))?\s*/g, '')
    .replace(/\s{2,}/g, ' ')

/**
 * Given a phoneNumber, tries to find it's format and format it
 * @param { Number | String } [phoneNumber=''] given phone number
 * @returns { String } formatted phone number
 */
export const formatPhone = (phoneNumber = '') => {
  return brazilianFormat(String(phoneNumber))
}

/**
 * Masks given value to look like brazilian phone number, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format R$ (\d{1-3}(\.\d{3})*)+,\d{2}
 */
export const maskPhone = (value = '') =>
  formatPhone(
    value
      .replace(/\D/g, '')
      .replace(/^0*/g, '')
      .replace(/(\d{11})[\w\W]*/, '$1')
  )

/**
 * Unformats brazilian phone number into number
 * @param { String } [phone=''] Phone to format
 * @returns { String } String
 */
export const unmaskPhone = (phone = '') => phone.replace(/\D/g, '')

/**
 * validates if phone is a valid phone considering brazilian format
 * @param { String | any } phone phone to test
 * @returns { Boolean } wether it's valid or not
 */
export const validatePhone = (phone = '') => {
  const phoneLength = String(phone).replace(/[\s()-]/g, '').replace(/^0*/, '').length
  return phoneLength >= 8 && phoneLength <= 11
}

/**
 * validates if phone is a valid phone considering international format
 * @param { String | any } phone phone to test
 * @returns { Boolean } wether it's valid or not
 */
export const validatePhoneWithDDI = (phone = '') => {
  const withPrefix = phone[0] === '+' ? phone : `+${phone}`
  if (withPrefix.startsWith('+55')) {
    return validatePhone(withPrefix.replace(/^\+55/, ''))
  }

  const phoneLength = withPrefix.replace(/\D/g, '').length
  // smallest phone numbers are from small countries (4 digits + ddi with 2 or more digits)
  // E.164 permits a maximum length of 15 digits for the complete international phone number
  return phoneLength >= 6 && phoneLength <= 15
}
