import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData, extractInnerData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { ReservationAddTicketsErrorMapping, ReservationCreationErrorMapping } from './error.map'
import { parseError } from '@/utils/parse-error'
import { ReservationAdvanceTransactionRoutes } from './ReservationAdvanceTransactions'
import { ReservationConfirmEmail, ReservationBalance } from '@/types/domains/Reservation'

const reservationService = authenticatedService('/v1/reservations')

reservationService.setServiceMethods({
  get (searchParams: Record<string, any>, page: string, perPage: string): Promise<any> {
    const params = cleanPayload(searchParams)
    return reservationService.get({ page, per_page: perPage, ...params }).then(extractData)
  },

  getById (reservationId: string): Promise<any> {
    return reservationService.get({}, reservationId).then(extractInnerData)
  },

  getByReservationCode (reservationCode: string) {
    return reservationService.get({}, `${reservationCode}/code`).then(extractInnerData)
  },

  getByReservationId (reservationId: string) {
    return reservationService.post({}, `${reservationId}/payment_link_by_reservation`).then(extractData)
  },

  addTickets (reservationId: string, data: Record<string, any>): Promise<any> {
    return reservationService
      .patch(data, `${reservationId}/add_tickets`)
      .catch(parseError(ReservationAddTicketsErrorMapping))
  },

  create (data: Record<string, any>): Promise<any> {
    return reservationService
      .post(data)
      .then(extractInnerData)
      .catch(parseError(ReservationCreationErrorMapping))
  },

  cancelTicket (reservationId: string, ticketId: string) {
    return reservationService.put({}, `${reservationId}/tickets/${ticketId}/cancel`)
  },

  changeInfo (reservationId: string, payer: string) {
    return reservationService.patch({ payer }, `${reservationId}`)
  },

  changeStatus (reservationId: string, status: string) {
    return reservationService.patch({ status }, `${reservationId}/status`)
  },

  changeTicketSlot (reservationId: string, payload: Record<string, any>) {
    return reservationService.patch(payload, `${reservationId}/change_tickets_slot`)
  },

  dashboardIndications (page: string, perPage: string) {
    return reservationService.get({ page, per_page: perPage }, 'daily_monitoring')
  },

  getReservationVendors (reservationId: string) {
    return reservationService.get({}, `${reservationId}/vendors`).then(extractData)
  },

  getBalance (reservationId: string): Promise<ReservationBalance> {
    return reservationService.get({}, `${reservationId}/balance`).then(extractData)
  },

  sendReservationConfirmEmail (reservationConfirmEmailData: ReservationConfirmEmail) {
    return reservationService.post(reservationConfirmEmailData, '/send_email_confirm')
  },

  getPassengers (reservationId: string) {
    return reservationService.get({}, `${reservationId}/passengers`).then(extractData)
  },

  changePayer (reservationId: string, payerId: string) {
    return reservationService.patch({}, `${reservationId}/payer/${payerId}`).then(extractData)
  },

  updateEkoLocator (reservationId: string, data: { extra_infos: { eko_locator: string }}) {
    return reservationService.patch(data, `${reservationId}/update_eko_locator`)
  },

  addAffiliate (reservationId: string, affiliateCode: string) {
    const data = { affiliate_code: affiliateCode }
    return reservationService.patch(data, `${reservationId}/add_affiliate`).then(extractData)
  },

  addFiscalDuty (reservationId: string, fiscalDuty: string) {
    const data = { fiscal_duty: fiscalDuty }
    return reservationService.patch(data, `${reservationId}/add_fiscal_duty`).then(extractData)
  },

  ...ReservationAdvanceTransactionRoutes(reservationService)
})

export const reservationDomain = reservationService.initialize
