
import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const container = css`
  position: fixed;
  left: 5%;
  bottom: 5%;
  max-width: 410px;
  width: calc(100% - 10px);
  padding: 30px;
  background-color: ${colors.white};
  z-index: 25;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px -1px, rgb(0 0 0 / 7%) 0px 5px 8px 0px, rgb(0 0 0 / 6%) 0px 1px 14px 0px;
`

export const buttonsContainer = css`
  margin-top: 30px;
`

export const paleButton = css`
  width: auto;
  background: transparent;
  margin-right: 30px;
  min-height: 2rem;
  position: relative;
  font-weight: 700;
  cursor: pointer;
  border: none;
  color: ${colors.gray5};

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background: currentColor;
    position: absolute;
    left: 0;
    bottom: 0;

    transition: transform 200ms ease-in-out;
    transform: scaleX(0);
  }

  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
`

export const content = css`
  h2 {
    display: block;
    color: #6A6A6A;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1.25em;
    margin-bottom: 1rem;
  }

  p {
    display: block;
    color: #AAAAAA;
    font-size: 0.75rem;
    line-height: 1.5;
  }
`
