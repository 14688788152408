import { firstWeekdayInMonth, daysInMonth } from '../../../utils/date'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function buildWeekArray (currentYear, currentMonth) {
  const weeksList = []
  const firstWeekday = firstWeekdayInMonth(currentYear, currentMonth)
  const daysAvailable = daysInMonth(currentYear, currentMonth)

  let monthStarted = false
  let monthEnded = false
  let monthDay = 0

  for (let week = 1; week <= 6 && !monthEnded; week += 1) {
    const weekDaysList = []
    for (let day = 1; day <= 7; day += 1) {
      if (!monthStarted && day >= firstWeekday) {
        monthDay = 1
        monthStarted = true
      } else if (monthStarted && !monthEnded) {
        monthDay += 1
      }

      weekDaysList.push({
        label: monthDay ? monthDay.toString() : '',
        number: monthDay,
        date: dayjs(`${currentYear}/${currentMonth}/${monthDay}`)
          .utcOffset(0, false)
          .toDate()
      })

      if (monthStarted && !monthEnded && monthDay >= daysAvailable) {
        monthDay = 0
        monthEnded = true
      }
    }
    weeksList.push(weekDaysList)
  }
  return weeksList
}
