import { CheckoutResponse, CheckoutPayload, CheckoutId, CheckoutPayment, CardBrandsResponse } from '@/types/domains/Checkout'
import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'

const checkoutService = authenticatedService('/v1/checkout')
const checkoutServiceV2 = authenticatedService('/v2/checkout')

checkoutService.setServiceMethods({
  async get (checkoutId: CheckoutId): Promise<CheckoutResponse> {
    return checkoutService.get({}, `${checkoutId}`)
      .then(extractData)
      .then(({ reservation_checkout }: CheckoutPayload): CheckoutResponse => reservation_checkout)
  },
  async pay (checkoutId: CheckoutId, payload: CheckoutPayment): Promise<CheckoutResponse> {
    return checkoutService.post(payload, `${checkoutId}/pay`)
      .then(extractData)
  },
  async getCardBrands (gateway = 'Braspag'): Promise<CardBrandsResponse> {
    return checkoutService.get({ gateway }, 'card_brands')
      .then(extractData)
  }
})

checkoutServiceV2.setServiceMethods({
  async pay (checkoutId: CheckoutId, payload: CheckoutPayment): Promise<CheckoutResponse> {
    return checkoutServiceV2.post(payload, `${checkoutId}/pay`)
      .then(extractData)
  }
})

export const checkoutDomain = checkoutService.initialize
export const checkoutDomainV2 = checkoutServiceV2.initialize
