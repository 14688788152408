import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const base = css`
  color: white;
  text-align: center;
  padding: 10px 20px;
  background: ${colors.primary};
  &:disabled {
    opacity: 0.3;
  }
  outline: none;
`

export const ghost = css`
  color: ${colors.gray5};
  border: 1px solid ${colors.gray5};
`

export const ghostPrimary = css`
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
`

export const disabledGhost = css`
  &:disabled {
    opacity: 1;
  }
`

export const circle = (size = 30) => css`
  width: ${size}px;
  height: ${size}px;
  border-radius: var(--round-element-radius, 50%);
  padding: 0;
`

export const shadow = css`
  border-color: transparent;
  box-shadow: 0 0 5px 0.02em rgba(0, 0, 0, 0.25);
`

export const groupedFabContainer = css`
  position: relative;
  display: flex;
  flex-flow: column;

  > :not(.fab-button) {
    display: none;
  }

  .fab-button {
    font-weight: bold;
    font-size: 20px;

    &__hover-icon {
      display: none;
    }
  }
  &:hover {
    .fab-button {
      background-color: ${colors.gray6};

      &__base-icon {
        display: none;
      }
      &__hover-icon {
        display: initial;
      }
    }

    > :not(.fab-button) {
      display: initial;
    }
  }
`
