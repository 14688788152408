import { NotificationResponse } from '@/types/domains/Notification'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

const notificationService = authenticatedService('/v1/notifications')

const NotificationMethods = {
  list (perPage = 10, page = 1) {
    return notificationService.get({ per_page: perPage, page }).then(extractData)
  },
  markAsRead (notificationId: string): Promise<NotificationResponse> {
    return notificationService.patch({}, notificationId).then(extractData)
  }
}

notificationService.setServiceMethods(NotificationMethods)

export const notificationDomain = notificationService.initialize
