/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

const getRGB = (color) => {
  if (color[0] === '#') {
    let matched = color.match(/\w\w/g)
    if (matched.length < 3) {
      matched = color.match(/\w/g).map(x => x + x)
    }
    return matched.map(x => parseInt(x, 16))
  } else if (color.substring(0, 3).toLowerCase() === 'rgb') {
    return color.match(/[0-9]+/g).map(x => parseInt(x))
  }
  return [0, 0, 0]
}

const loading = (circleSize = 80, quantityCircles = 15, time = 1, baseColor = colors.indigo1) => {
  const littleCircle = circleSize * 0.15
  const baseAngle = 360 / quantityCircles
  const innerRadius = (circleSize - littleCircle) / 2
  let boxShadowColors = ''
  const rgb = getRGB(baseColor)
  rgb.length = 3

  for (let index = 1; index <= quantityCircles; index += 1) {
    const innerAngle = index * baseAngle
    const x = innerRadius * Math.sin(innerAngle)
    const y = innerRadius * Math.cos(innerAngle)
    if (index === 1) {
      boxShadowColors += `${x}px ${y}px rgba(${rgb.toString()}, 1)`
      continue
    }
    boxShadowColors += `, ${x}px ${y}px rgba(${rgb.toString()}, ${1 / index})`
  }

  return css`
  height: ${circleSize}px;
  width: ${circleSize}px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: ${littleCircle}px;
    width: ${littleCircle}px;
    margin: ${littleCircle * -0.5}px;
    border-radius: 50%;
    box-shadow: ${boxShadowColors};
    animation: rotate ${time}s steps(${quantityCircles}) infinite;
  }

  @keyframes rotate {
    from { transform: rotate(0);}
    to   { transform: rotate(360deg);}
  }
`
}

export const LoadingAnimation = ({
  size = 80,
  quantityCircles = 15,
  time = 1,
  baseColor = colors.indigo1,
  customCss = [],
  ...props
}) => (
  <div css={[loading(size, quantityCircles, time, baseColor), ...customCss]} {...props} />
)
