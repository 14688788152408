import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import {
  ExpenseListResponse,
  ExpenseListSearchParams,
  PayloadCreateExpense,
  ExpenseRes,
  AllowedStatus,
  PayloadUpdateExpense,
  SimulateRecurrenceParams,
  GetSimulateRecurrenceExpenseRes,
  ExpenseForgottenRes,
  DeleteReceiptsParams,
  ExpenseAuditsParams,
  ExpenseAuditsRes,
  UpdateBatchExpenseReqBodyRaw
} from '@/types/domains/Expense'
import { AxiosResponse } from 'axios'

const expenseService = authenticatedService('v1')

type ListParams = { searchParams: ExpenseListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<ExpenseListResponse> => {
  return expenseService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'expenses').then(extractData)
}

const create = (payload: PayloadCreateExpense): Promise<any> => {
  return expenseService.post(payload, 'expenses').then(extractData)
}

const get = (expenseId: string): Promise<ExpenseRes | any> => expenseService.get({}, `expenses/${expenseId}`).then(extractData)

const update = (expense: PayloadUpdateExpense, expenseId: string): Promise<ExpenseRes | any> => {
  return expenseService.patch(expense, `expenses/${expenseId}`)
}

const getAllowedStatus = async (expenseId: string): Promise<AllowedStatus[]> => {
  return await expenseService.get({}, `expenses/${expenseId}/allowed_status`).then(extractData)
}

const getExpenseInitialStatus = (): Promise<Partial<Record<AllowedStatus, string>>> => {
  return expenseService.get({}, 'expenses_initial_status').then(extractData)
}

const getDownloadReceipt = (expenseId: string): Promise<AxiosResponse<ArrayBuffer>> => {
  return expenseService.get({}, `expenses/${expenseId}/receipt_file`, { responseType: 'arraybuffer' })
}

const getSimulateRecurrenceExpense = async (
  params: SimulateRecurrenceParams
): Promise<GetSimulateRecurrenceExpenseRes> => {
  return await expenseService.get(params, 'expenses/simulate_recurrence').then(extractData)
}

const getExpensesForgotten = (): Promise<ExpenseForgottenRes> => expenseService.get(
  {}, 'expenses/forgotten'
).then(extractData)

const deleteReceipts = async (params: DeleteReceiptsParams, expenseId: string): Promise<void> => {
  return await expenseService.remove(params, `expenses/${expenseId}/delete_receipts`)
}

const getExpenseAudits = async (params: ExpenseAuditsParams, expenseId: string): Promise<ExpenseAuditsRes> => {
  return await expenseService.get(params, `expenses/${expenseId}/audit`).then(extractData)
}

const updateExpenses = async (updateBatchExpenseReqBodyRaw: UpdateBatchExpenseReqBodyRaw) => {
  return await expenseService.patch(updateBatchExpenseReqBodyRaw, 'expenses/update_expenses')
}

expenseService.setServiceMethods(
  {
    list,
    create,
    get,
    update,
    getAllowedStatus,
    getExpenseInitialStatus,
    getDownloadReceipt,
    getSimulateRecurrenceExpense,
    getExpensesForgotten,
    deleteReceipts,
    getExpenseAudits,
    updateExpenses
  }
)

export const expenseDomain = expenseService.initialize
