import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import {
  Batch,
  BatchResponse,
  BatchTotalsResponse,
  GetGuidePaymentBatchRes
} from '@/types/domains/Batch'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { convertToFormData } from '@/utils/form-data'

const batchService = authenticatedService('v1/guide_payment_batches')

const paymentBatchesStatusTotalsService = authenticatedService('v1/payment_batches_status_totals')

const listVouchers = (searchParams: Record<string, any>, pagination: MetaOnRequest): Promise<any> => {
  const params = { ...searchParams }
  return batchService.get({ ...cleanPayload(params), ...pagination }, 'vouchers').then(extractData)
}

const list = (searchParams: Record<string, any>, pagination: MetaOnRequest): Promise<any> => {
  const params = { ...searchParams }
  return batchService.get({ ...cleanPayload(params), ...pagination }).then(extractData)
}

const get = (id: string): Promise<GetGuidePaymentBatchRes | any> => batchService.get({}, id).then(extractData)

const create = (batch: Batch): Promise<BatchResponse | any> => batchService.post(batch).then(extractData)

const payment = (id: string, batch: Batch): Promise<Batch | any> => batchService.putFormData(convertToFormData(batch), `${id}/pay`)

const remove = (id: string): Promise<any> => batchService.remove({}, id)

const getBeneficiaryTotals = (beneficiaryId: string, beneficiaryType: string): Promise<BatchTotalsResponse> => paymentBatchesStatusTotalsService.get({
  beneficiary_id: beneficiaryId,
  beneficiary_type: beneficiaryType
}).then(extractData)

batchService.setServiceMethods({ listVouchers, list, get, create, payment, remove })

paymentBatchesStatusTotalsService.setServiceMethods({ getBeneficiaryTotals })

export const batchDomain = batchService.initialize

export const paymentBatchesStatusTotalsDomain = paymentBatchesStatusTotalsService.initialize
