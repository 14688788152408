/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Manager, Reference, Popper } from 'react-popper'
import { baseFont, softnedEdges } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'
import { flexRowCentered } from '../../assets/styles/layout'

const popOver = css`
  ${baseFont}
  ${flexRowCentered}
  background: ${colors.white};
  text-align: center;
  border-radius: 10px;
  margin: 20px;
  padding: 0.5em;
  box-shadow: 0 0 20px #00000033;
  min-height: 50px;
  min-width: 50px;
  z-index: 20;
`

const arrow = css`
  position: absolute;
  ${softnedEdges}

  &::before {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-width: 15px 15px 15px 0px;
    border-color: transparent ${colors.white} transparent transparent;
  }
`

const rightArrow = css`
  left: -15px;
`
const topArrow = css`
  transform: rotate(-90deg);
  bottom: -20px;
`
const bottomArrow = css`
  transform: rotate(90deg);
  top: -20px;
`
const leftArrow = css`
  transform: rotate(180deg);
  right: -15px;
`

const Popover = ({ position = 'bottom', preventOverflow = false, children, customCss = [], ...other }) => {
  return (
    <Popper
      placement={position}
      modifiers={
        preventOverflow
          ? {
            flip: {
              enabled: false
            },
            preventOverflow: {
              escapeWithReference: true
            }
          }
          : undefined
      }
    >
      {({ ref, style, placement, arrowProps }) => (
        <div
          css={[popOver, ...customCss]}
          className='component-popover'
          ref={ref}
          style={style}
          data-placement={placement}
          {...other}
        >
          {children}
          <div
            className='component-popover-arrow'
            css={[
              arrow,
              placement?.startsWith('left') && leftArrow,
              placement?.startsWith('bottom') && bottomArrow,
              placement?.startsWith('top') && topArrow,
              placement?.startsWith('right') && rightArrow
            ]}
            ref={arrowProps.ref}
            // style={arrowProps.style}
          />
        </div>
      )}
    </Popper>
  )
}

export { Manager, Reference, Popover }
