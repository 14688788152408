/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { globals } from '../../assets/styles/variables'
import { colors } from '../../assets/styles/colors'

const styles = css`
  margin: ${globals.margin};
  outline: none;
  color: ${colors.gray2};
  font-size: 14px;
  font-weight: bold;
`

const required = css`
  margin-left: 10px;
  color: ${colors.gray4};
  font-weight: 400;
  font-size: 14px;
`

/**
 * @typedef {Object} LabelProps
 * @property {React.ReactNode} [children] - The children of the label.
 * @property {SerializedStyles} [css] - The Emotion CSS style.
 */

/**
 * Label component
 * @param {LabelProps} props - The props for the component.
 */
export const Label = ({ children, ...other }) => (
  <label css={styles} {...other}>
    {children}
  </label>
)

export const LabelWithRequiredSuffix = ({
  label = '',
  requiredLabel = 'Obrigatório'
}) => {
  return (
    <>
      {label}
      <span css={required}>({requiredLabel})</span>
    </>
  )
}
