import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { UserInvite } from '@/types/domains/UserInvite'

const userInviteService = authenticatedService('v1')
const userInviteRequestURL = 'user'

const get = (token: string): Promise<UserInvite> =>
  userInviteService.get({}, `${userInviteRequestURL}/invites/${token}`).then(extractData)

userInviteService.setServiceMethods({ get })

export const userInviteDomain = userInviteService.initialize
