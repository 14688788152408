import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { Transport, TransportUpdate, TransportAbout } from '@/types/domains/Transport'

const transportService = authenticatedService('/v1/moov/transports')

const get = (serviceId: string) =>
  transportService.get({}, `${serviceId}`).then(extractData)

const create = (transport: Transport): Promise<any> =>
  transportService.postFormData(transport, '/').then(extractData)

const update = (transport: TransportAbout, transportId: string) =>
  transportService.patch(transport, `${transportId}`)

const updateAsFormData = (transport: TransportUpdate, transportId: string) =>
  transportService.patchFormData(transport, `${transportId}`).then(extractData)

transportService.setServiceMethods({ get, create, updateAsFormData, update })

export const transportDomain = transportService.initialize
