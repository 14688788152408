/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReactComponent as LogoBinamik } from '../../../assets/svgs/logoBinamikGrayscale.svg'
import { getLanguageCode } from '../../../utils/getLanguageCode'
import { footer, customFooter, logoStyles, contentStyle, policies, policiesLink } from './Footer.styles'

const footerI18nDictionary = {
  pt_BR: {
    developedWith: 'Desenvolvido com tecnologia',
    allRightsReserved: 'Todos os direitos reservados',
    termsOfUse: 'Termos de uso',
    termsOfUsePath: 'https://accounts.binamik.com.br/termo-de-uso',
    privacyPolicy: 'Privacidade',
    privacyPolicyPath: 'https://accounts.binamik.com.br/politica-de-privacidade'
  },
  en_US: {
    developedWith: 'Powered by',
    allRightsReserved: 'All rights reserved',
    termsOfUse: 'Terms of Use',
    termsOfUsePath: 'https://accounts.binamik.com.br/termo-de-uso',
    privacyPolicy: 'Privacy',
    privacyPolicyPath: 'https://accounts.binamik.com.br/politica-de-privacidade'
  },
  es_ES: {
    developedWith: 'Desarrollado con tecnología',
    allRightsReserved: 'Todos los derechos reservados',
    termsOfUse: 'Terminos de uso',
    termsOfUsePath: 'https://accounts.binamik.com.br/termo-de-uso',
    privacyPolicy: 'Privacidad',
    privacyPolicyPath: 'https://accounts.binamik.com.br/politica-de-privacidade'
  }
}

/** @type {(data: {lang: string}) => string} */
const DefaultFooter = ({ lang }) => (
  <>
    <div css={contentStyle}>
      {footerI18nDictionary[lang].developedWith}&nbsp;
      <LogoBinamik css={logoStyles} role='img' aria-label='binamik' />
      &nbsp;{new Date().getFullYear()}
      &nbsp;© {footerI18nDictionary[lang].allRightsReserved}.
    </div>
    <div css={policies}>
      <a href={footerI18nDictionary[lang].termsOfUsePath} css={policiesLink} target='_blank' rel='noopener noreferrer'>{footerI18nDictionary[lang].termsOfUse}</a>
      &nbsp; | &nbsp;
      <a href={footerI18nDictionary[lang].privacyPolicyPath} css={policiesLink} target='_blank' rel='noopener noreferrer'>{footerI18nDictionary[lang].privacyPolicy}</a>
    </div>
  </>
)

/**
 * @type { React.FC<{
 *   customCss?: import('@emotion/core').SerializedStyles[]
 *   lang?: string
 * }>}
 * */
export const Footer = ({ children, lang = 'pt', customCss = [] }) => (
  <footer css={[footer, children && customFooter, ...customCss]} data-testid='footer'>
    {children || <DefaultFooter lang={getLanguageCode(lang)} />}
  </footer>
)
