import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  GetBankConciliationsParams,
  GetBankConciliationsRes,
  PostAutoBankConciliationParams,
  PostAutoBankConciliationRes,
  PostManualBankConciliationParams,
  PostManualBankConciliationRes,
  DeleteBankConciliationsParams
} from '@/types/domains/BankConciliation'
import { extractData } from '@/utils/data-extractor'

const bankConciliationService = authenticatedService('v1/conciliations')

const getBankConciliations = async (searchParams: GetBankConciliationsParams): Promise<GetBankConciliationsRes> => {
  return bankConciliationService.get(searchParams, '').then(extractData)
}

const postAutoBankConciliation = async (params: PostAutoBankConciliationParams): Promise<PostAutoBankConciliationRes> => {
  return await bankConciliationService.post(
    params, 'auto_conciliation'
  ).then(extractData)
}

const postManualBankConciliation = async (params: PostManualBankConciliationParams): Promise<PostManualBankConciliationRes> => {
  return await bankConciliationService.post(
    params, 'manual_conciliation'
  ).then(extractData)
}

const deleteBankConciliations = async (params: DeleteBankConciliationsParams): Promise<void> => {
  return await bankConciliationService.remove(
    params, 'delete'
  ).then(extractData)
}

bankConciliationService.setServiceMethods(
  {
    getBankConciliations,
    postAutoBankConciliation,
    postManualBankConciliation,
    deleteBankConciliations
  }
)

export const bankConciliationDomain = bankConciliationService.initialize
