/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { baseFont, softnedEdges } from '../../assets/styles/styles'

export const ErrorTooltip = props => (
  <span
    {...props}
    css={css`
      ${baseFont}
      ${softnedEdges}
      position: relative;
      outline: none;
      background-color: ${colors.red2};
      color: ${colors.white};
      font-weight: bold;
      padding: 10px;
      padding-right: 20px;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 18px;
        border: 5px solid;
        border-color: ${colors.red2} transparent transparent transparent;
      }
    `}
  />
)
