/**
 * Map and converts a query string into a mapped version of itself
 * @param { String } [query=''] The string representing all the query params
 * @return { Record<string, string> } The mapped query
 */
export const getQueryParams = (query = '') =>
  query
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc, item) => {
      let [key, value] = item.split('=').map(s => decodeURIComponent(s.replace(/\+/g, '%20')))
      if (key.endsWith('[]')) {
        key = key.slice(0, -2)
        value = acc[key] ? acc[key].concat(value) : [value]
      }
      return { ...acc, ...(value ? { [key]: value } : {}) }
    }, {})
