import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { CreateCashierHandlingPayload, CashierHandlingListSearchParams, CashierHandlingListResponse } from '@/types/domains/CashierHandling'

const cashierHandlingService = authenticatedService('v1/cashier_handlings')

type ListParams = { searchParams: CashierHandlingListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<CashierHandlingListResponse> => {
  return cashierHandlingService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const create = (payload: CreateCashierHandlingPayload): Promise<any> =>
  cashierHandlingService.post(cleanPayload(payload)).then(extractData)

cashierHandlingService.setServiceMethods({ list, create })

export const cashierHandlingDomain = cashierHandlingService.initialize
