import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { extractData } from '@/utils/data-extractor'
import { TicketsToBatch, TicketsToBatchParamsListSearchParams } from '@/types/domains/OfflineExperience'
import { cleanPayload } from '@/utils/clean-payload'
import { OfflineTicket } from '@/types/domains/OfflineTicket'
import { PaymentBatch, PaymentBatchResponse } from '@/types/domains/PaymentBatches'
import { convertToFormData } from '@/utils/form-data'

const offlineTicketService = authenticatedService('v1')
const offlineTicketRequestURL = 'offline_tickets'

type CategoryOptions = 'accommodation' | 'equipment_rental' | 'gastronomy' | 'air_transport' | 'car_rental' | 'activities' | 'transport' | 'others'
type ticketsToBatchParams = { searchParams: TicketsToBatchParamsListSearchParams; pagination: MetaOnRequest }

const getTicketsToBatch = ({ searchParams, pagination }: ticketsToBatchParams): Promise<TicketsToBatch> => {
  return offlineTicketService.get({ ...cleanPayload(searchParams), ...pagination }, `${offlineTicketRequestURL}/to_batch`).then(extractData)
}

const createPaymentBatch = (paymentBatch: PaymentBatch): Promise<PaymentBatchResponse> => {
  return offlineTicketService.post(paymentBatch, `${offlineTicketRequestURL}_batch`)
}

const create = (offlineTicket: OfflineTicket, category: CategoryOptions): Promise<OfflineTicket> => offlineTicketService.postFormData(convertToFormData(cleanPayload(offlineTicket)), `${offlineTicketRequestURL}/${category}`).then(extractData)

const get = (id: string): Promise<OfflineTicket> => offlineTicketService.get({}, `${offlineTicketRequestURL}/${id}`).then(extractData)

const update = (id: string, params: OfflineTicket): Promise<OfflineTicket> => offlineTicketService.patch(params, `${offlineTicketRequestURL}/${id}`).then(extractData)

offlineTicketService.setServiceMethods({ getTicketsToBatch, create, createPaymentBatch, get, update })

export const offlineTicketDomain = offlineTicketService.initialize
