import { css } from '@emotion/core'
import { colors } from './colors'

const themedDefaultText = css`
  font-weight: 700;
`

export const primaryTheme = css`
  background-color: ${colors.primary};
  ${themedDefaultText}
`

export const successTheme = css`
  background-color: ${colors.sucessVariant};
  color: ${colors.green6};
  ${themedDefaultText}

  i.b-font-close {
    color: ${colors.green2};
  }

  .color_progressbar_variant {
    background: ${colors.green2};
  }
`

export const dangerTheme = css`
  background-color: ${colors.dangerVariant};
  color: ${colors.red1};
  ${themedDefaultText}

  i.b-font-close-danger {
    color: ${colors.red1};
  }

  .color_progressbar_variant {
    background: ${colors.red3};
  }
`

export const warningTheme = css`
  background-color: ${colors.warningVariant};
  color: ${colors.orange6};
  ${themedDefaultText}

  i.b-font-close {
    color: ${colors.yellow2};
  }

  .cancelButtonVariant {
    color: ${colors.orange6};
    text-decoration: underline;
  }

  .confirmButtonVariant {
    background-color: ${colors.yellow2};
    color: ${colors.white}
  }

  .color_progressbar_variant {
    background: ${colors.yellow1};
  }
  
`

export const warningLightTheme = css`
  background-color: ${colors.white};
  color: ${colors.gray5};
`

export const infoTheme = css`
  background-color: ${colors.infoVariant};
  color: ${colors.gray3};
  ${themedDefaultText}

  i.b-font-close {
    color: ${colors.gray4};
  }

  .color_progressbar_variant {
    background: ${colors.gray4};
  }
`

export const infoLightTheme = css`
  background-color: ${colors.gray12};
  color: ${colors.gray5};
`

export const darkTheme = css`
  background-color: ${colors.dark};
  ${themedDefaultText}
`
