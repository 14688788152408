import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'

export const weekDay = css`
  font-weight: 200;
  padding: 5px 10px;
  color: ${colors.gray4};
  font-size: 13px;
`

export const tdPadding = css`
  padding: 8px 0;
`

export const dayMonthHeader = css`
  display: flex;
  justify-content: center;
  color: ${colors.gray2};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
