/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { SlideInOut } from '../../../Transitions/SlideInOut'
import { fifthLayer, flexRow } from '../../../../assets/styles/layout'
import { Backdrop } from '../../../Backdrop/Backdrop'
import { colors } from '../../../../assets/styles/colors'

const sidebarContainer = css`
  ${flexRow}
  ${fifthLayer}
  background-color: ${colors.white};
  height: 100%;
`

export const SidebarContainer = props => (
  <div {...props} css={sidebarContainer} />
)

const containersPosition = css`
  ${fifthLayer}
  left: 0;
  top: 0;
  position: fixed;
`

export const Sidebar = ({
  isOpen = false,
  hasBackdrop = true,
  onSidebarClose: emitSidebarCloseEvent = identity,
  children
}) => {
  return (
    <>
      {
        (isOpen && hasBackdrop) && (
          <Backdrop
            onClick={emitSidebarCloseEvent}
            css={containersPosition}
          />
        )
      }
      <SlideInOut in={isOpen}>
        <SidebarContainer css={containersPosition}>
          {children}
        </SidebarContainer>
      </SlideInOut>
    </>
  )
}
