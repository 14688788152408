
import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { depth } from '../../utils/depthBuilder'
import { BREAK_POINTS } from '../Layout'

export const actionBarWrapper = css`
  position: fixed;
  inset: auto 0 0;
  background-color: ${colors.white};
  padding: 0.5rem 1rem;
  z-index: 10;
  ${depth(15, 'dark')};

  .bar__content {
    max-width: 1248px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_left {
      max-width: 50%;
      margin: 0 auto 0 0;
    }

    &_button {
      margin: 0 0 0 auto;
    }

    @media (max-width: ${BREAK_POINTS.tabletUp}) {
      flex-direction: column;
      gap: 1rem;
      margin: 0.5rem 0;

      &_left {
        max-width: 100%;
      }
    }
  }
`
