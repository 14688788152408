/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef, useCallback, forwardRef, useEffect, useState, useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'
import { Calendar } from './Calendar/Calendar'
import { Popover, Manager, Reference } from '../Popover/Popover'
import { datePickerContainer, datePickerWrapper, error, disabledStyle, angleSelector, rotateSelector, angleSelectorIcon, hoverShadow, dateInput, fullWidthStyle, datePickerPopover, coverMobile, preventHeaderIndexStyle } from './Datepicker.style'
import { DatepickerInput } from './DatepickerInput'
import { AngleDownIcon } from '../Icons/Icons'
import { getLanguageCode } from '../../utils/getLanguageCode'
import { Backdrop } from '../Backdrop/Backdrop'

/**
 * @typedef {{
 *   children?: React.ReactNode,
 *   value: string | Date,
 *   readOnly?: boolean,
 *   disabled?: boolean,
 *   onChange?: function,
 *   onBlur?: function,
 *   error?: boolean | string,
 *   minDate?: Date | string,
 *   maxDate?: Date | string,
 *   'mark-today'?: boolean,
 *   'click-only'?: boolean,
 *   markToday?: any,
 *   clickOnly?: any,
 *   position?: string,
 *   placeholder?: string,
 *   customCss?: import('@emotion/core').SerializedStyles[],
 *   ariaLabel?: string,
 *   fullWidth?: boolean,
 *   allowsEmpty?: boolean,
 *   id?: string,
 *   lang?: string,
 *   isSelectorOpen?: boolean,
 *   preventOverflow?: boolean,
 *   preventHeaderIndex?: boolean
 * }} DatePickerProps
 *
 * @type {React.ForwardRefExoticComponent<DatePickerProps & React.RefAttributes<HTMLDivElement>>}
 */
export const DatePicker = forwardRef(
  (
    {
      children,
      value,
      readOnly = false,
      disabled = false,
      onChange: emitChangeEvent = identity,
      onBlur: emitBlurEvent = identity,
      error: hasError = false,
      minDate = new Date(-10e13),
      maxDate = new Date(+10e13),
      'mark-today': mark_today = true,
      'click-only': click_only = false,
      markToday = mark_today,
      clickOnly = click_only,
      position = 'bottom',
      placeholder,
      customCss = [],
      ariaLabel,
      fullWidth = false,
      allowsEmpty = false,
      lang = 'pt_BR',
      isSelectorOpen = false,
      preventOverflow = false,
      preventHeaderIndex = false,
      ...other
    },
    ref
  ) => {
    const [emitBlurOnChange, setEmitBlur] = useState(false)
    const datePickerReference = useRef()
    const inputReference = useRef()
    const [isOpened, setOpenState] = useClickOutside(datePickerReference)

    const safeLang = useMemo(() => getLanguageCode(lang), [lang])

    const toggleShow = event => {
      event.stopPropagation()
      inputReference.current.blur()
      setOpenState(previous => !previous)
    }

    const onKeyPress = () => setOpenState(false)

    const onContainerClick = useCallback(event => {
      event.stopPropagation()
      if (!isOpened) {
        inputReference.current.focus()
      }
    }, [isOpened])

    const changeDate = data => {
      emitChangeEvent(data)
      setEmitBlur(true)
      setOpenState(false)
    }

    const onBlur = event => emitBlurEvent(value, event)

    useEffect(() => {
      if (emitBlurOnChange) {
        setEmitBlur(false)
        emitBlurEvent()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, emitBlurOnChange])

    useEffect(() => {
      if (isSelectorOpen) {
        setOpenState(true)
      }
    }, [isSelectorOpen, setOpenState])

    useEffect(() => {
      if (ref && typeof ref !== 'function') {
        ref.current = { setOpenState, isOpen: isOpened }
      }
    }, [ref, setOpenState, isOpened])

    const popoverStyle = useMemo(() => [datePickerPopover, preventHeaderIndex ? preventHeaderIndexStyle : ''], [preventHeaderIndex])

    return (
      <div>
        {isOpened && <Backdrop css={coverMobile} onClick={onKeyPress} />}
        <Manager>
          <div
            ref={datePickerReference}
            {...other}
            css={[
              datePickerContainer,
              (readOnly || disabled) && disabledStyle,
              clickOnly && hoverShadow,
              hasError && error,
              fullWidth && fullWidthStyle,
              ...customCss
            ]}
            tabIndex='0'
            onBlur={onBlur}
            onClick={onContainerClick}
          >
            <Reference>
              {({ ref }) => (
                <div
                  css={[datePickerWrapper, fullWidth && fullWidthStyle]}
                  ref={ref}
                >
                  <DatepickerInput
                    ref={inputReference}
                    date={value}
                    placeholder={placeholder}
                    onKeyPress={onKeyPress}
                    onChange={changeDate}
                    onBlur={onBlur}
                    data-testid='datepicker-input'
                    disabled={readOnly || disabled}
                    readOnly={clickOnly}
                    onClick={clickOnly ? toggleShow : identity}
                    css={[dateInput]}
                    ariaLabel={ariaLabel}
                    allowsEmpty={allowsEmpty}
                    lang={safeLang}
                  />
                  <span
                    css={[angleSelector, clickOnly || hoverShadow]}
                    onClick={toggleShow}
                    data-testid='datepicker-button'
                  >
                    <AngleDownIcon
                      css={[angleSelectorIcon, isOpened && rotateSelector]}
                    />
                  </span>
                </div>
              )}
            </Reference>
            {isOpened && (
              <Popover position={position} preventOverflow={preventOverflow} css={popoverStyle}>
                <Calendar
                  onChange={changeDate}
                  date={value || undefined}
                  minDate={minDate}
                  maxDate={maxDate}
                  markToday={markToday}
                  lang={safeLang}
                >
                  {children}
                </Calendar>
              </Popover>
            )}
          </div>
        </Manager>
      </div>
    )
  }
)
