import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { LegalAgreement, UserData } from '@/types/domains/Terms'

const termsService = authenticatedService('/v1/legal_agreements')

termsService.setServiceMethods({
  getByType (type: string): Promise<LegalAgreement> {
    return termsService.get({}, `${type}/latest`)
      .then(extractData)
  },
  acceptTerm (id: string): Promise<UserData> {
    return termsService.post({}, `${id}/accept`).then(extractData)
  }
})

export const termDomain = termsService.initialize
