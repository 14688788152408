/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { forwardRef } from 'react'
import { colors } from '../../assets/styles/colors'

const cardBase = (padding) => css`
  background: ${colors.white};
  border: 1px solid ${colors.gray11};
  padding: ${padding}px;
  border-radius: 10px;
`

const shadow = css`
  box-shadow: 0 0 5px #00000033;
`

/** @type { React.ForwardRefExoticComponent<CardProps & React.HTMLAttributes<HTMLDivElement>> } */
export const Card = forwardRef(
  ({ children, padding = 30, withShadow = false, customCss = [], ...other }, ref) => (
    <div
      ref={ref}
      css={[cardBase(padding), withShadow && shadow, ...customCss]}
      {...other}
    >
      {children}
    </div>
  )
)

/**
 * @typedef CardProps
 * @prop { number } [padding=30]
 * @prop { boolean } [withShadow=false]
 * @prop { import("@emotion/core").SerializedStyles[] } [customCss=[]]
 */
