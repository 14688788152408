import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const dayWidth = '42px'

export const calendar = css`
  width: 540px;
  margin: 0 20px 0 40px;
`

export const body = css`
  table-layout: fixed;
  width: 100%;
`

export const header = css`
  justify-content: stretch;
  padding: 10px 15px 15px;
  color: ${colors.gray1};
`

export const titleContainer = css`
  font-size: 25px;
  flex-grow: 1;
  text-align: center;

  span {
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;

    i {
      margin-left: 5px;
      font-size: 15px;
    }

    &:hover {
      color: ${colors.white};
      background-color: ${colors.primaryVariant};
    }
  }

  strong {
    font-weight: 500;
  }
`

export const selectedFirstStyle = css`
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.primary};
  box-shadow: 0 0 10px #00000033;

  position: relative;

  &:after {
    content: "";

    width: 0;
    height: 0;

    position: absolute;

    border-left: 11px solid transparent;
    border-bottom: 24px solid transparent;
    border-top: 12px solid ${colors.primary};

    bottom: -6px;
    left: 63%;
    transform: rotate(45deg);
    border-radius: 4px;
  }
`

export const selectedSecondStyle = css`
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.primary};
  box-shadow: 0 0 10px #00000033;

  position: relative;

  &:after {
    content: "";

    width: 0;
    height: 0;

    position: absolute;

    border-left: 11px solid transparent;
    border-top: 24px solid transparent;
    border-bottom: 12px solid ${colors.primary};

    bottom: -6px;
    right: 63%;
    transform: rotate(137deg);
    border-radius: 4px;
  }
`

export const selectedEqualStyle = css`
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.primary};
  box-shadow: 0 0 10px #00000033;
`

export const hoverSelectedStyle = css`
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.primary};
  box-shadow: 0 0 10px #00000033;
`

export const disabledStyle = css`
  color: ${colors.gray8};

  &:hover {
    color: ${colors.gray8};
    background-color: transparent;
    box-shadow: unset;
    cursor: unset;
  }
`

export const boxInsideTableData = css`
  padding: 5px;
`

export const labelInsideTableDate = (color) => css`
  text-align: center;
  color: ${color};
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
  border-radius: 50%;
  width: ${dayWidth};
  height: ${dayWidth};
  user-select: none;

  &:hover {
    ${hoverSelectedStyle}
  }
`

export const selectedDayStyle = css`
  background-color: ${colors.purple1};
  padding-right: 0;
`

export const widthFitContent = css`
  width: fit-content;
`

export const borderRadiusLeft = css`
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
`

export const borderRadiusRight = css`
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
`

export const width80 = css`
  width: 80px;
`

export const dot = css`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`

export const redDot = css`
  background-color: ${colors.red2};
`

export const iconMonthSelectorStyle = css`
  color: ${colors.gray4};
  font-size: 22px;
  background-color: ${colors.white};
`

export const containerSpan = css`
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
  `
