import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  BankStatementsFileImportParams,
  BankStatementsListParams,
  BankStatementsListRes,
  BankStatementsDeleteParams,
  BankStatementsDeleteRes,
  BankStatementsImportedParams,
  BankStatementsImportedRes,
  BankStatementsImportParams,
  BankStatementsImportRes
} from '@/types/domains/BankStatements'

const bankStatementsService = authenticatedService('v1/bank_statements')

const bankStatementsFileImport = async (
  searchParams: BankStatementsFileImportParams
): Promise<BankStatementsImportRes> => {
  return await bankStatementsService.postFormData(
    searchParams,
    'import'
  ).then(extractData)
}

const list = async (
  { searchParams, pagination }: BankStatementsListParams
): Promise<BankStatementsListRes> => {
  return await bankStatementsService.get({
    ...searchParams,
    ...pagination
  }).then(extractData)
}

const remove = async (
  params: BankStatementsDeleteParams
): Promise<BankStatementsDeleteRes> => {
  return await bankStatementsService.remove(
    params
  ).then(extractData)
}

const getImported = (params: BankStatementsImportedParams): Promise<BankStatementsImportedRes> => {
  return bankStatementsService.get(params, 'imported').then(extractData)
}

const bankStatementsImport = async (params: BankStatementsImportParams): Promise<BankStatementsImportRes> => {
  return await bankStatementsService.post(
    params,
    'import_api'
  ).then(extractData)
}

bankStatementsService.setServiceMethods(
  {
    bankStatementsFileImport,
    list,
    remove,
    getImported,
    bankStatementsImport
  }
)

export const bankStatementsDomain = bankStatementsService.initialize
