import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { ReservationRevenueSearchParams, ReservationRevenuePayload, ReservationRevenueTotalsPayload } from '@/types/domains/ReservationRevenue'

const reservationRevenuesService = authenticatedService('v1/reservation_revenues')

type ListParams = { searchParams: ReservationRevenueSearchParams; pagination: MetaOnRequest }

const listRevenue = ({ searchParams, pagination }: ListParams): Promise<ReservationRevenuePayload> => {
  return reservationRevenuesService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const listRevenueTotals = ({ searchParams }: { searchParams: ReservationRevenueSearchParams }): Promise<ReservationRevenueTotalsPayload> => {
  return reservationRevenuesService.get({
    ...cleanPayload(searchParams)
  }, 'totalizers').then(extractData)
}

reservationRevenuesService.setServiceMethods({ listRevenue, listRevenueTotals })

export const reservationRevenueDomain = reservationRevenuesService.initialize
