/** @jsxRuntime classic */
/** @jsx jsx */
import { forwardRef, useCallback, useState, useEffect, useMemo } from 'react'
import { MaskedInput } from '../Input/Input'
import { jsx, css } from '@emotion/core'
import { identity, maskDate } from '@bonitour/common-functions'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const datePickerInput = css`
  box-sizing: border-box;
  border: 0;
  padding: 0;
  width: 100%;
  border-radius: 0;
  background-color: transparent;
`

const asButtonStyle = css`
  &:hover {
    box-shadow: 0 0 6px #000000cc;
  }
`

export const DatepickerInput = forwardRef(
  (
    {
      date,
      onChange: emitChangeEvent = identity,
      onBlur: emitBlurEvent = identity,
      onClick: emitClick = identity,
      asButton = false,
      allowsEmpty,
      lang = 'pt_BR',
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState('')

    const dateFormat = useMemo(() => {
      return lang === 'en_US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
    }, [lang])

    const setInputToExternalDate = useCallback(() => {
      if (date) {
        setInputValue(() => dayjs(date).format(dateFormat))
      } else if (allowsEmpty && !date) {
        setInputValue('')
      }
    }, [allowsEmpty, date, dateFormat])

    const onInputBlur = useCallback(() => {
      const date = dayjs(inputValue, dateFormat)
      if (date.isValid()) {
        emitChangeEvent(date.toDate())
      } else if (allowsEmpty) {
        setInputValue('')
        emitChangeEvent()
      } else {
        setInputToExternalDate()
      }
      emitBlurEvent()
    }, [
      allowsEmpty,
      dateFormat,
      emitBlurEvent,
      emitChangeEvent,
      inputValue,
      setInputToExternalDate
    ])

    useEffect(() => {
      setInputToExternalDate()
    }, [setInputToExternalDate])

    return (
      <MaskedInput
        {...props}
        formatterFunction={date => date && maskDate(date)}
        ref={ref}
        css={[datePickerInput, asButton && asButtonStyle]}
        value={inputValue}
        onChange={setInputValue}
        onBlur={onInputBlur}
        onClick={emitClick}
        autoComplete='bday'
      />
    )
  }
)
