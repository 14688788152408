import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { CashierListSearchParams, CashierListResponse, CloseCartPayload, CashierDetails, Employees } from '@/types/domains/Cashier'

const cashierService = authenticatedService('v1/cashiers')

type ListParams = { searchParams: CashierListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<CashierListResponse> => {
  return cashierService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const get = (cashierId: 'my_cashier' | string): Promise<CashierDetails> => cashierService.get({}, cashierId).then(extractData)

const closeCashier = ({ cashier_id, ...payload }: CloseCartPayload): Promise<any> => {
  return cashierService.patch(cleanPayload(payload), `${cashier_id}/close`).then(extractData)
}

const reopenCashier = ({ cashier_id, ...payload }: CloseCartPayload): Promise<any> => {
  return cashierService.patch(cleanPayload(payload), `${cashier_id}/reopen`).then(extractData)
}

const listEmployees = (): Promise<Employees> => {
  return cashierService.get({}, 'operators').then(extractData)
}

cashierService.setServiceMethods({ list, closeCashier, reopenCashier, get, listEmployees })

export const cashierDomain = cashierService.initialize
