import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { iconHex } from '../../assets/styles/icons'
import { roundedEdges } from '../../assets/styles/styles'

export const disabledStyle = css`
  pointer-events: none;
  background-color: ${colors.gray14};
  color: ${colors.gray4};

  &::after {
    content: "";
  }
`

export const colorPicker = css`
  min-height: 40px;
  font-size: 14px;
  font-weight: 500;
  background: ${colors.white};
  color: ${colors.gray3};
  border: 1px solid ${colors.gray8};
  padding: 11px 20px;
  padding-left: 42px;
  margin: 0;
  box-sizing: border-box;
  ${roundedEdges}
  width: 100%;
  user-select: none;
  position: relative;

  &::after {
    position: absolute;
    display: inline-block;
    padding: 3px;
    right: 10px;
    top: 25%;
    font-family: "bonifont";
    content: "\\${iconHex('angle-down')}";
    color: ${colors.gray3};
  }

  &:disabled,
  &:read-only {
  }
`

export const angleDown = css`
  &::after {
    content: "\\${iconHex('angle-up')}";
  }
`
export const colorPickerContainer = css`
  display: inline-flex;
  min-width: 150px;
`

export const error = css`
  border-color: ${colors.danger};
`

export const plugin = css`
  box-shadow: none !important;
`

export const colorPreview = css`
  width: 24px;
  height: 24px;
  ${roundedEdges}
  position: absolute;
  left: 10px;
  top: 8px;
`
