import { authenticatedService } from '@/interfaces/authenticatedService'
import { ReservationMapResponse } from '@/types/domains/Reservation'
import { extractData } from '@/utils/data-extractor'

const reservationMapService = authenticatedService('/v1/reservation_map')

function list (
  serviceId: string,
  companyId: string,
  startDate: string,
  endDate: string
): Promise<any> {
  return reservationMapService.get({
    company_id: companyId,
    service_id: serviceId,
    start_date: startDate,
    end_date: endDate
  }).then(extractData)
}

function details (
  serviceId: string,
  companyId: string,
  startDate: string,
  endDate: string,
  hour: string
): Promise<any> {
  return reservationMapService.get({
    company_id: companyId,
    service_id: serviceId,
    initial_date: startDate,
    final_date: endDate,
    hour
  }, 'hour').then(extractData)
}

function getHourly (
  serviceId: string,
  companyId: string,
  startDate: string,
  endDate: string
): Promise<ReservationMapResponse> {
  return reservationMapService.get({
    service_id: serviceId,
    company_id: companyId,
    start_date: startDate,
    end_date: endDate
  }).then(extractData)
}

reservationMapService.setServiceMethods({ list, details, getHourly })

export const reservationMapDomain = reservationMapService.initialize
