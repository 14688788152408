import { ErrorMap, ErrorReport, ParserErrors, EntityPronoun } from '@/types/domains/Error'
import { ATOMIZER_ERROR, ATOMIZER_ERROR_MESSAGE } from '@/constants/utils/errors'
import { RequestError } from '@/interfaces/authenticatedService'

const buildErrorMessages = (errorMapping: ErrorMap = {}) => (errors: ErrorReport = {}): string[] => {
  const { errors_msg: messages = [] } = errors
  return messages.map(message => {
    const method = errorMapping[message]
    if (!method) {
      return ''
    }
    return method(errors)
  }).filter(Boolean)
}

export const parseError = (errorMapping: ErrorMap = {}) => ({ data: errors = {} }: {data: ErrorReport }): Promise<ParserErrors> => {
  return Promise.reject(
    {
      parsedErrors: buildErrorMessages(errorMapping)(errors)
    }
  )
}

export const isAtomizerBuildingError = (error: ErrorReport): [boolean, string] => [
  (error?.errors_msg || []).includes(ATOMIZER_ERROR),
  ATOMIZER_ERROR_MESSAGE
]

export const parseCrudError = (
  error: RequestError<ErrorReport>,
  entityNameLabel = '',
  entityPronoun: EntityPronoun
) => {
  const errorLabel = (function () {
    const { status, data, config } = error

    const isPermissionError = status === 403

    const isCreateError = config.method === 'POST'

    const isUpdateError = config.method === 'PATCH'

    const isDeleteError = config.method === 'DELETE'

    const isReadError = config.method === 'GET'

    const [isAtomizerError, atomizerErrorMessage] = isAtomizerBuildingError(data)

    if (isAtomizerError) return atomizerErrorMessage

    if (isPermissionError && isUpdateError) return `Você não possui permissão de edição de ${entityNameLabel}`

    if (isPermissionError && isCreateError) return `Você não possui permissão de criação de ${entityNameLabel}`

    if (isPermissionError && isDeleteError) return `Você não possui permissão de exclusão de ${entityNameLabel}`

    if (isPermissionError && isReadError) return `Você não possui permissão de visualização a ${entityNameLabel}`

    if (isPermissionError) return 'Você não possui permissão de acesso'

    if (isUpdateError) return `Erro ao salvar ${entityPronoun} ${entityNameLabel}`

    if (isCreateError) return `Erro ao salvar ${entityPronoun} ${entityNameLabel}`

    if (isDeleteError) return `Erro ao excluir ${entityPronoun} ${entityNameLabel}`

    if (isReadError) return `Erro ao visualizar ${entityPronoun} ${entityNameLabel}`

    return 'Tente novamente mais tarde.'
  }())

  return Promise.reject(errorLabel)
}
