/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

const padding = css`
  padding: 0 30px 30px 30px;
`

const collapse = css`
  display: none;
`

export const CollapsableSectionBody = ({ children, isExpanded = false, ...other }) => (
  <div css={[padding, !isExpanded && collapse]} {...other}>
    {children}
  </div>
)
