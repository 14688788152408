import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  ListSuppliersParams,
  CreateSuppliersParams,
  UpdateSuppliersParams,
  SuppliersRes,
  SupplierItem,
  SupplierAuditParams,
  SupplierAuditRes
} from '@/types/domains/Supplier'
import { extractData } from '@/utils/data-extractor'

const supplierService = authenticatedService('v1/suppliers')

const list = async ({ searchParams, pagination }: ListSuppliersParams): Promise<SuppliersRes> => {
  return await supplierService.get({ ...searchParams, ...pagination }).then(extractData)
}

const create = async (params: CreateSuppliersParams): Promise<SupplierItem> => {
  return await supplierService.post(params).then(extractData)
}

const get = (supplierId: string): Promise<SupplierItem> => supplierService.get({}, supplierId).then(extractData)

const update = (params: UpdateSuppliersParams, supplierId: string): Promise<SupplierItem | any> => {
  return supplierService.patch(params, supplierId)
}

const remove = (supplierId: string): Promise<void> => supplierService.remove({}, supplierId)

const getSupplierAudit = async (params: SupplierAuditParams, supplierId: string): Promise<SupplierAuditRes> => {
  return await supplierService.get(params, `/${supplierId}/audit`).then(extractData)
}

const options = (): Promise<SuppliersRes> => supplierService.get({}).then(extractData)

supplierService.setServiceMethods({ list, create, update, get, remove, getSupplierAudit, options })

export const supplierDomain = supplierService.initialize
