import React, { useMemo } from 'react'

import { FloatingActionBar } from './FloatingActionBar'
import { bindingBarContentWrapper } from './FloatingBindingBar.styles'

import { formatMoney } from '@bonitour/common-functions'

export const FloatingSelectionBar = ({
  selected = [],
  children,
  value = null
}) => {
  const count = useMemo(() => selected?.length, [selected.length])
  return (
    <FloatingActionBar actionChildren={children}>
      <div css={bindingBarContentWrapper}>
        <div className='selection__count'>{count}</div>
        {count
          ? value
            ? (
              <p>Valor selecionado: {formatMoney(value)}</p>
            )
            : (
              <p>Ite{count > 1 ? 'ns' : 'm'} selecionado{count > 1 ? 's' : ''}</p>
            )
          : (
            <p>Nenhum item selecionado</p>
          )}
      </div>
    </FloatingActionBar>
  )
}
