import { CompanyRole, PaginatedRoles, MacroPermissions, RoleData, UpdateCompanyRole, PermissionsMap } from '@/types/domains/Role'
import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'

const rolesService = authenticatedService('/v1')
const rolesServiceV2 = authenticatedService('/v2/company_roles')

rolesService.setServiceMethods({
  getCompanyRoles (perPage: string, page: string): Promise<PaginatedRoles> {
    return rolesService.get({ per_page: perPage, page }, 'company_roles').then(extractData)
  },
  getCompanyRole (roleId: string): Promise<CompanyRole> {
    return rolesService.get({}, `company_roles/${roleId}`).then(extractData)
  },
  getMacroPermissions (): Promise<MacroPermissions> {
    return rolesService.get({}, 'macro_permissions')
      .then(extractData)
  },
  getPermissionsMap (): Promise<PermissionsMap> {
    return rolesService.get({}, 'permissions_map')
      .then(extractData)
  },
  createCompanyRole (roleData: RoleData): Promise<CompanyRole> {
    return rolesService.post({ ...roleData }, 'company_roles')
  },
  updateCompanyRole (roleData: UpdateCompanyRole, id: string) {
    return rolesService.patch({ ...roleData }, `company_roles/${id}`)
  },
  remove (roleId: string): Promise<any> {
    return rolesService.remove({}, `company_roles/${roleId}`)
  },
  removeEmployees (roleId: string, employeeIds: any): Promise<any> {
    return rolesService.patch({ ...employeeIds }, `company_roles/${roleId}/remove_employees`)
  }
})

rolesServiceV2.setServiceMethods({
  getAllCompanyRoles (): Promise<CompanyRole> {
    return rolesServiceV2.get().then(extractData)
  }
})

export const rolesDomain = rolesService.initialize
export const rolesDomainV2 = rolesServiceV2.initialize
