/**
 * Formats a credit card expiration date with 2 or 4 year digits
 * @param { String } [expiration=''] expiration string
 * @returns { String } formatted expiration
 */
export const formatExpirationDate = (expiration = '') =>
  expiration.replace(/^([\d_]{2})([\d_]{2,4})$/g, '$1/$2')

/**
 * Masks given value to look like brazilian CNPJ, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format (\d{2}).(\d{3}).?(\d{3})/?(\d{4})-?(\d{2})
 */
export const maskExpirationDate = (value = '') =>
  formatExpirationDate(
    value
      .replace(/\D/g, '')
      .replace(/(\d{6})[\w\W]/, '$1')
      .padStart(6, '_')
  )

/**
 * validates an expiration date
 * @param { String } expirationDate date to valiDate
 * @returns { Boolean } wether it's ok or no
 */
export const validateExpirationDate = (expirationDate) => /^(0[1-9]|1[0-2])\/?(\d{2}|\d{4})$/g.test(expirationDate)
