/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { ResponsiveImage } from '../../Image/Image'
import { openIcon, flexCenteredContainer, topFixedHeight, uploadPreviewCardLabel, uploadPreviewCardDeleteButton, uploadPreviewCard, iconStyle, overflowHidden, link } from './UploadPreviewCard.style'
import { PdfIcon, EyeIcon, TrashIcon, DocumentIcon } from '../../Icons/Icons'
import { getExtension } from '../../../utils/getExtension'

export const UploadPreviewCard = ({ index, file, isImageHidden = false, disabled = false, onDelete = () => {} }) => {
  const imageSrc = file.src
  const fileName = file.name

  const stopPropagation = event => {
    event.stopPropagation()
  }

  const deleteItem = event => {
    stopPropagation(event)
    event.preventDefault()
    onDelete(index)
  }

  const extension = getExtension(imageSrc || fileName)
  return (
    <div css={isImageHidden ? overflowHidden : uploadPreviewCard} onClick={stopPropagation}>
      {!disabled && (
        <button css={uploadPreviewCardDeleteButton} type='button' onClick={deleteItem} >
          <TrashIcon />
        </button>
      )}
      {!isImageHidden && (
        <React.Fragment>
          <div css={flexCenteredContainer}>
            {(imageSrc && extension !== 'pdf')
              ? <ResponsiveImage css={topFixedHeight} src={imageSrc} />
              : extension !== 'pdf'
                ? <DocumentIcon css={iconStyle} />
                : <PdfIcon css={iconStyle} />
            }
            {imageSrc && (
              <a css={openIcon} onClick={stopPropagation} href={imageSrc} target='_blank' rel='noopener noreferrer'>
                <EyeIcon />
              </a>
            )}
          </div>
          {Boolean(fileName) && <div css={uploadPreviewCardLabel}>{fileName}</div>}
        </React.Fragment>
      )}

      {isImageHidden &&
        <a css={link} onClick={stopPropagation} href={imageSrc} target='_blank' rel='noopener noreferrer'>
          {Boolean(fileName) && <div css={uploadPreviewCardLabel}>{fileName}</div>}
        </a>}
    </div>
  )
}
