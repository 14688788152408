export function buildYearInterval (referenceYear, columns = 5, lines = 4) {
  const total = columns * lines
  const yearsList = []
  let initialYear = referenceYear - (referenceYear % total)
  for (let line = 1; line <= lines; line += 1) {
    const yearLine = []
    for (let column = 1; column <= columns; column += 1) {
      yearLine.push(initialYear)
      initialYear += 1
    }
    yearsList.push(yearLine)
  }
  return yearsList
}
