import { authenticatedService, RequestError } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { parseCrudError } from '@/utils/parse-error'
import { EntityPronoun, ErrorReport } from '@/types/domains/Error'
import { ExceptionalDay, ExceptionalDayRes } from '@/types/domains/ExceptionalDay'

const decodeError = (error: RequestError<ErrorReport>) => parseCrudError(error, 'dia excepcional', EntityPronoun.m)

const exceptionalDayService = authenticatedService('v1')

const create = (exceptionalDay: ExceptionalDay): Promise<ExceptionalDayRes | any> => {
  return exceptionalDayService.post(exceptionalDay, 'exceptional_day')
    .then(extractData)
    .catch((requestError: RequestError<ErrorReport>) => decodeError(requestError))
}

const getById = (exceptionalDayId: string): Promise<ExceptionalDayRes | any> => {
  return exceptionalDayService.get({}, `exceptional_days/${exceptionalDayId}`).then(extractData)
}

const list = (params: any): Promise<any> => {
  return exceptionalDayService.get({ ...params }, 'exceptional_days').then(extractData)
}

const update = (exceptionalDay: ExceptionalDay, exceptionalDayId: string): Promise<ExceptionalDayRes | any> => {
  return exceptionalDayService.patch(exceptionalDay, `exceptional_days/${exceptionalDayId}`)
    .catch((requestError: RequestError<ErrorReport>) => decodeError(requestError))
}

const remove = (exceptionalDayId: string): Promise<any> => exceptionalDayService.remove({}, `exceptional_days/${exceptionalDayId}`)
  .catch((requestError: RequestError<ErrorReport>) => decodeError(requestError))

exceptionalDayService.setServiceMethods({ create, getById, list, update, remove })

export const exceptionalDayDomain = exceptionalDayService.initialize
