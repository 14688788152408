import { css } from '@emotion/core'
import { colors } from './colors'

export const scrollBar = (size = 10) => css`
    ${(size < 6)
    ? 'scrollbar-width: thin;'
    : ''}

    ::-webkit-scrollbar {
      width: ${size}px;
      height: ${size}px;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.gray12};
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.gray6};
      border-radius: 5px;
      border: 1px solid gray;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.gray3};
    }
`
