export const daysMonthDistribution = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const MONTH_LIST = {
  pt_BR: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  en_US: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  es_ES: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
}

export const MONTH_ABREV_LIST = {
  pt_BR: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  en_US: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  es_ES: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
}

export const MONTH_MAPPED_ABREV_LIST = {
  pt_BR: [
    ['Jan', 'Fev', 'Mar', 'Abr'],
    ['Mai', 'Jun', 'Jul', 'Ago'],
    ['Set', 'Out', 'Nov', 'Dez']
  ],
  en_US: [
    ['Jan', 'Feb', 'Mar', 'Apr'],
    ['May', 'Jun', 'Jul', 'Aug'],
    ['Sep', 'Oct', 'Nov', 'Dec']
  ],
  es_ES: [
    ['Ene', 'Feb', 'Mar', 'Abr'],
    ['May', 'Jun', 'Jul', 'Ago'],
    ['Sep', 'Oct', 'Nov', 'Dic']
  ]
}

export const DAY_OF_WEEK_LIST = {
  pt_BR: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  en_US: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  es_ES: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab']
}
