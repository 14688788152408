import { authenticatedService } from '@/interfaces/authenticatedService'
import { CreateVoucher, CancelVoucher } from '@/types/domains/VoucherUnico'
import { extractData } from '@/utils/data-extractor'

const voucherUnicoService = authenticatedService('/v1/vouchers_unico')
const voucherTurisService = authenticatedService('/v1/vouchers_turis')
const voucherTurisCancelService = authenticatedService('/v1/tickets')

voucherUnicoService.setServiceMethods({
  createVoucher (ticketsIds: CreateVoucher): Promise<any> {
    return voucherUnicoService.postFormData(ticketsIds, 'create_voucher_unico').then(extractData)
  },
  cancelVoucher (ticketsIds: CancelVoucher): Promise<any> {
    return voucherUnicoService.patchFormData(ticketsIds, 'cancel_voucher_unico').then(extractData)
  }
})

voucherTurisService.setServiceMethods({
  createVoucher (ticketsIds: CreateVoucher): Promise<any> {
    return voucherTurisService.postFormData(ticketsIds, '').then(extractData)
  }
})

voucherTurisCancelService.setServiceMethods({
  cancelVoucher (ticketsIds: CancelVoucher): Promise<any> {
    return voucherTurisCancelService.patch({}, `${ticketsIds}/cancel_voucher_turis`).then(extractData)
  }
})

export const voucherUnicoDomain = voucherUnicoService.initialize
export const voucherTurisDomain = voucherTurisService.initialize
export const voucherTurisCancelDomain = voucherTurisCancelService.initialize
