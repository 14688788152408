import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { RefundResponse, Refund, ReasonDelete } from '@/types/domains/Financial'

const reservationRefundService = authenticatedService('/v1/reservations')
const reservationRefundServiceV2 = authenticatedService('/v2/reservations')

reservationRefundService.setServiceMethods(
  {
    getById (reservationId: string, refundId: string): Promise<RefundResponse> {
      return reservationRefundService.get({}, `${reservationId}/refunds/${refundId}`).then(extractData)
    },
    getRefundLinkById (reservationId: string, refundId: string): Promise<PaymentResponse> {
      return reservationRefundService.get({}, `${reservationId}/refunds/${refundId}`).then(extractData)
    },
    create (refund: Refund, reservationId: string): Promise<any> {
      return reservationRefundService.postFormData(refund, `${reservationId}/refunds`)
        .then(extractData)
    },
    createPay (refund: Refund, reservationId: string, paymentId: string): Promise<any> {
      return reservationRefundService.postFormData(refund, `${reservationId}/payments/${paymentId}/pay_refund`)
        .then(extractData)
    },
    edit (refund: Refund, reservationId: string, refundId: string): Promise<any> {
      return reservationRefundService.patchFormData(refund, `${reservationId}/refunds/${refundId}`)
        .then(extractData)
    },
    remove (reservationId: string, refundId: string, refundRemove: ReasonDelete): Promise<any> {
      return reservationRefundService.remove(refundRemove, `${reservationId}/refunds/${refundId}`)
        .then(extractData)
    }
  }
)

reservationRefundServiceV2.setServiceMethods({
  create (refund: Refund, reservationId: string): Promise<any> {
    return reservationRefundServiceV2.postFormData(refund, `${reservationId}/refunds`)
      .then(extractData)
  }
})

export const reservationRefundDomain = reservationRefundService.initialize
export const reservationRefundDomainV2 = reservationRefundServiceV2.initialize
