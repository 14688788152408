import { css } from '@emotion/core'

export const flex = css`
  display: flex;
`

export const flexHorizontallyCentered = css`
  ${flex}
  align-items: center;
`

export const flexColumn = css`
  ${flex}
  flex-grow:1;
  flex-direction: column;
`

export const flexRow = css`
  ${flex}
  flex-direction: row;
`

export const flexRowCentered = css`
  ${flexRow}
  align-items: center;
  justify-content: center;
`

export const flexColumnCentered = css`
  ${flexColumn}
  align-items: center;
  justify-content: center;
`
export const flexCenteredSpaceBetweenRow = css`
  ${flexRow}
  align-items: center;
  justify-content: space-between;
`

export const firstLayer = css`
  z-index: 1;
`
export const secondLayer = css`
  z-index: 2;
`
export const thirdLayer = css`
  z-index: 3;
`
export const fourthLayer = css`
  z-index: 4;
`
export const fifthLayer = css`
  z-index: 5;
`
