import { css } from '@emotion/core'

export const tableHeaderRow = css`
  th:first-of-type {
    border-radius: 10px 0px 0px 10px;
  }

  th:last-of-type {
    border-radius: 0px 10px 10px 0px;
  }
`
