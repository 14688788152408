import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { PlanRes, Plan } from '@/types/domains/Plans'

const planService = authenticatedService('v1/companies')

const get = (companyId: string): Promise<PlanRes | any> => planService.get({}, `${companyId}/plan`).then(extractData)

const create = (companyId: string, plan: Plan): Promise<PlanRes | any> => {
  return planService.post(plan, `${companyId}/plan`)
    .then(extractData)
}

planService.setServiceMethods({ get, create })

export const planDomain = planService.initialize
