import { css } from '@emotion/core'

export const yearSelector = css`
  width: 50px;
  height: 50px;
  line-height: 50px;
  span {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
`
