const white = '#FFFFFF'
const black = '#000000'

const grays = {
  gray1: '#4D4D4D',
  gray2: '#666666',
  gray3: '#6A6A6A',
  gray4: '#959595',
  gray5: '#AAAAAA',
  gray6: '#BABABA',
  gray7: '#D6D6D6',
  gray8: '#E6E6E6',
  gray9: '#EAEAEA',
  gray10: '#EDEDED',
  gray11: '#F0F0F0',
  gray12: '#F4F4F4',
  gray13: '#F7F7F7',
  gray14: '#FAFAFA',
  gray15: '#FCFCFC'
}

const blues = {
  blue1: '#286C81',
  blue2: '#55A6AC',
  blue3: '#1ED6BB',
  blueGradient1: 'linear-gradient(90deg, rgba(85,166,172,1) 0%, rgba(40,108,129,1) 100%)',
  blueGradient2: 'linear-gradient(46deg, #0096A2 0%, #1ED6BB 100%)',
  blueGradient3: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)',
  blue4: '#6AB1CE'
}

const greens = {
  green1: '#188955',
  green2: '#10bd6d',
  green3: '#38B54B',
  green4: '#F4FAFA',
  green5: '#E2F4EC',
  green6: '#218430',
  greenGradient1: 'linear-gradient(90deg, rgba(16,189,109,1) 0%, rgba(24,137,85,1) 100%)',
  green7: '#56E39F',
  green8: '#59C9A5',
  green9: '#5B6C5D',
  green10: '#4E9D8A',
  green11: '#ABD282'
}

const indigos = {
  indigo1: '#4D4DFF',
  indigo2: '#7E7EFC',
  indigo3: '#EDEDFF',
  indigoGradient1: 'linear-gradient(90deg, rgba(126,126,252,1) 0%, rgba(77,77,255,1) 100%)',
  indigo4: '#6D75AD',
  indigo5: '#465775'
}

const oranges = {
  orange1: '#FF8000',
  orange2: '#DD9E41',
  orange3: '#F6A742',
  orange4: '#FFC164',
  orange5: '#FAF2E7',
  orange6: '#CB901A'
}

const reds = {
  red1: '#E22521',
  red2: '#FF0000',
  red3: '#FF6461',
  red4: '#F64242',
  red5: '#F26D6D',
  red6: '#FBEBEB',
  redGradient1: 'linear-gradient(90deg, rgb(246, 66, 66) 0%, rgb(242, 109, 109) 100%);',
  red7: '#C48686'
}

const yellows = {
  yellow1: '#FDBB4E',
  yellow2: '#F8A520',
  yellow3: '#FFF8ED',
  yellow4: '#A7AF77'
}

const purples = {
  purple1: '#7E7EFCFA',
  purple2: '#8080FC',
  purple3: '#4E9D8A',
  purple4: '#BFA0B0'
}

const browns = {
  brown1: '#B69B89',
  brown2: '#AD9761'
}

const semantics = {
  primary: indigos.indigo1,
  primaryVariant: indigos.indigo2,
  secondary: yellows.yellow1,
  warning: oranges.orange4,
  warningVariant: oranges.orange5,
  success: greens.green3,
  sucessVariant: greens.green5,
  danger: reds.red3,
  dangerVariant: reds.red6,
  info: grays.gray5,
  infoVariant: grays.gray14,
  dark: grays.gray2,
  disabled: grays.gray11
}

export const colors = {
  white,
  black,
  ...blues,
  ...grays,
  ...greens,
  ...indigos,
  ...oranges,
  ...reds,
  ...yellows,
  ...purples,
  ...semantics,
  ...browns
}
