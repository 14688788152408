import { useCallback, useReducer } from 'react'

/**
 * @typedef {Object} UsePaginationProps
 * @property {number} [startingPage] - Home page for pagination. Optional.
 * @property {number} [totalPages] - Total number of pages available. Optional.
 * @property {number} [totalItems] - Total number of items available. Optional.
 * @property {number} [perPage] - Number of items per page. Optional.
 */

/**
 * @typedef {Object} StateHandlerProps
 * @property {PaginationStateProps} [state] - Home page for pagination.
 * @property {PaginationStateProps} [newState] - Total number of pages available.
 */

/**
 * @typedef {Object} PaginationStateProps
 * @property {number} page - Current page.
 * @property {number} totalPages - Total number of pages.
 * @property {number} totalItems - Total number of items.
 * @property {number} perPage - Number of items per page.
 * @property {boolean} isActive - Indicates whether pagination is active.
 */

/**
 * @callback PerPageChangeCallbackType
 * @param {function(props: { newPerPage: number; page: number }): void} perPageChangeCallback
 * @returns {function(newPerPage: number): void}
 */

/**
 * @callback PageChangeCallbackType
 * @param {function(props: { newPage: number }): void} pageChangeCallback
 * @returns {function(newPage: number): void}
 */

/**
 * @typedef {Object} UsePaginationReturnType
 * @property {PaginationStateProps} pagination - Current state of pagination.
 * @property {function(newState: Partial<PaginationStateProps>): void} setPagination - Function to set the state of pagination.
 * @property {PageChangeCallbackType} handleOnPageChange - Callback for page flip.
 * @property {PerPageChangeCallbackType} handleOnPerPageChange - Callback to change items per page.
 */

const DEFAULT_USE_PAGINATION_PROPS = {
  startingPage: 1,
  totalPages: 1,
  totalItems: 0,
  perPage: 10
}

/**
 * A handler function.
 * @param {StateHandlerProps} props - Pagination properties.
 */
const stateHandler = (state, newState) => {
  return {
    ...state,
    ...newState,
    isActive: true
  }
}

/**
 * Function that uses pagination properties.
 * @param {UsePaginationProps} props - Pagination properties.
 * @returns {UsePaginationReturnType} - Returns an object with the properties and callbacks of the pagination.
 */
export const usePagination = ({
  startingPage = DEFAULT_USE_PAGINATION_PROPS.startingPage,
  totalPages: totalPagesProp = DEFAULT_USE_PAGINATION_PROPS.totalPages,
  totalItems: totalItemsProp = DEFAULT_USE_PAGINATION_PROPS.totalItems,
  perPage: perPageProp = DEFAULT_USE_PAGINATION_PROPS.perPage
} = DEFAULT_USE_PAGINATION_PROPS) => {
  const [paginationState, setPaginationState] = useReducer(stateHandler, {
    page: startingPage,
    totalPages: totalPagesProp,
    totalItems: totalItemsProp,
    perPage: perPageProp,
    isActive: false
  })

  const handleOnPageChange = useCallback(
    (pageChangeCallback) => (newPage) => {
      setPaginationState({ page: newPage })
      pageChangeCallback({ newPage })
    },
    []
  )

  const handleOnPerPageChange = useCallback(
    (perPageChangeCallback) => (newPerPage) => {
      setPaginationState({ perPage: newPerPage, page: 1 })
      perPageChangeCallback({ newPerPage, newPage: 1 })
    },
    []
  )

  return {
    pagination: paginationState,
    setPagination: setPaginationState,
    handleOnPageChange,
    handleOnPerPageChange
  }
}
