import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { ReservationId, ReservationTickets, PartnershipRefundResponse, ReservationTicketSummary } from '@/types/domains/Reservation'
import { cleanPayload } from '@/utils/clean-payload'

const reservationTicketsService = authenticatedService('/v1/reservations')

reservationTicketsService.setServiceMethods({
  getTicketsToPay (reservationId: ReservationId): Promise<ReservationTickets> {
    return reservationTicketsService.get({}, `${reservationId}/tickets_to_pay`)
      .then(extractData)
  },
  getTicketsToPayFromVendor (reservationId: ReservationId, vendor_id: string): Promise<ReservationTickets> {
    return reservationTicketsService.get({}, `${reservationId}/tickets_to_pay/${vendor_id}`)
      .then(extractData)
  },
  getPartnershipTicketsToRefund (reservationId: string): Promise<PartnershipRefundResponse> {
    return reservationTicketsService.get({}, `${reservationId}/advance_tickets_to_refund`)
  },
  getTicketsToRefund (reservationId: ReservationId, searchParams: Record<string, any>): Promise<ReservationTickets> {
    const params = { ...searchParams }

    return reservationTicketsService.get({ ...cleanPayload(params) }, `${reservationId}/tickets_to_refund`)
      .then(extractData)
  },
  getReservationTickets (reservationId: ReservationId): Promise<ReservationTicketSummary> {
    return reservationTicketsService.get({}, `${reservationId}/tickets`)
      .then(extractData)
  }
})

export const reservationTicketsDomain = reservationTicketsService.initialize
