import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { flexRowCentered } from '../../../assets/styles/layout'

const marginSpacing = 15

const cardWidth = '25%'

export const flexCenteredContainer = css`
  position: relative;
  ${flexRowCentered}
  overflow: hidden;
  min-height: 150px;
  min-width: 150px;
  margin: 0 auto;
  border-radius: 14px;
  overflow: hidden;
`

export const topFixedHeight = css`
  height: 150px;
`

export const iconStyle = css`
  font-size: 100px;
`

export const overflowHidden = css`
  overflow: hidden;
`

export const uploadPreviewCard = css`
  min-width: 150px;
  position: relative;
  width: calc(${cardWidth} - ${marginSpacing * 2}px);
  min-height: 40px;
  box-sizing: border-box;
  padding: 0;
  margin: ${marginSpacing}px;
  border: 1px solid ${colors.gray9};
  border-radius: 15px;
  background-color: ${colors.white};
  cursor: default;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const uploadPreviewCardLabel = css`
  margin: 5px;
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding: 0px 5px;
  box-sizing: border-box;
  line-height: 1.5;
`

export const uploadPreviewCardDeleteButton = css`
  position: absolute;
  z-index: 1;
  right: -5px;
  top: -5px;
  width: 25px;
  height: 25px;
  background: ${colors.white};
  border-radius: var(--small-round-element-radius, 50%);
  cursor: pointer;
  border-color: transparent;
  box-shadow: 0 0 5px 0.02em rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
`

export const openIcon = css`
  position: absolute;
  display: flex;
  text-align: center;
  justify-content: center;
  bottom: 5px;
  width: 25px;
  height: 25px;
  background: ${colors.white};
  padding: 3px;
  border-radius: var(--small-round-element-radius, 50%);
  cursor: pointer;
  border-color: transparent;
  box-shadow: 0 0 5px 0.02em rgba(0, 0, 0, 0.25);
  color: ${colors.gray2};
  text-decoration: none;
`

export const link = css`
  color: ${colors.gray2};

  div {
    max-width: 200px;
  }
`
