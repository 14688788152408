import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  ConditionPayload,
  ConditionResponse,
  GetConditionsResponse
} from '@/types/domains/Condition'
import { extractData } from '@/utils/data-extractor'

const condition = authenticatedService('v1/events_processors')

const baseUrl = (eventProcessorId: string) => `${eventProcessorId}/conditions`

const getConditions = async (eventProcessorId: string): Promise<GetConditionsResponse> => {
  return await condition.get({}, baseUrl(eventProcessorId)).then(extractData)
}

const createCondition = async (
  createConditionPayload: ConditionPayload,
  eventProcessorId: string
): Promise<ConditionResponse> => {
  return await condition.post(
    createConditionPayload, baseUrl(eventProcessorId)
  ).then(extractData)
}

const getCondition = async (
  eventProcessorId: string,
  conditionId: string
): Promise<ConditionResponse> => {
  return await condition.get(
    {},
    `${baseUrl(eventProcessorId)}/${conditionId}`
  ).then(extractData)
}

const updateCondition = async (
  updateConditionPayload: ConditionPayload,
  eventProcessorId: string,
  conditionId: string
): Promise<ConditionResponse> => {
  return await condition.patch(
    updateConditionPayload,
    `${baseUrl(eventProcessorId)}/${conditionId}`
  ).then(extractData)
}

const removeCondition = async (
  eventProcessorId: string,
  conditionId: string
) => {
  return await condition.remove(
    {},
    `${baseUrl(eventProcessorId)}/${conditionId}`
  )
}

condition.setServiceMethods({
  getConditions,
  createCondition,
  getCondition,
  updateCondition,
  removeCondition
})

export const conditionDomain = condition.initialize
