import { css } from '@emotion/core'
import { colors } from './colors'
import { iconHex } from './icons'
import { resetNativeInput } from './input'

export const fontFamily = 'GT Walsheim Pro'
export const fontSize = '15px'
export const margin = '5px'
export const borderRadius = '34px'

export const baseFont = css`
  font-size: 15px;
`

export const relative = css`
  position: relative;
`

export const clickable = css`
  cursor: pointer;
`

export const roundedEdges = css`
  border-radius: var(--round-element-radius, 360px);
`

export const softnedEdges = css`
  border-radius: 5px;
`

export const disabled = css`
  &:disabled {
    cursor: default;
  }
`

export const option = css`
  ${resetNativeInput};
  position: relative;
  appearance: none;
  background: ${colors.white};
  border: 2px solid ${colors.gray8};
  width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: pointer;
  outline: none;

  &:checked {
    font-size: 12px;
    background: ${colors.secondary};
    border-color: ${colors.secondary};

    &::after {
      font-family: "bonifont";
      content: "\\${iconHex('check-thick')}";
      position: absolute;
      top: 5px;
      left: 3px;
      color: ${colors.white};
    }
  }

  &:disabled {
    cursor: initial;
    background: ${colors.gray14};

    &:checked {
      background: ${colors.secondary};
      border-color: ${colors.secondary};
      opacity: 0.4;
    }
  }

  &:focus-visible {
    outline: 2px solid #007aff;
    outline-offset: 1px;
  }
`
