import { deepSetter, identity, pureSplice } from '@bonitour/common-functions'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { object } from 'yup'
import { deepGetOrElse } from 'deep-getter'

const yupUtils = schema => {
  const internalSchema = object().shape(schema)
  const collectError = ({ path = '', message = '' }) => ({ path, message })
  function validateForm (form) {
    return internalSchema.validate(form, { abortEarly: false }).catch(({ inner = [] }) => inner.map(collectError))
  }

  function validateValue (key, context) {
    return internalSchema
      .validateAt(key, context)
      .then(() => ({ path: key, message: '' }))
      .catch(({ message = '' }) => ({ path: key, message }))
  }

  return { validateForm, validateValue }
}

function isObject (obj) {
  const type = typeof obj
  return (type === 'function' || type === 'object') && !!obj
}

const isFormValid = errors => !Object.keys(errors).length

export const useForm = (state, schema) => {
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const { validateForm, validateValue } = useMemo(() => yupUtils(schema), [schema])

  useEffect(() => {
    if (state && isObject(state)) {
      setForm(oldForm => {
        if (Object.keys(state).length === 0 && Object.keys(state).length === Object.keys(oldForm).length) {
          return oldForm
        }
        return Object.assign({}, state)
      })
    }
  }, [state])

  const changeFormValue = useCallback(
    (inputValue, inputKey) => setForm(
      formState => Object.fromEntries(
        Object.entries(
          deepSetter(
            Object.assign({}, formState),
            inputKey,
            inputValue
          )
        ).map(
          ([key, value]) => [
            key,
            Array.isArray(value) ? [...value] : value
          ]
        )
      )
    ),
    []
  )

  const changeErrorValue = (key, value) =>
    setErrors(prevErrors => deepSetter(Object.assign({}, prevErrors), key, value))

  const validate = async () => {
    const validationErrors = (await validateForm(form)) || []
    if (Array.isArray(validationErrors)) {
      return validationErrors.reduce((acc, { path, message }) => {
        return deepSetter(acc, path, message)
      }, {})
    }
    return {}
  }

  const validateSingleInput = (inputKey, inputValue) =>
    validateValue(inputKey, inputValue).then(({ path, message = '' }) => changeErrorValue(path, message))

  const onSubmit = (successCallback = identity, errorCallback = identity) => async () => {
    const formattedErrors = await validate()
    setErrors(formattedErrors)
    if (isFormValid(formattedErrors)) {
      successCallback(form)
    } else {
      errorCallback(formattedErrors, form)
    }
  }

  const onInputBlur = name => () => validateSingleInput(name, form)
  const onInputChange = name => value => changeFormValue(value, name)

  const addItemOnArray = path => defaultObject => {
    const copiedObject = Object.assign({}, defaultObject)
    const previousList = deepGetOrElse(form, path, [])
    changeFormValue(previousList.concat(copiedObject), path)
  }

  const removeItemArray = path => index => {
    const previousList = deepGetOrElse(form, path, [])
    changeFormValue(pureSplice(previousList, index, 1), path)
  }

  const resetArray = path => (defaultValue = []) => changeFormValue(defaultValue, path)

  const clearErrors = useCallback((field = '') => {
    if (field) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors }
        delete newErrors[field]
        return newErrors
      })
    } else {
      setErrors({})
    }
  }, [])

  return {
    form,
    setForm,
    errors,
    onSubmit,
    changeFormValue,
    validateSingleInput,
    validate,
    clearErrors,
    utils: {
      onInputBlur,
      onInputChange,
      addItemOnArray,
      removeItemArray,
      resetArray
    }
  }
}
