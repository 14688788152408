/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react'
import { identity, head, tail } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { CalendarHeader, CalendarSelector, CalendarTable } from '../Calendar.components'
import { buildYearInterval } from './YearSelector.utils'
import { yearSelector } from './YearSelector.style'
import { SortUpIcon } from '../../../Icons/Icons'

export const YearHeader = ({
  onYearChange: emitYearChange = identity,
  onHeaderClick: emitHeaderClick = identity,
  offset = 1,
  firstYear = 0,
  lastYear = 0
}) => {
  const onPreviousClick = () => emitYearChange(firstYear - offset)
  const onNextClick = () => emitYearChange(lastYear + 1)

  return (
    <CalendarHeader onNextClick={onNextClick} onPreviousClick={onPreviousClick} onHeaderClick={emitHeaderClick}>
      <span>
        <strong>
          {firstYear} - {lastYear}
        </strong>
        <SortUpIcon />
      </span>
    </CalendarHeader>
  )
}
const columns = 4
const lines = 4
const totalOfYears = columns * lines

export const CalendarYearSelector = ({
  onYearChange: emitYearChange = identity,
  onHeaderClick: emitHeaderClick = identity,
  selectedYear
}) => {
  const [referenceYear, setReferenceYear] = useState(selectedYear)
  const [yearInterval, setYearInterval] = useState([])

  useEffect(() => {
    if (referenceYear) {
      setYearInterval(buildYearInterval(referenceYear, columns, lines))
    }
  }, [referenceYear])

  const firstYear = head(head(yearInterval))
  const lastYear = tail(tail(yearInterval))

  return (
    <>
      <YearHeader
        onYearChange={setReferenceYear}
        onHeaderClick={emitHeaderClick}
        offset={totalOfYears}
        firstYear={firstYear}
        lastYear={lastYear}
      />
      <CalendarTable>
        {yearInterval.map((yearsList, listIndex) => (
          <tr key={`years-${listIndex}`}>
            {yearsList.map((year, yearIndex) => (
              <td key={`year-${listIndex}-${yearIndex}`}>
                <CalendarSelector
                  css={yearSelector}
                  selected={year === selectedYear}
                  onClick={() => emitYearChange(year)}
                >
                  {year}
                </CalendarSelector>
              </td>
            ))}
          </tr>
        ))}
      </CalendarTable>
    </>
  )
}
