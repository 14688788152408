import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

const conditionsOperatorsService = authenticatedService('v1')

const getConditionsOperators = (): Promise<any> => conditionsOperatorsService.get({}, 'conditions_operators').then(extractData)

conditionsOperatorsService.setServiceMethods({
  getConditionsOperators
})

export const conditionsOperatorsDomain = conditionsOperatorsService.initialize
