/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity, head, tail } from '@bonitour/common-functions'
import { flexCenteredSpaceBetweenRow, flex } from '../../assets/styles/layout'
import { Button } from '../Buttons/Buttons'
import { messageStyle, alignTop, backdrop, container, headerTitle, transition, lastUpdateStyle, checkboxContainer, linkStyle, checkboxTextStyle, linkContainerStyle } from './TermsDialog.style'
import { Backdrop } from '../Backdrop/Backdrop'
import { Checkbox } from '../Checkbox/Checkbox'
import { H1, P } from '../Headings/Headings'
import { Portal } from '../Portal/Portal'
import PropTypes from 'prop-types'

import { CSSTransition } from 'react-transition-group'
import { useState, useMemo } from 'react'

export const TermsDialog = ({
  title = 'Políticas e Termos de uso',
  message = 'Para continuar utilizando nossos serviços, é necessário que você leia e concorde com a versão atual das seguintes políticas:',
  lastUpdate = '',
  lastUpdateAsLabel = false,
  companyTermSource = '',
  privacyPolicySource = '',
  travelerTermSource = '',
  buttonLabelConfirm = 'Confirmar',
  companyTermLabel = 'Termos de uso',
  privacyPolicyLabel = 'Política de Privacidade',
  touristTermLabel = 'Termos de uso do viajante',
  checkboxLabel = 'Declaro que li e concordo com a versão atual das políticas citadas acima.',
  isVisible = false,
  successCallback = identity,
  cancelCallback = identity,
  className = 'confirmDialog',
  children = '',
  customButtonCss = [],
  customContainerCss = [],
  customHeadingCss = [],
  terms = [],
  ...other
}) => {
  const duration = 50
  const [isChecked, setIsChecked] = useState(false)

  function capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const getUnacceptedTerm = (terms, type) => {
    return head(Object.values(terms).filter(term => head(term) === type)) || []
  }

  const companyTerm = getUnacceptedTerm(terms, 'company_terms_of_use')
  const privacyPolicy = getUnacceptedTerm(terms, 'privacy_policy')
  const travelerPolicy = getUnacceptedTerm(terms, 'tourist_terms_of_use')
  const isCompanyTerm = companyTerm.length !== 0 ? !tail(companyTerm) : false
  const isPrivacyPolicy = privacyPolicy.length !== 0 ? !tail(privacyPolicy) : false
  const isTravelerPolicy = travelerPolicy.length !== 0 ? !tail(travelerPolicy) : false
  const lastUpdateLabel = useMemo(
    () => lastUpdateAsLabel ? lastUpdate : `Última atualização em ${capitalizeFirstLetter(lastUpdate)}`,
    [lastUpdate, lastUpdateAsLabel]
  )

  return (
    <Portal>
      {isVisible && <Backdrop css={backdrop} onClick={cancelCallback} />}

      <CSSTransition
        in={isVisible}
        timeout={duration}
        classNames={className}
        css={transition(className, duration)}
        unmountOnExit
      >
        <div css={[container, ...customContainerCss]} {...other}>
          <div css={[flexCenteredSpaceBetweenRow, alignTop]}>
            <H1 css={[headerTitle, ...customHeadingCss]}>
              {title}
            </H1>
          </div>
          <div>
            {children}
            <P css={lastUpdateStyle}>{lastUpdateLabel}</P>
            <P css={messageStyle}>
              {message}
            </P>
            <div css={linkContainerStyle}>
              {isCompanyTerm && (
                <a href={companyTermSource} target='_blank' rel='noopener noreferrer' css={linkStyle}>
                  {companyTermLabel}
                </a>)}
              {isPrivacyPolicy && (
                <a href={privacyPolicySource} target='_blank' rel='noopener noreferrer' css={linkStyle}>
                  {privacyPolicyLabel}
                </a>)}
              {isTravelerPolicy && (
                <a href={travelerTermSource} target='_blank' rel='noopener noreferrer' css={linkStyle}>
                  {touristTermLabel}
                </a>)}
            </div>
            <span css={[flex, checkboxContainer]}>
              <Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} />
              <P css={checkboxTextStyle}>{checkboxLabel}</P>
            </span>
            <div>
              <Button css={customButtonCss} onClick={() => successCallback(isCompanyTerm, isPrivacyPolicy, isTravelerPolicy)} disabled={!isChecked}>{buttonLabelConfirm}</Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </Portal>

  )
}

TermsDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  lastUpdate: PropTypes.string,
  lastUpdateAsLabel: PropTypes.bool,
  companyTermSource: PropTypes.string,
  privacyPolicySource: PropTypes.bool.isRequired,
  travelerTermSource: PropTypes.string,
  buttonLabelConfirm: PropTypes.string,
  companyTermLabel: PropTypes.string,
  privacyPolicyLabel: PropTypes.string,
  touristTermLabel: PropTypes.string,
  checkboxLabel: PropTypes.string,
  isVisible: PropTypes.bool,
  successCallback: PropTypes.string,
  cancelCallback: PropTypes.string,
  className: PropTypes.string,
  customButtonCss: PropTypes.array,
  customContainerCss: PropTypes.array,
  customHeadingCss: PropTypes.array,
  terms: PropTypes.array
}
