import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import {
  PaymentBatch,
  PaymentBatchItems,
  PaymentBatchAddPayment,
  PaymentBatchResponse,
  PaymentBatchTicketSearchParams,
  PaymentBatchTicketsResponse,
  PaymentBatches,
  PaymentBatchesParamsListSearchParams,
  PaymentCreateBatch
} from '@/types/domains/PaymentBatches'

const paymentBatchesPrefix = 'payment_batches'

const paymentBatchService = authenticatedService('/v1')
const paymentBatchServiceV2 = authenticatedService(`/v2/${paymentBatchesPrefix}`)

type paymentBatchesParams = { searchParams: PaymentBatchesParamsListSearchParams; pagination: MetaOnRequest }

const getPaymentBatches = ({ searchParams, pagination }: paymentBatchesParams): Promise<PaymentBatches> =>
  paymentBatchService.get({ ...cleanPayload(searchParams), ...pagination }, paymentBatchesPrefix).then(extractData)

const deletePaymentBatch = (paymentBatchId: string): Promise<PaymentBatchResponse> =>
  paymentBatchService.remove({}, `${paymentBatchesPrefix}/${paymentBatchId}`)

const showPaymentBatch = (paymentBatchId: string): Promise<PaymentBatchResponse> =>
  paymentBatchService.get({}, `${paymentBatchesPrefix}/${paymentBatchId}`).then(extractData)

const updatePaymentBatch = (paymentBatchId: string, paymentBatch: PaymentBatch): Promise<PaymentBatchResponse> =>
  paymentBatchService.patch(paymentBatch, `${paymentBatchesPrefix}/${paymentBatchId}`)

const editPaymentBatch = (paymentBatchId: string, paymentBatch: PaymentBatch): Promise<PaymentBatchResponse> =>
  paymentBatchService.patch(paymentBatch, `${paymentBatchesPrefix}/${paymentBatchId}`)

const createPaymentBatch = (paymentBatch: PaymentCreateBatch): Promise<PaymentBatchResponse> =>
  paymentBatchService.post(paymentBatch, paymentBatchesPrefix).then(extractData)

const addBatchItems = (id: string, paymentBatch: PaymentBatchItems): Promise<PaymentBatchResponse> =>
  paymentBatchService.post(paymentBatch, `${paymentBatchesPrefix}/${id}/add_items`)

const changeStatus = (id: string, status: string): Promise<PaymentBatchResponse> => paymentBatchService.patch(
  { status },
  `${paymentBatchesPrefix}/${id}/change_status`
)

const getPaymentBatchesV2 = ({ searchParams, pagination }: paymentBatchesParams): Promise<PaymentBatches> =>
  paymentBatchServiceV2.get({ ...cleanPayload(searchParams), ...pagination }, '').then(extractData)

const editPaymentBatchV2 = (paymentBatchId: string, paymentBatch: PaymentBatch): Promise<PaymentBatchResponse> =>
  paymentBatchServiceV2.patch(paymentBatch, `${paymentBatchId}`)

const showPaymentBatchV2 = (paymentBatchId: string): Promise<PaymentBatchResponse> =>
  paymentBatchServiceV2.get({}, `${paymentBatchId}`).then(extractData)

const getPaymentBatchTotalizers = (
  params: PaymentBatchesParamsListSearchParams
): Promise<PaymentBatchResponse> =>
  paymentBatchServiceV2.get(cleanPayload(params), 'totalizers').then(extractData)

const getTicketsToBatch = (
  params: PaymentBatchTicketSearchParams
): Promise<PaymentBatchTicketsResponse> =>
  paymentBatchService.get(cleanPayload(params), `${paymentBatchesPrefix}/tickets_to_batch`).then(extractData)

const registerPaymentToBatch = (
  paymentBatchId: string,
  paymentData: PaymentBatchAddPayment
): Promise<PaymentBatchResponse> => paymentBatchService.post(
  paymentData,
  `payment_batch/${paymentBatchId}/transactions`
)

const refundTransaction = (
  paymentBatchId: string,
  transactionId: string
): Promise<PaymentBatchResponse> => paymentBatchService.patch(
  {},
  `payment_batch/${paymentBatchId}/transactions/${transactionId}/reverse`
)

paymentBatchService.setServiceMethods({
  getPaymentBatches,
  deletePaymentBatch,
  showPaymentBatch,
  updatePaymentBatch,
  editPaymentBatch,
  createPaymentBatch,
  addBatchItems,
  getTicketsToBatch,
  registerPaymentToBatch,
  changeStatus,
  refundTransaction
})
paymentBatchServiceV2.setServiceMethods({
  getPaymentBatches: getPaymentBatchesV2,
  showPaymentBatch: showPaymentBatchV2,
  editPaymentBatch: editPaymentBatchV2,
  getPaymentBatchTotalizers
})

export const paymentBatchDomain = paymentBatchService.initialize
export const paymentBatchDomainV2 = paymentBatchServiceV2.initialize
