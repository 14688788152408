/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { useCallback, useMemo, useRef, useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { CheckboxInputGroup } from '../Checkbox/Checkbox'
import PropTypes from 'prop-types'
import { useClickOutside } from '@bonitour/app-functions'
import { Popover, Manager, Reference } from '../Popover/Popover'
import { InputFormGroup } from '../Input/InputFormGroup'
import { Input } from '../Input/Input'
import { colors } from '../../assets/styles/colors'
import { hidden } from '../../assets/styles/global'
import { iconHex } from '../../assets/styles/icons'
import { normalizeString } from '../../utils/string/normalizeString'

export const MultiSelect = ({
  options = [],
  isChecked = identity,
  allSelected = false,
  onOptionChange,
  onAllOptionsChange,
  ...others
}) => {
  const onAllScheduleChange = () => onAllOptionsChange()
  const uniqueId = useMemo(() => (Math.random() + 1).toString(36).substring(7), [])

  return (
    <div {...others}>
      <CheckboxInputGroup id={`${uniqueId}-all`} checked={allSelected} onChange={onAllScheduleChange}>Selecionar todos</CheckboxInputGroup>

      {options.map(option => {
        const value = option?.value || option
        const label = option?.label || option
        return (
          <div key={value}>
            <CheckboxInputGroup
              id={`${uniqueId}-${value}`}
              onChange={onOptionChange(value)}
              checked={isChecked(value)}
            >
              {label}
            </CheckboxInputGroup>
          </div>
        )
      })}
    </div>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  isChecked: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onAllOptionsChange: PropTypes.func.isRequired
}

const inputCss = css`
  background-color: ${colors.white} !important;
`

const inputFormGroupCss = open => css`
  position: relative;
  &::after {
    font-family: 'bonifont' !important;
    content: '\\${iconHex('angle-down')}';
    color: ${colors.gray2};
    position: absolute;
    right: 22px;
    font-size: 13px;
    bottom: 14px;
    transform: rotate(${open ? '-180' : '0'}deg);
    transition: 0.2s transform ease;
  }
  input {
    padding-right: 40px;
  }
`

const popoverCss = css`
  > div {
    max-height: min(300px, calc(100vh - 130px));
    overflow-y: auto;

    label {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px 0;
    }
  }
`

const wrapper = css`
  width: 100%;
`

export const MultiSelectDropdown = ({
  errorMessage,
  selected: selectedNullable,
  onChange,
  options: optionsNullable,
  customCss = [],
  label,
  placeholder,
  ...others
}) => {
  const selected = useMemo(() => selectedNullable || [], [selectedNullable])
  const options = useMemo(() => optionsNullable || [], [optionsNullable])
  const reference = useRef()
  const [isListOpen, setListOpen] = useClickOutside(reference)
  const handleListOpen = () => setListOpen(isListOpen => !isListOpen)

  const areAllSelected = useMemo(() => options.length === selected.length, [options.length, selected.length])
  const onChangeAll = useCallback(() => {
    areAllSelected
      ? onChange([])
      : onChange(options.map(option => option?.value || option))
  }, [areAllSelected, onChange, options])

  const isSelected = useCallback((value) => selected.includes(value), [selected])

  const onChangeItem = (value, ...b) => () => {
    if (isSelected(value)) {
      onChange((selected || []).filter((item) => item !== value))
    } else {
      onChange([...selected, value])
    }
  }

  const [filter, setFilter] = useState()

  const filteredOptions = useMemo(() => {
    return options.filter(option =>
      normalizeString(filter)
        .trim()
        .split(' ')
        .every(word => normalizeString(option?.label).includes(word)))
  }, [filter, options])

  const handleFilterChange = useCallback(
    (value) => {
      setFilter(value)
    },
    [setFilter]
  )

  return (
    <div ref={reference} css={[wrapper, ...customCss]} {...others}>
      <Manager>
        <>
          <Reference>
            {({ ref }) => (
              <div ref={ref} onClick={handleListOpen}>
                <InputFormGroup errorMessage={errorMessage} label={label} customCss={[inputFormGroupCss(isListOpen)]}>
                  <Input
                    css={inputCss}
                    placeholder={placeholder}
                    value={filter}
                    onChange={handleFilterChange}
                  />
                </InputFormGroup>
              </div>
            )}
          </Reference>
          <Popover css={[popoverCss, !isListOpen && hidden]}>
            <MultiSelect
              options={filteredOptions}
              isChecked={isSelected}
              onOptionChange={onChangeItem}
              onAllOptionsChange={onChangeAll}
              allSelected={areAllSelected}
            />
          </Popover>
        </>
      </Manager>
    </div>
  )
}
