import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

const braspagAccessTokenService = authenticatedService('/v1/checkout/access_token')

const createToken = (): Promise<any> => braspagAccessTokenService.post({}).then(extractData)

braspagAccessTokenService.setServiceMethods({
  createToken
})

export const braspagAccessTokenDomain = braspagAccessTokenService.initialize
