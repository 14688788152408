import { cookieStorage } from '../storage/cookie'

export const login = () => {
  const storage = cookieStorage()
  const loginKey = 'bonitour-login'

  const setLoginCredentials = data => storage.set(loginKey, data)
  const getLoginCredentials = () => storage.get(loginKey)
  const cleanLoginCredential = () => storage.remove(loginKey)

  return {
    set: setLoginCredentials,
    get: getLoginCredentials,
    clean: cleanLoginCredential
  }
}
