import { authenticatedService } from '@/interfaces/authenticatedService'
import { BillingConfigurationItem, ConfigurationAuditsParams, ConfigurationAuditsRes, UpdateBillingConfigurationParams } from '@/types/domains/BillingConfiguration'
import { extractData } from '@/utils/data-extractor'

const billingConfigurationService = authenticatedService('v1/billing_configurations')

const get = (configurationId: string): Promise<BillingConfigurationItem> => billingConfigurationService.get({}, configurationId).then(extractData)

const update = (params: UpdateBillingConfigurationParams, configurationId: string): Promise<BillingConfigurationItem | any> => {
  return billingConfigurationService.patch(params, configurationId)
}

const getConfigurationAudits = async (params: ConfigurationAuditsParams, configurationId: string): Promise<ConfigurationAuditsRes> => {
  return await billingConfigurationService.get(params, `${configurationId}/audit`).then(extractData)
}

billingConfigurationService.setServiceMethods({ update, get, getConfigurationAudits })

export const billingConfigurationDomain = billingConfigurationService.initialize
