/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

const headingBase = css`
  font-weight: 600;
  color: ${colors.gray3};
  margin-bottom: 20px;
`

export const heading1 = css`
  font-size: 26px;
  color: ${colors.gray1};
`

export const heading2 = css`
  font-size: 23px;
`

export const heading3 = css`
  font-size: 20px;
`

export const heading4 = css`
  font-size: 17px;
`

export const paragraph = css`
  font-size: 16px;
  color: ${colors.gray3};
  font-weight: 500;
  margin-bottom: 15px;
`

export const H1 = ({ customCss = [], ...rest }) => (
  <h1 {...rest} css={[headingBase, heading1, ...customCss]} />
)

export const H2 = ({ customCss = [], ...rest }) => (
  <h2 {...rest} css={[headingBase, heading2, ...customCss]} />
)

export const H3 = ({ customCss = [], ...rest }) => (
  <h3 {...rest} css={[headingBase, heading3, ...customCss]} />
)

export const H4 = ({ customCss = [], ...rest }) => (
  <h4 {...rest} css={[headingBase, heading4, ...customCss]} />
)

export const P = ({ customCss = [], ...rest }) => (
  <p {...rest} css={[paragraph, ...customCss]} />
)
