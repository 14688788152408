import { css } from '@emotion/core'

export const messageStyle = css`
  margin: 20px 0 40px 0;
  line-height: 1.15;
  overflow: auto;
  max-height: calc(100vh - 280px);
  display: inline-block;
`
export const marginRight20 = css`
  margin-right: 20px;
`
