export function localStorage (prefix) {
  const { localStorage: storage } = window.top

  const buildLabel = token => `${prefix ? `${prefix}_` : ''}${token}`

  const getData = token => {
    const keyData = storage.getItem(buildLabel(token))
    try {
      return JSON.parse(keyData)
    } catch (_) {
      return keyData
    }
  }

  const setData = (token, value) => {
    try {
      storage.setItem(buildLabel(token), JSON.stringify(value))
    } catch (_) {
      storage.setItem(buildLabel(token), value)
    }
  }

  const removeByLabel = label => storage.removeItem(label)

  const cleanData = () => {
    Object.keys(storage).forEach(key => {
      if (key.indexOf(prefix) === 0) {
        removeByLabel(key, storage)
      }
    })
  }

  const get = token => getData(token)
  const set = (token, value) => setData(token, value)
  const remove = token => removeByLabel(buildLabel(token))
  const clean = () => cleanData()

  return { get, set, remove, clean }
}
