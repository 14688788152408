/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from '@emotion/core'

const scrollIntoView = selector => {
  const options = {
    block: 'start',
    behavior: 'smooth'
  }

  const element = document.getElementById(selector)
  element.scrollIntoView(options)
}

export function Accordion ({
  value,
  children,
  sectionName = 'accordionSection',
  onClick: emitClickEvent,
  ...other
}) {
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {
      id: `${sectionName}${child.props.indicator}`,
      isExpanded: value !== 0 && value === child.props.indicator,
      onClick: emitClickEvent
    })
  })

  useEffect(() => {
    if (value) {
      scrollIntoView(`${sectionName}${value}`)
    }
  // eslint-disable-next-line
  }, [value])

  return (
    <div {...other}>
      {childrenWithProps}
    </div>
  )
}
