import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

type InviteResponse = {
  accept: boolean;
}

type InviteId = string

const InviteService = authenticatedService('/v1/invites')

const InvitesRoutes = {
  get (inviteId: InviteId) {
    return InviteService.get({}, inviteId).then(extractData)
  },
  list () {
    return InviteService.get().then(extractData)
  },
  respond (data: InviteResponse, inviteId: InviteId) {
    return InviteService.put(data, `${inviteId}/respond`).then(extractData)
  }
}

InviteService.setServiceMethods(InvitesRoutes)

export const invitationDomain = InviteService.initialize
