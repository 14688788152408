/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { softnedEdges } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'

const arrow = css`
  position: absolute;
  ${softnedEdges}

  &::before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 5px 0px;
  }
`

const setColors = color => css`
  &::before {
    border-color: transparent ${color} transparent transparent;
  }
`

const top = css`
  transform: rotate(-90deg);
  left: calc(50% - 5px);
  bottom: -7px;
`

const bottom = css`
  transform: rotate(90deg);
  left: 50%;
  top: -7px;
`

const left = css`
  transform: rotate(180deg);
  right: -5px;
`

const right = css`
  left: -5px;
`

const arrowPosition = {
  top,
  bottom,
  left,
  right
}

export const TooltipArrow = React.forwardRef(({ position = 'bottom', color = colors.gray4, ...other }, ref) => (
  <div css={[arrow, setColors(color), arrowPosition[position] || top]} ref={ref} {...other} />
))
