import React, { useCallback, useMemo } from 'react'

import { changeSortOrder } from './helpers'
import { TableHeaderWrapper } from './styles'
import { useTable } from './TableContext'

const TableHeader = ({ label, columnIdx = 0, onChange }) => {
  const { sortableColumns, sortedColumn, sortedOrder } = useTable()

  const sortOrder = useMemo(
    () => (sortedColumn === columnIdx ? sortedOrder : ''),
    [columnIdx, sortedColumn, sortedOrder]
  )

  const isSortable = useMemo(
    () => sortableColumns.includes(label),
    [label, sortableColumns]
  )

  const handleClick = useCallback(() => {
    if (!isSortable) return
    onChange(changeSortOrder(sortOrder))
  }, [isSortable, onChange, sortOrder])

  return (
    <TableHeaderWrapper
      className={[
        'TableHeader',
        sortOrder ? `sorted-${sortOrder}` : '',
        isSortable ? 'sortable' : ''
      ].join(' ')}
      onClick={handleClick}
      role={isSortable ? 'button' : undefined}
    >
      {label}
    </TableHeaderWrapper>
  )
}

export { TableHeader }
