/**
 * Get the last item from the array
 * @param { T[] } arr the array that is being passed
 * @returns { T | undefined } Any data that might be on the last index or undefined if the array is empty
 * @template T
 */
export const tail = arr => (arr && arr.length ? arr[arr.length - 1] : undefined)

/**
 * Get the first item from the array
 * @param { T[] } arr the array that is being passed
 * @returns { T | undefined } Any data that might be on the last index or undefined if the array is empty
 * @template T
 */
export const head = arr => (arr && arr.length ? arr[0] : undefined)

/**
 * Simulate the behaviour of Array.splice as a pure function
 * @param { T[] } arr the array that is being passed
 * @param { Number } start The index of the first item that is being deleted
 * @param { Number } deleteCount How many items should be deleted
 * @param  {...T } items Any items that may be inserted in the place of the removed item
 * @returns { T[] } The result array
 * @template T
 */
export function pureSplice (arr, start, deleteCount, ...items) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)]
}

/**
 * Given an array, remove all of it's duplicate values without changing the original
 * @global
 * @param { T[] } arr Original array
 * @returns { T[] } array without duplicate values
 * @template T
 */
export function getUniqueValues (arr) {
  return arr.filter((item, index, arr) => arr.indexOf(item) === index)
}
