import React from 'react'
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../Table/Table'
import { loadingTableContainer } from './LoadingTable.style'

export const LoadingTable = ({ elementsCount, columns, customCss = [] }) => {
  return (
    <TableContainer css={[loadingTableContainer, ...customCss]}>
      <div>
        <TableHeader>
          <TableHeaderRow>
            {columns.map((value) => (
              <TableHeaderCell key={value}>{value}</TableHeaderCell>
            ))}
          </TableHeaderRow>
        </TableHeader>
        {Array.from({ length: elementsCount }, (value) => {
          return <span key={value} />
        })}
      </div>
    </TableContainer>
  )
}
