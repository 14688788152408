import { authenticatedService } from '@/interfaces/authenticatedService'
import { PaymentProviderRes } from '@/types/domains/PaymentProvider'
import { extractData } from '@/utils/data-extractor'

const cardBrandsService = authenticatedService('v1/card_brands')

const listCardBrands = async (): Promise<PaymentProviderRes> => {
  return cardBrandsService.get({}).then(extractData)
}

cardBrandsService.setServiceMethods({ list: listCardBrands })

export const cardBrandsDomain = cardBrandsService.initialize
