/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation } from '../../Loading/LoadingAnimation'
import { TableBodyCell } from '../TableBodyCell/TableBodyCell'
import { TableBodyRow } from '../TableBodyRow/TableBodyRow'
import { tableBodyEmptyCell, tableBody, tableBodyCenteredCell } from './TableBody.style'

const TableBodyFallback = ({ message = 'Sem dados' }) => (
  <TableBodyRow>
    <TableBodyCell className='table-body-empty' colSpan='100%' css={tableBodyEmptyCell}>
      {message}
    </TableBodyCell>
  </TableBodyRow>
)

const TBody = props => <tbody {...props} />

export function TableBody ({ children, loading, fallbackMessage = 'Sem dados', ...props }) {
  if (loading) {
    return (
      <TableBodyCell colSpan='100%'>
        <div css={tableBodyCenteredCell}>
          <LoadingAnimation size={30} />
        </div>
      </TableBodyCell>
    )
  }
  if (children && Array.isArray(children) && !children.length) {
    return (
      <TBody {...props}>
        <TableBodyFallback message={fallbackMessage} />
      </TBody>
    )
  }
  return (
    <TBody css={tableBody} {...props}>
      {children || <TableBodyFallback message={fallbackMessage} />}
    </TBody>
  )
}
