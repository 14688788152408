import { css } from '@emotion/core'

import { colors } from './colors'
import { flexRowCentered } from './layout'

export const clickable = css`
  cursor: pointer;
`

export const statusIndicator = css`
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
`

export const roundedRightCorners = css`
  border-radius: 0 10px 10px 0;
`

export const roundedLeftCorners = css`
  border-radius: 10px 0 0 10px;
`

export const tableHeaderBase = css`
  height: 70px;
`

export const innerTableHeaderBase = css`
  height: 50px;
`

export const tableHeaderCellBase = css`
  height: 70px;
  padding: 0 0 0 20px;
  font-weight: 500;
  background-color: ${colors.gray12};

  &:last-of-type {
    ${roundedRightCorners};
  }

  &:first-of-type {
    ${roundedLeftCorners};
  }
`

export const innerTableHeaderCellBase = css`
  height: 50px;
  padding: 0 0 0 20px;
  font-weight: 500;
  background-color: ${colors.gray14};

  &:last-of-type {
    ${roundedRightCorners};
  }

  &:first-of-type {
    ${roundedLeftCorners};
  }
`

export const defaultRowHeight = css`
  height: 50px;
`

export const defaultCellPadding = css`
  padding: 0 0 0 20px;
`

export const resetCellColor = css`
  background-color: ${colors.white} !important;
`

export const arrowOpenIndicator = css`
  transform: rotateZ(90deg);
`

export const openingTransition = css`
  transition: 0.2s;
`

export const margin = (marginTop, marginRight, marginBottom, marginLeft) => css`
  margin: ${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px;
`

export const marginTop = margin => css`
  margin-top: ${margin}px;
`

export const marginBottom = margin => css`
  margin-bottom: ${margin}px;
`

export const marginLeft = margin => css`
  margin-left: ${margin}px;
`

export const marginRight = margin => css`
  margin-right: ${margin}px;
`

export const width = percentage => css`
  width: ${percentage}%;
`

export const primaryColor = css`
  color: ${colors.primary};
`

export const link = css`
  color: ${colors.primary};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`

export const resetLink = css`
  text-decoration: none;
`

export const hidden = css`
  display: none;
`

export const backgroundPrimaryVariant = css`
  background-color: ${colors.primaryVariant};
`
export const loadingContainer = css`
  ${flexRowCentered};
  width: 100%;
  margin: 50px 0;
`
