const heightRegex = /^(\d{1})(\d{2})$/g
const heightMask = '$1,$2'

/**
 * Formats a 3 length number into human readable Height
 * @param { String } [height=''] number of 3 digits length
 * @returns { String } formatted height
 */
export const formatHeight = (height = '') => height.replace(heightRegex, heightMask)

/**
 * Masks given value to height, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format (\d{1}),(\d{2})
 */
export const maskHeight = (value = '') =>
  formatHeight(
    value
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d{1})/, '$1,$2')
      .replace(/(\d{1})/, '$1')
  )
