import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const messageStyle = css`
  margin: 20px 0;
  max-width: 320px;
  text-align: left;
  font-size: 15px;
  color: ${colors.gray3};
`

export const linkStyle = css`
  color: ${colors.primary};

  &:visited {
    color: ${colors.primary}
  }
`

export const marginRight20 = css`
  margin-right: 20px;
`

export const headerTitle = css`
  margin: 0;
  font-size: 24px;
`

export const lastUpdateStyle = css`
  color: ${colors.gray6};
  margin: 15px 0;
`

export const alignTop = css`
  align-items: flex-start;
`

export const backdrop = css`
  width: 100%;
  height: 100%;
  z-index: 20;
`

export const container = css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  max-width: 500px;
  width: calc(100% - 10px);
  padding: 30px;
  background-color: ${colors.white};
  z-index: 25;
  border-radius: 10px;
  box-sizing: border-box;
`

export const transition = (className, duration) => css`
  &.${className}-enter {
    opacity: 0;
  }
  &.${className}-enter-active {
    transition: opacity ${duration}ms;
  }
  &.${className}-exit-active {
    transition: opacity ${duration}ms;
  }
`

export const checkboxContainer = css`
  margin: 20px 0;
  align-items: center;
`

export const checkboxTextStyle = css`
  color: ${colors.gray3};
  margin-left: 5px;
  margin-bottom: 0;
  max-width: 330px;
`

export const capitalize = css`
  text-transform: capitalize;
`

export const linkContainerStyle = css`
  a {
    display: block;
  }

  a:nth-child(2) {
    margin-top: 10px;
  }
`
