import { useState, useEffect, useCallback } from 'react'

export function useClickOutside (reference) {
  const [isVisible, setVisibility] = useState(false)

  useEffect(() => {
    const handleClickOutside = e => {
      if (reference.current && reference.current.contains(e.target)) {
        return
      }
      setVisibility(false)
    }
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isVisible, reference])

  const setVisibilityOn = useCallback(() => setVisibility(true), [])
  const setVisibilityOff = useCallback(() => setVisibility(false), [])

  return [isVisible, setVisibility, setVisibilityOn, setVisibilityOff]
}
