export type ErrorMsg = string

export interface ErrorReport{
  errors?: string[];
  errors_msg?: ErrorMsg[];
  extra_data?: Record<string, any>;
}

export type ErrorMessageGenerator = (error: ErrorReport) => string

export type ErrorMap = Record<string, ErrorMessageGenerator>

export type ParserErrors = {
  parsedErrors: string[];
}

export enum EntityPronoun {
  f = 'a',
  m = 'o'
}
