import { useEffect, useRef, useState } from 'react'

export const useElementBoundingClientRect = (
  ref,
  type,
  throttleTime = 500
) => {
  const [boundingClientRect, setBCRect] = useState(new DOMRect())

  const lastRan = useRef(0)
  const nextUpdateValue = useRef(new DOMRect())
  const timeout = useRef(null)

  useEffect(() => {
    const current = ref.current

    if (!current) return

    const update = (rect) => {
      setBCRect(rect)
      lastRan.current = Date.now()
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
    }

    const observer = new ResizeObserver(([entry]) => {
      const timeFromLastUpdate = Date.now() - lastRan.current

      const currentValue =
        type === 'bounding'
          ? entry.target.getBoundingClientRect()
          : entry.contentRect

      if (timeFromLastUpdate > throttleTime) {
        update(currentValue)
      } else {
        nextUpdateValue.current = currentValue

        if (!timeout.current) {
          timeout.current = setTimeout(() => {
            update(nextUpdateValue.current)
          }, throttleTime - timeFromLastUpdate)
        }
      }
    })

    observer.observe(current)
    return () => {
      observer.unobserve(current)
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [ref, throttleTime, type])

  return boundingClientRect
}
