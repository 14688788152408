/* eslint-disable no-undef */
/**
 * Append data to a object with FormData shape
 * @param { String } key key to update some value
 * @param { T } data value to be changed
 * @param { function } formData instance of formData web api
 * @param { boolean } allowNull flag to check if need to keep null values
 * @returns { Object } data with FormData shape
 */
const appendData = (key, data, formData, allowNull = false) => {
  if (!allowNull && (data === undefined || data === null)) {
    return
  }
  if (allowNull && data === undefined) {
    return
  }

  if (data instanceof Array) {
    data.forEach(item => appendData(`${key}[]`, item, formData))
    return
  } else if (data instanceof File) {
    formData.append(key, data)
    return
  } else if (data instanceof Object) {
    Object.entries(data).forEach(([extraKey, item]) => appendData(`${key}[${extraKey}]`, item, formData))
    return
  }
  formData.append(key, data)
}

/**
 * Formats form json input to FormData
 * @param { Object } data value to be formatted
 * @param { function } allowNull flag to check if need to keep null values
 * @returns { Object } formatted form data
 */
export const convertToFormData = (data, allowNull = false) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, data]) => appendData(key, data, formData, allowNull))
  return formData
}
