import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { CountPlanListResponse, CountPlanListSearchParams, CreateCountPlanPayload } from '@/types/domains/CountPlan'

const countPlanService = authenticatedService('v1/accountable_plans')

type ListParams = { searchParams: CountPlanListSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<CountPlanListResponse> => {
  return countPlanService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

const create = (payload: CreateCountPlanPayload): Promise<any> => {
  return countPlanService.post(payload).then(extractData)
}

const get = (planId: string): Promise<any> => countPlanService.get({}, planId).then(extractData)

const update = (countPlan: any, planId: string): Promise<any> => {
  return countPlanService.put(countPlan, planId)
}

const remove = (planId: string): Promise<any> => countPlanService.remove({}, planId)

countPlanService.setServiceMethods({ list, get, create, update, remove })

export const countPlanDomain = countPlanService.initialize
