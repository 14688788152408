/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

const row = css`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const Row = ({ customCss = [], ...props }) => <div css={[row, ...customCss]} {...props} />
