/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../../assets/styles/colors'
import { CircleThumbnail } from '../../../Thumbnails/Thumbnails'
import { ResponsiveImage } from '../../../Image/Image'
import { BREAK_POINTS } from '../../../Layout/Layout.style'
import { flexRowCentered } from '../../../../assets/styles/layout'

const { bigPhone } = BREAK_POINTS

const userThumbContainer = css`
  padding: 6px 10px;
  border: 1px solid ${colors.gray11};
  border-radius: 10px;  
`

const companyImageStyle = css`
  max-height: 36px;
  margin-right: 10px;
  @media (max-width: ${bigPhone}) {
    display: none;
  }
`

const imageContainer = css`
  width: 35px;
  height: 35px;
  background-color: ${colors.gray12};
  font-weight: bold;
  text-transform: capitalize;
  color: ${colors.gray4};
`

const getFirstLetter = name => name[0] || ''

export const UserThumb = ({ companyImage, avatar, name = 'usuário', ...other }) => (
  <div {...other} css={[flexRowCentered, userThumbContainer]}>
    {Boolean(companyImage) && <img src={companyImage} css={companyImageStyle} />}
    <CircleThumbnail size={35} css={imageContainer}>
      {avatar ? <ResponsiveImage src={avatar} alt={`Foto do ${name}`} /> : getFirstLetter(name)}
    </CircleThumbnail>
  </div>
)
