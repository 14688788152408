import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  GetEventsResponse,
  GetEventsSearchParams,
  CreateEventPayload,
  GetEventResponse
} from '@/types/domains/Event'

const eventService = authenticatedService('v1/events')

const getEvents = (getEventsSearchParams: GetEventsSearchParams): Promise<GetEventsResponse> => {
  return eventService.get(getEventsSearchParams).then(extractData)
}

const getEventsTypes = (): Promise<string[]> => {
  return eventService.get({}, '/event_types').then(extractData)
}

const getEventFieldsNames = async (
  eventId: string
): Promise<string[]> => {
  return eventService.get({}, `${eventId}/fields_names`).then(extractData)
}

const create = (payload: CreateEventPayload): Promise<any> => {
  return eventService.post(payload).then(extractData)
}

const getEvent = (eventId: string): Promise<GetEventResponse> => eventService.get({}, eventId).then(extractData)

const update = (event: any, eventId: string): Promise<any> => {
  return eventService.put(event, eventId)
}

const remove = (eventId: string): Promise<any> => eventService.remove({}, eventId)

eventService.setServiceMethods(
  {
    getEvents,
    getEventsTypes,
    getEvent,
    getEventFieldsNames,
    create,
    update,
    remove
  }
)

export const eventDomain = eventService.initialize
