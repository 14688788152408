/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  header,
  titleContainer,
  boxInsideTableData,
  labelInsideTableDate,
  selectedStyle,
  disabledStyle,
  todayStyle,
  body
} from './Calendar.style'
import { flexHorizontallyCentered } from '../../../assets/styles/layout'
import { GhostCircleButton } from '../../Buttons/Buttons'
import { AngleRightIcon, AngleLeftIcon } from '../../Icons/Icons'
import { identity } from '@bonitour/common-functions'

export const CalendarHeader = ({
  onPreviousClick,
  onNextClick,
  onHeaderClick,
  children
}) => {
  return (
    <div css={[header, flexHorizontallyCentered]}>
      {onPreviousClick && (
        <GhostCircleButton type='button' onClick={onPreviousClick}>
          <AngleLeftIcon />
        </GhostCircleButton>
      )}
      <div css={titleContainer}>
        <span onClick={onHeaderClick}>{children}</span>
      </div>
      {onNextClick && (
        <GhostCircleButton type='button' onClick={onNextClick}>
          <AngleRightIcon />
        </GhostCircleButton>
      )}
    </div>
  )
}

export const CalendarSelector = ({
  disabled,
  children,
  selected,
  onClick: emitClickEvent = identity,
  today = false,
  ...other
}) => {
  return (
    <div {...other} css={boxInsideTableData}>
      <span
        onClick={!disabled ? emitClickEvent : identity}
        css={[
          labelInsideTableDate,
          selected && selectedStyle,
          disabled && disabledStyle,
          today && todayStyle
        ]}
      >
        {children}
      </span>
    </div>
  )
}

export const CalendarTable = props => (
  <table css={body} cellSpacing='0' cellPadding='0'>
    <tbody {...props} />
  </table>
)
