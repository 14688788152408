import { authenticatedService } from '@/interfaces/authenticatedService'
import { SupplierBankAccountParams, SupplierBankAccountItem, SupplierBankAccountList, SupplierBankAccountAuditParams, SupplierBankAccountAuditRes } from '@/types/domains/SupplierBankAccount'
import { extractData } from '@/utils/data-extractor'

const supplierBankAccountService = authenticatedService('v1/suppliers')

const list = async (supplierId: string): Promise<SupplierBankAccountList> => {
  return await supplierBankAccountService.get({}, `${supplierId}/supplier_bank_accounts`).then(extractData)
}

const create = async (params: SupplierBankAccountParams, supplierId: string): Promise<SupplierBankAccountItem> => {
  return await supplierBankAccountService.post(params, `${supplierId}/supplier_bank_accounts`)
}

const get = (supplierId: string, supplierBankAccountId: string): Promise<SupplierBankAccountItem> => {
  return supplierBankAccountService.get({}, `${supplierId}/supplier_bank_accounts/${supplierBankAccountId}`).then(extractData)
}

const update = async (params: SupplierBankAccountParams, supplierId: string, supplierBankAccountId: string): Promise<SupplierBankAccountItem> => {
  return await supplierBankAccountService.patch(params, `${supplierId}/supplier_bank_accounts/${supplierBankAccountId}`)
}

const remove = (supplierId: string, supplierBankAccountId: string): Promise<void> => {
  return supplierBankAccountService.remove({}, `${supplierId}/supplier_bank_accounts/${supplierBankAccountId}`)
}

const getSupplierBankAccountAudit = async (params: SupplierBankAccountAuditParams, supplierBankAccountId: string, supplierId: string): Promise<SupplierBankAccountAuditRes> => {
  return await supplierBankAccountService.get(params, `${supplierId}/supplier_bank_accounts/${supplierBankAccountId}/audit`).then(extractData)
}

supplierBankAccountService.setServiceMethods({ list, create, get, update, remove, getSupplierBankAccountAudit })

export const supplierBankAccountDomain = supplierBankAccountService.initialize
