/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../../assets/styles/colors'

const container = css`
  display: flex;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${colors.gray12};
  padding: 10px 15px;
`

export const LogoMenuItem = ({
  logoSrc,
  ...props
}) => {
  return (
    <div css={container} {...props}>
      <img src={logoSrc} alt='Logo' />
    </div>
  )
}
