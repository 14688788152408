const appendDataToFormData = (key: string, data: any, formData: FormData) => {
  if (data === undefined || data === null) {
    return
  }
  if (data instanceof Array) {
    data.forEach((item) => appendDataToFormData(`${key}[]`, item, formData))
    return
  } else if (data instanceof File) {
    formData.append(key, data)
    return
  } else if (data instanceof Object) {
    Object.entries(data).forEach(([extraKey, item]) => appendDataToFormData(`${key}[${extraKey}]`, item, formData))
    return
  }
  formData.append(key, data)
}

export const convertToFormData = (data: Record<string, any>) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, data]) => appendDataToFormData(key, data, formData))
  return formData
}
