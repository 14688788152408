import { colors } from '../../assets/styles/colors.js'
import { css } from '@emotion/core'
import { iconHex } from '../../assets/styles/icons.js'
export * as libStyle from 'react-phone-input-2/lib/high-res.css'

export const buttonStyle = {
  border: `1px solid ${colors.gray8}`,
  borderRadius: 'var(--round-element-radius, 20px)'
}

export const inputStyle = ({ ...other }) => ({
  width: 'inherit',
  height: '41px',
  border: `1px solid ${colors.gray8}`,
  borderRadius: 'var(--round-element-radius, 20px)',
  color: `${other?.disabled ? colors.gray6 : colors.gray1}`,
  paddingLeft: '55px',
  paddingRight: '10px',
  borderColor: colors.gray8,
  fontSize: '15px',
  fontVariantNumeric: 'tabular-nums',
  letterSpacing: 'normal',
  backgroundColor: `${other?.disabled ? colors.gray11 : colors.white}`
})

export const inputErrorStyle = ({ ...other }) => ({
  ...inputStyle({ ...other }),
  borderColor: colors.danger
})

export const divContainer = ({ ...other }) => css`
  width: fit-content;
  position: relative;

  .react-tel-input {
    width: inherit;
    position: relative;
  }

  * {
    ${other?.disabled && 'cursor: not-allowed;'}

    &, &::placeholder {
      font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI',
          'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          'Helvetica Neue', sans-serif !important;
    }
    font-size: 15px;
  }

  .fake-placeholder {
    position: absolute;
    top: 50%;
    pointer-events: none;
    touch-action: none;
    transform: translateY(-50%);
    color: ${colors.gray6};
    left: 65px;
    right: 12px;
    overflow: hidden;
  }

  input,
  input::placeholder,
  .fake-placeholder {
    font-weight: 400 !important;
    opacity: 1;
    font-size: 15px;
    font-variant-numeric: 'tabular-nums';
    letter-spacing: normal;
  }

  input::placeholder,
  .fake-placeholder {
    color: ${colors.gray6};
  }


  .flag {
    border-right: 1px solid white;
  }

  .country-list .country {
    &:hover,
    &.highlight {
      .flag {
        border-right: 1px solid #f1f1f1;
      }
    }
  }

  .selected-flag {
    padding-left: 12px !important;
    background-color: transparent !important;
    opacity: ${other?.disabled ? 0.5 : 1};

    :hover {
      border-radius: 100% !important;
    }
  }

  .flag-dropdown {
    border: none !important;
    background-color: transparent !important;
    &.open {
      background-color: transparent !important;
    }
  }

  ul.country-list {
    border-radius: 6px !important;
    @media (max-width: 360px) {
      margin-left: -10px !important;
    }
  }

  li.search {
    position: relative;
    cursor: default;
    &::before {
      font-family: 'bonifont';
      display: block;
      content: '\\${iconHex('search')}';
      font-size: 20px;
      position: absolute;
      top: 18px;
      left: 28px;
      color: ${colors.gray3};
    }
  }

  input.search-box {
    border-radius: var(--round-element-radius, 20px)!important;
    padding: 8px 14px !important;
    padding-left: 30px !important;
    border-color: ${colors.gray8} !important;

    &::placeholder {
      content: none !important;
      display: none !important;
      opacity: 0 !important;
    }
  }

  input.search-box[type='search']::-webkit-search-decoration,
  input.search-box[type='search']::-webkit-search-cancel-button,
  input.search-box[type='search']::-webkit-search-results-button,
  input.search-box[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  .search-emoji {
    display: none;
  }

  .arrow {
    transition: transform 200ms;
    border: none !important;
    transform: translateY(-1px) rotate(135deg);
    width: 5px !important;
    height: 5px !important;
    border-top: 1px solid ${other?.disabled ? colors.gray6 : colors.gray1} !important;
    border-right: 1px solid ${other?.disabled ? colors.gray6 : colors.gray1} !important;
  }

  .arrow.up {
    transform: translateY(2px) rotate(-45deg);
    border-top: 1px solid ${other?.disabled ? colors.gray6 : colors.gray1} !important;
    border-right: 1px solid ${other?.disabled ? colors.gray6 : colors.gray1} !important;
  }
`
