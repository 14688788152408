/** @jsxRuntime classic */
/** @jsx jsx */
import { useRef } from 'react'
import { ChromePicker } from 'react-color'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'

import { Popover, Manager, Reference } from '../Popover/Popover'
import { colorPickerContainer, colorPicker, angleDown, error, disabledStyle, plugin, colorPreview } from './Colorpicker.style'
import { colors } from '../../assets/styles/colors'

export function ColorPicker ({
  children,
  value = colors.gray5,
  readOnly = false,
  disabled = false,
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  error: hasError = false,
  ...other
}) {
  const colorPickerReference = useRef()
  const [isOpened, setOpenState] = useClickOutside(colorPickerReference)
  const toggleShow = () => setOpenState(!isOpened)

  const changeColor = data => {
    emitChangeEvent(data.hex.toUpperCase())
  }

  const onBlur = event => emitBlurEvent(value, event)

  return (
    <div css={colorPickerContainer} ref={colorPickerReference} tabIndex='0' onBlur={onBlur} {...other}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              css={[colorPicker, isOpened && angleDown, hasError && error, (readOnly || disabled) && disabledStyle]}
              ref={ref}
              onClick={toggleShow}
            >
              <div css={colorPreview} style={{ backgroundColor: value }} />
              <span>{value}</span>
            </div>
          )}
        </Reference>
        {isOpened && (
          <Popover position='bottom'>
            <ChromePicker css={plugin} color={value} onChange={changeColor} disableAlpha />
          </Popover>
        )}
      </Manager>
    </div>
  )
}
