import { identity } from '@bonitour/common-functions'

export const BREAK_POINTS = {
  largeScreen: '2000px',
  hdDesktop: '1600px',
  desktop: '1280px',
  tablet: '1024px',
  smallTablet: '768px',
  tableUp: '592px',
  bigPhone: '480px',
  phone: '420px',
  smallPhone: '360px'
}

const breakpointsMediaQueries = {
  phone: 'min-width: 150px',
  tabletup: 'min-width:600px',
  tabletlandscape: 'min-width:900px',
  desktop: 'min-width:1200px',
  bigdesktop: 'min-width:1800px'
}

export const generateBreakpointWithContent = (contentFunction = identity) => (elementBreakpoints = {}) => {
  if (Object.keys(elementBreakpoints).length) {
    return Object.entries(breakpointsMediaQueries).reduce((style, [breakpointKey, breakpointQuery]) => {
      const breakpointRelatedValue = elementBreakpoints[breakpointKey]
      if (!breakpointRelatedValue && breakpointRelatedValue !== 0) {
        return style
      }
      return (style += `
        @media (${breakpointQuery}){
          ${contentFunction(breakpointRelatedValue)}
        }
        `)
    }, '')
  }
  return ''
}
