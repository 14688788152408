const zipCodeRegex = /^(\d{5})(\d{3})$/g
const zipCodeMask = '$1-$2'

/**
 * Formats a 8 length number into human readable Zip Code
 * @param { String } [zipCode=''] number of 8 digits length
 * @returns { String } formatted zip code
 */
export const formatZipCode = (zipCode = '') => zipCode.replace(zipCodeRegex, zipCodeMask)

/**
 * Masks given value to look like brazilian Zip Code, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format (\d{5})-(\d{3})
 */
export const maskZipCode = (value = '') =>
  formatZipCode(
    value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{1})/, '$1-$2')
      .replace(/(\d{2})/, '$1')
  )
