import { authenticatedService } from '@/interfaces/authenticatedService'
import { Integration, IntegrationLink } from '@/types/domains/Integration'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'

const integrationService = authenticatedService('v1/integrations')

const create = (Integration: Integration): Promise<any> => {
  return integrationService.post(Integration).then(extractData)
}

const list = (searchParams: Record<string, any>, pagination: MetaOnRequest): Promise<any> => {
  const params = { ...searchParams }

  return integrationService.get({
    ...cleanPayload(params),
    ...pagination
  }).then(extractData)
}

const get = (integrationId: string): Promise<Integration | any> => integrationService.get({}, integrationId).then(extractData)

const link = (integrationId: string, integrationLink: IntegrationLink) => {
  return integrationService.post(integrationLink, `${integrationId}/integration_services`).then(extractData)
}

const remove = (integrationId: string, integrationActivityId: string): Promise<any> => integrationService.remove({}, `${integrationId}/integration_services/${integrationActivityId}`)

const update = (integrationId: string, integration: Integration): Promise<any> => {
  return integrationService.patch(cleanPayload(integration), integrationId)
}

integrationService.setServiceMethods({ create, list, get, link, remove, update })

export const integrationDomain = integrationService.initialize
