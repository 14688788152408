import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  GetEventsProcessorsParams,
  GetEventsProcessorsResponse,
  CreateEventProcessorPayload,
  EventProcessorResponse,
  EditEventProcessorPayload,
  GetUniqueConditionsResponse,
  GetMatchedEventsResponse,
  GetAutomationRulesSimulationsResponse,
  AutomationRuleResponse,
  GetAutomationRulesResponse,
  AutomationRulePayload,
  GetAutomationRuleActionsResponse,
  GetAutomationRulesAccountsResponse,
  GetAutomationRulesAccountFormulasResponse
} from '@/types/domains/EventsProcessor'

const eventsProcessorService = authenticatedService('v1/events_processors')
const automationRulesCommonService = authenticatedService('v1/automation_rules_common')

const getEventsProcessors = (
  getEventsProcessorsParams: GetEventsProcessorsParams
): Promise<GetEventsProcessorsResponse> => {
  return eventsProcessorService.get(getEventsProcessorsParams).then(extractData)
}

const removeEventProcessor = (eventProcessorId: string): Promise<void> => {
  return eventsProcessorService.remove({}, eventProcessorId)
}

const createEventProcessor = (payload: CreateEventProcessorPayload): Promise<EventProcessorResponse> => {
  return eventsProcessorService.post(payload).then(extractData)
}

const getEventProcessor = (eventProcessorId: string): Promise<EventProcessorResponse> => {
  return eventsProcessorService.get({}, eventProcessorId).then(extractData)
}

const updateEventProcessor = (
  payload: EditEventProcessorPayload, eventProcessorId: string
): Promise<EventProcessorResponse> => {
  return eventsProcessorService.patch(payload, eventProcessorId)
}

const getUniqueConditions = (
  eventProcessorId: string
): Promise<GetUniqueConditionsResponse> => {
  return eventsProcessorService.get(
    {}, `${eventProcessorId}/unique_conditions`
  ).then(extractData)
}

const getMatchedEvents = (
  eventProcessorId: string
): Promise<GetMatchedEventsResponse> => {
  return eventsProcessorService.get(
    {}, `${eventProcessorId}/matched_events`
  ).then(extractData)
}

const getAutomationRulesSimulations = async (
  params: { event_id: string },
  eventProcessorId: string
): Promise<GetAutomationRulesSimulationsResponse> => {
  return await eventsProcessorService.get(
    params, `${eventProcessorId}/simulations`
  ).then(extractData)
}

const getAutomationRules = async (
  eventProcessorId: string
): Promise<GetAutomationRulesResponse> => {
  return await eventsProcessorService.get(
    {}, `${eventProcessorId}/automation_rules`
  ).then(extractData)
}

const getAutomationRule = async (
  eventProcessorId: string,
  automationRuleId: string
): Promise<AutomationRuleResponse> => {
  return await eventsProcessorService.get(
    {}, `${eventProcessorId}/automation_rules/${automationRuleId}`
  ).then(extractData)
}

const createAutomationRule = async (
  payload: AutomationRulePayload,
  eventProcessorId: string
): Promise<AutomationRuleResponse> => {
  return await eventsProcessorService.post(
    payload, `${eventProcessorId}/automation_rules`
  ).then(extractData)
}

const updateAutomationRule = async (
  payload: AutomationRulePayload,
  eventProcessorId: string,
  automationRuleId: string
): Promise<AutomationRuleResponse> => {
  return await eventsProcessorService.patch(
    payload, `${eventProcessorId}/automation_rules/${automationRuleId}`
  ).then(extractData)
}

const removeAutomationRule = async (
  eventProcessorId: string,
  automationRuleId: string
): Promise<void> => {
  return await eventsProcessorService.remove(
    {}, `${eventProcessorId}/automation_rules/${automationRuleId}`
  ).then(extractData)
}

const getAutomationRuleActions = async (
  params: { only_public_actions: boolean }
): Promise<GetAutomationRuleActionsResponse> => {
  return await automationRulesCommonService.get(
    params, 'actions'
  ).then(extractData)
}

const getAutomationRuleAccounts = async (): Promise<
  GetAutomationRulesAccountsResponse
> => {
  return await automationRulesCommonService.get(
    {}, 'accounts'
  ).then(extractData)
}

const getAutomationRuleAccountFormulas = async (): Promise<
  GetAutomationRulesAccountFormulasResponse
> => {
  return await automationRulesCommonService.get(
    {}, 'account_formulas'
  ).then(extractData)
}

automationRulesCommonService.setServiceMethods({
  getAutomationRuleActions,
  getAutomationRuleAccounts,
  getAutomationRuleAccountFormulas
})

export const automationRulesCommonDomain = automationRulesCommonService.initialize

eventsProcessorService.setServiceMethods({
  getEventsProcessors,
  removeEventProcessor,
  createEventProcessor,
  getEventProcessor,
  updateEventProcessor,
  getUniqueConditions,
  getMatchedEvents,
  getAutomationRulesSimulations,
  getAutomationRule,
  getAutomationRules,
  createAutomationRule,
  updateAutomationRule,
  removeAutomationRule
})

export const eventsProcessorDomain = eventsProcessorService.initialize
