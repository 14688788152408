import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { baseFont } from '../../assets/styles/styles'
import { flexRowCentered, flexHorizontallyCentered } from '../../assets/styles/layout'

export const container = css`
  ${baseFont}
  ${flexHorizontallyCentered}
  color: ${colors.gray5};
`

export const indexesContainer = css`
  ${flexRowCentered}
  min-height: 40px;
  padding: 0px 5px;
  margin-left: 10px;
  background-color: ${colors.gray11};
  border-radius: var(--round-element-radius, 20px);

  span {
    color: ${colors.gray3};
    margin: 0 8px;
    cursor: pointer;
    user-select: none;
  }
`

export const indexActive = css`
  ${flexRowCentered}
  width: 32px;
  height: 32px;
  margin: 0 !important;
  border-radius: var(--round-element-radius, 100%);;
  background-color: ${colors.white};
  box-shadow: 0px 0px 4px #00000014;
`

export const defaultCursor = css`
  cursor: initial !important;
`
