/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, { useCallback } from 'react'
import { colors } from '../../assets/styles/colors'
import { useToast } from '../ToastNotification/utils/useToast'
import { PlusIcon, CloseIcon } from '../Icons/Icons'
import { InputFormGroup } from '../Input/InputFormGroup'
import { MaskedInput } from '../Input/Input'
import { identity, normalizeString } from '@bonitour/common-functions'

const plusIcon = css`
  padding: 8px 10px;
  border-radius: var(--round-element-radius, 50%);
  background-color: ${colors.purple2};
  margin-right: 10px;
  color: white;
  cursor: pointer;
`

const keyWordsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
`

const inputGroupWithLimitStyles = css`
display: flex;
gap: 5px;

.keywords {
  top: 1193px;
  left: 90px;
  height: 36px;
  box-sizing: border-box;
  border-radius: var(--round-element-radius, 16px);
  box-shadow: 0px 3px 6px #00000029;
  color: ${colors.gray3};
  margin: 5px;
  padding: 5px 16px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 100%;
}

.title__keywords {
  font: normal normal normal 14px/18px Mulish;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closeIcon {
  padding-right: 5px;
  cursor: pointer;
  margin-left: -2px;
}

  .disabled {
    background-color: ${colors.disabled};
    color: ${colors.gray6};
    cursor: default;

    .closeIcon {
      cursor: default;
    }
  }
`

export function KeyWords ({
  setNewKeyWord,
  newKeyWord,
  setKeyWords,
  label,
  keyWords,
  mustNormalizeString = true,
  maskFn = identity,
  disabled = false
}) {
  const { add: addToast } = useToast()
  const [isVisibleKeyWordInput, setVisibleKeyWordInput] = React.useState(false)

  React.useEffect(() => {
    document.addEventListener('keydown', (e) => e.key === 'Escape' && setVisibleKeyWordInput(false))
  })

  const removeKeyWord = React.useCallback((value) => {
    if (disabled) return
    setKeyWords(keyWords.filter(item => item !== value))
  }, [disabled, keyWords, setKeyWords])

  const addKeyWord = (value) => {
    const keyWord = (mustNormalizeString ? normalizeString(value) : value).trim()

    if (keyWord === '') {
      setNewKeyWord('')
      setVisibleKeyWordInput(v => !v)
    } else if (keyWords.includes(keyWord)) {
      setNewKeyWord('')
      setVisibleKeyWordInput(v => !v)
      addToast('Esta palavra já está sendo usada')
    } else {
      setVisibleKeyWordInput(v => !v)
      setKeyWords((v) => v.concat(keyWord))
      setNewKeyWord('')
    }
  }
  const keyWordsGroup = React.useMemo(() =>
    keyWords.map(value =>
      <div key={value} className={`keywords ${disabled ? 'disabled' : ''}`}>
        <CloseIcon
          className='closeIcon'
          onClick={() => removeKeyWord(value)}
        />
        <span className='title__keywords'>{value}</span>
      </div>
    ), [keyWords, disabled, removeKeyWord])

  const handleVisibleKeyWordInput = useCallback(
    () => {
      if (disabled) return
      setVisibleKeyWordInput(v => !v)
    }
    , [disabled])

  return (
    <InputFormGroup label={label} css={inputGroupWithLimitStyles}>
      <div css={keyWordsContainer} className='container__keyWord'>
        {keyWordsGroup}
        {isVisibleKeyWordInput &&
          <MaskedInput
            className='keywords'
            value={newKeyWord}
            onChange={(v) => setNewKeyWord(v)}
            onBlur={addKeyWord}
            onKeyPress={(e) => e.charCode === 13 ? addKeyWord(newKeyWord) : e.charCode === 27 && setVisibleKeyWordInput(false)}
            formatterFunction={maskFn}
            disabled={disabled}
          />}
        <PlusIcon
          css={plusIcon}
          className={disabled ? 'disabled' : ''}
          onClick={handleVisibleKeyWordInput}
        />
      </div>
    </InputFormGroup>
  )
}
