import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'

const providerService = authenticatedService('/v1/companies')

providerService.setServiceMethods({
  getPaginatedList (companyId: string, perPage: string, page: string): Promise<any> {
    return providerService.get({ per_page: perPage, page }, `${companyId}/providers/`).then(extractData)
  },
  getPayProviders (companyId: string): Promise<any> {
    return providerService.get({}, `${companyId}/pay_providers`).then(extractData)
  },
  getProvidersByName (companyId: string, perPage: string, page: string, toSearch: string): Promise<any> {
    return providerService.get({ per_page: perPage, page, company_name: toSearch }, `${companyId}/providers`).then(extractData)
  }
})

export const providerDomain = providerService.initialize
