/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Label } from '../Label/Label'
import { clickable } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { reset } from '../../assets/styles/input'

const style = css`
  outline: none;
  position: relative;
  width: 36px;
  height: 20px;
  margin-right: 8px;
  background-color: ${colors.gray5};
  transition: 0.4s;
  border-radius: var(--small-round-element-radius, 12px);

  &:checked {
    background-color: ${colors.secondary};

    &:before {
      transform: translateX(16px);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: ${colors.white};
    transition: 0.4s;
    border-radius: calc(var(--small-round-element-radius, 50%) - 2px);
  }
`

export const Toggle = props => <input type='checkbox' css={[style, reset, clickable]} {...props} />

const setFirst = css`
  order: -1;
`

const toggleLabel = css`
  color: ${colors.gray3};
  font-weight: initial;
  user-select: none;
`

export const ToggleInputGroup = ({ id, children, disabled: isDisabled = false, customCss = [], customToggleCss = [], customLabelCss = [], ...other }) => (
  <div css={[flexHorizontallyCentered, ...customCss]}>
    <Label css={[toggleLabel, isDisabled || clickable, ...customLabelCss]} htmlFor={id}>
      {children}
    </Label>
    <Toggle css={[setFirst, ...customToggleCss]} id={id} disabled={isDisabled} {...other} />
  </div>
)
