import { css, keyframes } from '@emotion/react'
import { colors } from '../../assets/styles/colors'

const gradientAnimation = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export const loadingTableContainer = css`
  padding: 30px;
  thead {
    height: 90px;

    tr {
      th {
        width: 5%;
      }
    }
  }
  :before {
    content: '@';
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  span {
    content: ' ';
    overflow: hidden;
    position: relative;
    height: 45px;
    width: 100%;
    border-radius: 10px;
    margin: 5px 0 5px 0;
    background-color: ${colors.white};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: translateX(-100%);
      animation: ${gradientAnimation} 2s infinite;
      content: '';
      background-image: linear-gradient(
        90deg,
        rgba(240, 240, 240, 0) 0,
        rgba(240, 240, 240, 0.25) 15%,
        rgba(240, 240, 240, 0.75) 80%,
        rgba(240, 240, 240, 0)
      );
    }

    &:nth-child(2n) {
      background-color: ${colors.gray12};
      &::after {
        background: linear-gradient(
          90deg,
          ${colors.gray12} 0%,
          rgba(255, 255, 255, 1) 29%,
          rgba(255, 255, 255, 1) 62%,
          ${colors.gray12} 100%
        );
      }
    }
  }
`
