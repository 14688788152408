/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { scrollBar } from '../../assets/styles/addons'
import { colors } from '../../assets/styles/colors'
import { BREAK_POINTS } from '../Layout'
import { useMemo } from 'react'

const pills = css`
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${colors.gray8};
  background-color: ${colors.white};
  overflow-x: auto;
  ${scrollBar(0)};

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    display: flex;
  }
`

const pill = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 80px;
  padding: 10px 30px;
  color: ${colors.gray3};
  background-color: ${colors.white};
  cursor: pointer;

  svg {
    font-size: 18px;
    margin-right: 10px;

    @media (max-width: ${BREAK_POINTS.bigPhone}){
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    flex-direction: column;
  }
`

const pillActive = css`
  color: ${colors.white};
  background-color: ${colors.primary};
`

const pillLabel = css`
  font-size: 14px;
`

export const Pills = ({ customCss = [], children, ...others }) => {
  return (
    <div css={[pills, ...customCss]} {...others}>
      {children}
    </div>
  )
}

export const Pill = ({ label = '', icon: Icon = null, active = false, onClick: emitClick = identity, customActiveCss, customCss = [] }) => {
  const iconIsFunction = useMemo(() => typeof Icon === 'function', [Icon])
  return (
    <div css={[pill, active && (customActiveCss || pillActive), ...customCss]} onClick={emitClick}>
      {Icon && !iconIsFunction && Icon}
      {Icon && iconIsFunction && <Icon />}
      <span css={pillLabel}>{label}</span>
    </div>
  )
}
