/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity, head } from '@bonitour/common-functions'
import { useState, useRef } from 'react'
import { dropzone, dropzoneContent, dropzoneError, dropzoneFileDragging, dropzoneDisabled } from './UploadDropzone.style'
import { isFileTypeValid, isFileSizeValid } from './UploadDropzone.utils'

export function UploadDropzone ({
  multiple = false,
  disabled = false,
  error = false,
  limitMbSize,
  accept,
  children,
  onChange: emitChangeEvent = identity,
  onError: emitErrorEvent = identity,
  ...other
}) {
  const [fileDragged, setFileDragged] = useState(false)
  const dropzoneReference = useRef()

  function validateAndEmitFiles (files) {
    const fileArray = Array.isArray(files) ? files : Array.from(files)
    if (!isFileTypeValid(accept, fileArray)) {
      emitErrorEvent('Formato inválido')
    } else if (!isFileSizeValid(limitMbSize, fileArray)) {
      emitErrorEvent('Arquivo está acima do limite de tamanho')
    } else {
      emitChangeEvent(Array.from(fileArray))
    }
  }

  const handleInputFilesUpload = () => validateAndEmitFiles(dropzoneReference.current.files)

  const activateFileInput = event => {
    event.stopPropagation()
    if (disabled) return
    dropzoneReference.current.click()
  }

  const activateFileDrag = event => {
    event.stopPropagation()
    event.preventDefault()
    setFileDragged(true)
  }

  const deactivateFileDrag = () => {
    setFileDragged(false)
  }

  const onFileDrop = event => {
    event.stopPropagation()
    event.preventDefault()
    if (disabled) return
    const files = event.dataTransfer.files
    const filteredFiles = multiple
      ? Array.from(files)
      : [head(files)]
        .filter(file => file instanceof File)
    if (validateAndEmitFiles(filteredFiles)) {
      emitChangeEvent(filteredFiles)
    }
    deactivateFileDrag()
  }

  return (
    <div
      css={[dropzone, fileDragged && dropzoneFileDragging, disabled && dropzoneDisabled, error && dropzoneError]}
      {...other}
      onClick={activateFileInput}
      onKeyPress={activateFileInput}
      onDragOver={activateFileDrag}
      onDragLeave={deactivateFileDrag}
      onDrop={onFileDrop}
    >
      <input
        ref={dropzoneReference}
        type='file'
        onChange={handleInputFilesUpload}
        multiple={multiple}
        accept={accept}
      />
      <div css={dropzoneContent}>{children}</div>
    </div>
  )
}
