import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { PickUpDropOffCreate, PickUpDropOffCreationResponse, PickUpDropOffResponse, PickUpDropOffUpdate } from '@/types/domains/PickupAndDropOff'

const pickUpAndDropOffService = authenticatedService('v1/reservations')
const pickUpAndDropOffMoovService = authenticatedService('v1/pick_up_drop_offs')

const get = (reservationId: string): Promise<PickUpDropOffResponse> => pickUpAndDropOffService.get({}, `${reservationId}/pick_up_drop_off`).then(extractData)

const remove = (pickupDropoffId: string): Promise<void> => pickUpAndDropOffMoovService.remove({}, `${pickupDropoffId}`)

const update = (pickupDropoffId: string, pickUpDropOff: PickUpDropOffUpdate): Promise<void> => {
  return pickUpAndDropOffMoovService.patch(pickUpDropOff, `${pickupDropoffId}/bind_pick_up_drop_off_address`)
    .then(extractData)
}

const create = (reservationId: string, pickUpDropOff: PickUpDropOffCreate): Promise<PickUpDropOffCreationResponse> => {
  return pickUpAndDropOffService.patch(pickUpDropOff, `${reservationId}/pick_up_drop_off`)
    .then(extractData)
}

pickUpAndDropOffService.setServiceMethods({ get, create })
pickUpAndDropOffMoovService.setServiceMethods({ remove, update })

export const pickUpAndDropOffDomain = pickUpAndDropOffService.initialize
export const pickUpAndDropOffMoovDomain = pickUpAndDropOffMoovService.initialize
