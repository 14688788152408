import { css } from '@emotion/core'
import { colors } from './colors'

export const styles = {
  default: {
    textColor: colors.gray3
  },
  disabled: {
    textColor: colors.white,
    background: colors.gray14
  }
}

export const reset = css`
  appearance: none;
  outline: 0;

  &:active {
    outline: 0;
    box-shadow: none;
  }
`

export const resetNativeInput = css`
  font-family: inherit;
  appearance: none;
  user-select: none;
  border: none;

  &:active {
    outline: 0;
    box-shadow: none;
  }
`

export const resetColors = css`
  background-color: transparent;
  border-color: ${styles.default.textColor};
  color: ${styles.default.textColor};
`

export const defaultBorder = css`
  border: 1px solid ${styles.default.textColor};
`
