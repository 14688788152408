import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { PaymentMethodListResponse, PaymentMethodListSearchParams } from '@/types/domains/PaymentMethod'

const paymentMethodService = authenticatedService('v1/payment_methods')

type ListParams = { searchParams: PaymentMethodListSearchParams }

const list = ({ searchParams }: ListParams): Promise<PaymentMethodListResponse> => {
  return paymentMethodService.get({
    ...cleanPayload(searchParams)
  }).then(extractData)
}

paymentMethodService.setServiceMethods({ list })

export const paymentMethodDomain = paymentMethodService.initialize
