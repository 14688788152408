/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Thumbnail } from '../Thumbnails/Thumbnails'
import { ResponsiveImage } from './Image'

export const Logo = ({ src = '', ...props }) => (
  <Thumbnail {...props}>
    <ResponsiveImage src={src} alt='Logo da Bonitour' />
  </Thumbnail>
)
