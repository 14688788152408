/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { flexCenteredSpaceBetweenRow, flexColumnCentered } from '../../assets/styles/layout'
import { Button, GhostButton } from '../Buttons/Buttons'
import { Dialog } from '../Dialog/Dialog'
import { Input } from '../Input/Input'
import { InputFormGroup } from '../Input/InputFormGroup'
import { contentStyle, marginRight20 } from './PromptDialog.style'

export const PromptDialog = ({
  title = '',
  buttonLabelConfirm = 'Confirmar',
  buttonLabelCancel = 'Cancelar',
  isVisible = false,
  successCallback = identity,
  cancelCallback = identity,
  className = 'confirmDialog',
  inputLabel = '',
  inputErrorMessage,
  inputComponent: InputComponent = Input,
  inputProps = {},
  children,
  ...other
}) => {
  const [inputValue, setInputValue] = useState(inputProps.defaultValue || '')
  const handleConfirmation = () => {
    successCallback(inputValue)
    setInputValue('')
  }

  const handleCancelation = () => {
    cancelCallback()
    setInputValue('')
  }

  return (
    <Dialog isVisible={isVisible} onClose={handleCancelation} title={title} className={className} {...other}>
      <div css={flexColumnCentered}>
        <div css={contentStyle} {...other}>
          {children}
          <InputFormGroup id='dialog-input' label={inputLabel} errorMessage={inputErrorMessage}>
            <InputComponent value={inputValue} onChange={setInputValue} {...inputProps} />
          </InputFormGroup>
        </div>

        <div css={flexCenteredSpaceBetweenRow}>
          <GhostButton css={marginRight20} onClick={handleCancelation}>{buttonLabelCancel}</GhostButton>
          <Button onClick={handleConfirmation}>{buttonLabelConfirm}</Button>
        </div>
      </div>
    </Dialog>
  )
}

PromptDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  successCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  buttonLabelConfirm: PropTypes.string,
  buttonLabelCancel: PropTypes.string,
  inputLabel: PropTypes.string,
  inputErrorMessage: PropTypes.string,
  inputComponent: PropTypes.any,
  inputProps: PropTypes.any
}
