import { authenticatedService, RequestError } from '@/interfaces/authenticatedService'
import { decodeErrors } from '@/utils/error-decoder'
import { extractInnerData, extractData } from '@/utils/data-extractor'
import { parseCrudError } from '@/utils/parse-error'
import { formatDate } from '@bonitour/common-functions'
import { VacancyModel, ConflictObject } from '@/types/domains/Vacancies'
import { ErrorReport, EntityPronoun } from '@/types/domains/Error'

function getConflictingPeriods (conflicts: Array<ConflictObject> | Record<string, any>) {
  if (Array.isArray(conflicts)) {
    return conflicts
  } else {
    return Object.entries(conflicts).map(period => period[1].conflicts[0])
  }
}

function decodeError (error: RequestError<ErrorReport>) {
  const { data } = error
  const errorMessages = [
    'vacancy::period::conflited_date_range',
    'uc_create_vacancy_and_its_periods::period::conflited_date_range',
    'calendar::period::conflited_date_range'
  ]

  const isDateRangeConflictError = errorMessages.some(errorMsg =>
    data?.errors_msg?.includes(errorMsg)
  )

  if (isDateRangeConflictError) {
    const conflictingPeriods = getConflictingPeriods(data?.extra_data?.conflicting_periods || [])
      .map(({ label, initial_date, final_date }) =>
        `${label} (${formatDate(initial_date)} - ${formatDate(final_date)})`)
      .reduce((acc, curr) => `${acc}, ${curr}`)

    return Promise.reject(`Período conflitante com: ${conflictingPeriods}`)
  }

  const isPastDateErrorError = /can't create in the past/.test(Object.values(
    data?.extra_data as Record<string, string[]>
  ).flat().join()) || ''
  if (isPastDateErrorError) {
    return Promise.reject('Data inicial não pode ser no passado. Favor alterar as datas dos períodos.')
  }

  const errorLabel = parseCrudError(error, 'vaga', EntityPronoun.f)

  return errorLabel
}

const vacancyService = authenticatedService('/v1/vacancies/')

const get = (serviceId: string) => vacancyService.get({ service_id: serviceId }).then(extractInnerData)

const getById = (vacancyId: string) => vacancyService.get({}, vacancyId).then(extractInnerData)

const remove = (serviceId: string, vacancyId: string) =>
  vacancyService.remove({ service_id: serviceId }, vacancyId)
    .catch((requestError: RequestError<ErrorReport>) => decodeError(requestError))

function create (vacancy: VacancyModel) {
  return vacancyService.post(vacancy)
    .then(extractData)
    .catch((requestError: RequestError<ErrorReport>) => decodeError(requestError))
}

function update (vacancy: VacancyModel, vacancyId: string) {
  return vacancyService.patch(vacancy, vacancyId)
    .then(extractData)
    .catch((requestError: RequestError<ErrorReport>) => decodeError(requestError))
}

vacancyService.setServiceMethods({ get, getById, create, update, decodeErrors, remove })

export const vacancyDomain = vacancyService.initialize
