/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PlusIcon } from '../Icons/Icons.js'
import { ResetButton } from '../Buttons/Buttons.js'
import { CircleThumbnail } from '../Thumbnails/Thumbnails.js'
import { colors } from '../../assets/styles/colors.js'

const wrapper = css`
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.primaryVariant};
  outline: none;
`

const circle = css`
  background-color: ${colors.primaryVariant};
  color: ${colors.white};
`

const label = css`
  margin-left: 10px;
  text-align: left;
`

export const AddLabel = ({ children, ...other }) => (
  <ResetButton css={wrapper} {...other}>
    <CircleThumbnail css={circle} size={30}>
      <PlusIcon />
    </CircleThumbnail>
    <span css={label}>{children}</span>
  </ResetButton>
)
