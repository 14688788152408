/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { resetColors } from '../../assets/styles/input'
import { baseFont, clickable, roundedEdges, disabled } from '../../assets/styles/styles'
import { resetButton } from '../../assets/styles/button'
import { base, ghost, disabledGhost, circle, shadow, groupedFabContainer, ghostPrimary } from './Button.style'

export const ResetButton = ({ customCss = [], ...other }) => (
  <button css={[resetButton, clickable, disabled, ...customCss]} {...other} />
)

export const Button = ({ css = undefined, customCss = [], ...other }) => (
  <ResetButton css={[base, baseFont, roundedEdges, css, ...customCss]} {...other} />
)

export const GhostButton = ({ css = undefined, customCss = [], ...props }) => (
  <Button {...props} css={[resetColors, ghost, disabledGhost, css, ...customCss]} />
)

export const GhostPrimaryButton = ({ customCss = [], ...props }) => (
  <Button {...props} css={[resetColors, ghostPrimary, disabledGhost, ...customCss]} />
)

export const CircleButton = ({ size, customCss = [], ...other }) => (
  <Button {...other} css={[circle(size), ...customCss]} />
)

export const AsyncButton = ({ customCss = [], ...props }) => (
  <Button {...props} css={[...customCss]} />
)

export const GhostCircleButton = ({ size, customCss = [], ...other }) => (
  <GhostButton {...other} css={[circle(size), ...customCss]} />
)

export const Fab = ({ customCss = [], ...props }) => (
  <CircleButton {...props} css={[shadow, ...customCss]} size={60} />
)

export const GroupedFab = ({
  containerCss,
  children,
  BaseIcon = () => null,
  HoverIcon = () => null,
  ...other
}) => (
  <div css={[groupedFabContainer, containerCss]}>
    {children}
    <Fab {...other} className='fab-button'>
      <BaseIcon className='fab-button__base-icon' />
      <HoverIcon className='fab-button__hover-icon' />
    </Fab>
  </div>
)
