/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { roundedEdges, disabled } from '../../assets/styles/styles'
import { flexRowCentered } from '../../assets/styles/layout'
import { removeVerticalSpacing, inputAddon, minWidthToBuildBorder, overwritePadding, errorInput, disabled as inputDisabled } from './Input.style'

const overwriteStyleByPosition = {
  left: [removeVerticalSpacing('right'), overwritePadding],
  right: [removeVerticalSpacing('left'), overwritePadding]
}

const inputAddonFactory = (position = 'right') => {
  const overwriteStyle = overwriteStyleByPosition[position] || []
  return function Addon ({ disabled: isDisabled = false, error: hasError = false, ...other }) {
    return (
      <div
        {...other}
        css={[
          roundedEdges,
          flexRowCentered,
          inputAddon,
          ...overwriteStyle,
          isDisabled && disabled,
          isDisabled && inputDisabled,
          hasError && errorInput,
          minWidthToBuildBorder
        ]}
      />
    )
  }
}

export const Suffix = inputAddonFactory('right')
export const Prefix = inputAddonFactory('left')
