/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { generateBreakpointWithContent } from '../Layout.style'

const columnByBreakpoint = generateBreakpointWithContent(breakpointSize => {
  if (breakpointSize === 0) {
    return `
      width: 0;
      max-width: 0;
    `
  }
  const sizePercentage = (100.0 * breakpointSize) / 12
  return `
    flex-basis: ${sizePercentage}%;
    max-width: ${sizePercentage}%;
  `
})

const column = props => {
  return css`
    box-sizing: border-box;
    flex: 0 0 auto;
    padding: 0 10px 0 10px;
    flex-basis: 100%;
    max-width: 100%;
    align-items: stretch;
    display: flex;
    ${columnByBreakpoint(props)}
  `
}

export const Column = ({ css = undefined, customCss = [], ...props }) => (
  <div css={[column(props), css, ...customCss]} {...props} />
)
