import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { ExternalProvider, ExternalProviderRes } from '@/types/domains/ExternalProvider'

const externalProviderService = authenticatedService('v1/offline_providers')

const list = (searchParams: Record<string, any>, pagination: MetaOnRequest): Promise<any> => {
  const params = { ...searchParams }

  return externalProviderService.get({
    ...cleanPayload(params),
    ...pagination
  }).then(extractData)
}

const get = (externalProviderId: string): Promise<ExternalProviderRes | any> => externalProviderService.get({}, externalProviderId).then(extractData)

const create = (externalProvider: ExternalProvider): Promise<ExternalProviderRes | any> => {
  return externalProviderService.post(externalProvider).then(extractData)
}

const update = (externalProvider: ExternalProvider, externalProviderId: string): Promise<ExternalProviderRes | any> => {
  return externalProviderService.patch(cleanPayload(externalProvider), externalProviderId)
}

externalProviderService.setServiceMethods({ list, get, create, update })

export const externalProviderDomain = externalProviderService.initialize
