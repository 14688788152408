/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { hidden } from '../Select/Select.style'
import PropTypes from 'prop-types'
import { baseExportToggle, cardTitle, collapsableIcon, collapsableIconOpen, exportAllButtonStyle, exportAllIconStyle, exportButtonStyle, exportIconStyle, exportStyle, exportText, link, multiSelectCardStyle, multiSelectStyle, scheduleSelect, selectStyle, multiSelectInput, width200 } from './ColumnsToExport.style'
import { marginTop } from '../../assets/styles/global'
import { Select } from '../Select/Select'
import { identity } from '@bonitour/common-functions'
import { H2, P } from '../Headings/Headings'
import { InputFormGroup } from '../Input/InputFormGroup'
import { InputWithSuffix } from '../Input/Input'
import { AngleRightIcon, FileExportIcon } from '../Icons/Icons'
import { Card } from '../Card/Card'
import { MultiSelect } from '../MultiSelect/MultiSelect'
import { Button } from '../Buttons/Buttons'
import { Column, Row } from '../Layout'

const marginTop20 = marginTop(20)

export const ColumnsToExport = ({
  reference,
  wantedFields = '',
  format = '',
  errors = {},
  isMultiSelectOpen = false,
  multiSelectOptions = [],
  formatOptions = [],
  isChecked = false,
  areAllSelected = false,
  loading = false,
  toggleMultiSelect = identity,
  onInputChange = identity,
  onOptionChange = identity,
  allScheduleChange = identity,
  onClick = identity,
  onExportAll = identity,
  ...others
}) => {
  return (
    <div css={exportStyle} {...others}>
      <P css={[exportText, marginTop20]}>Configure o documento selecionando as colunas que deseja exportar no formato desejado. Caso tenha alguma dúvida, acesse <a css={link} href='https://ajuda.binamik.com.br/uncategorized/como-exportar-informacoes-em-csv/' target='blank' rel='noreferrer noopener'>aqui</a> a FAQ</P>
      <P css={exportText}>O documento será exportado no formato desejado, para uso em Excel.</P>
      <div css={scheduleSelect} ref={reference}>
        <>
          <Row style={{ margin: '0' }}>
            <Column phone={6} desktop={6} style={{ paddingLeft: '0' }}>
              <InputFormGroup label='Formato' errorMessage={errors.format} css={width200}>
                <Select
                  placeholder='Todos'
                  options={formatOptions}
                  value={format}
                  error={errors.format}
                  onChange={onInputChange('format')}
                />
              </InputFormGroup>
            </Column>
            <Column phone={6} desktop={6} style={{ paddingRight: '0' }}>
              <InputFormGroup label='Colunas' errorMessage={errors.wantedFields} onClick={(toggleMultiSelect)} css={multiSelectStyle}>
                <InputWithSuffix
                  inputCss={multiSelectInput}
                  suffixCss={multiSelectInput}
                  placeholder='Seleção de colunas'
                  value={wantedFields}
                  disabled
                >
                  <AngleRightIcon css={isMultiSelectOpen ? collapsableIconOpen : collapsableIcon} />
                </InputWithSuffix>
              </InputFormGroup>
            </Column>
          </Row>
          <Card customCss={[multiSelectCardStyle, !isMultiSelectOpen && hidden]}>
            <H2 css={cardTitle}>Colunas da tabela</H2>
            <MultiSelect
              options={multiSelectOptions.map(option => option.label)}
              isChecked={isChecked}
              onOptionChange={onOptionChange}
              onAllOptionsChange={allScheduleChange}
              allSelected={areAllSelected}
              css={selectStyle}
            />
            <Button
              onClick={onClick} css={[exportButtonStyle, baseExportToggle]} disabled={loading}
            >
              <FileExportIcon css={exportIconStyle} />
              Exportar apenas colunas selecionadas
            </Button>
          </Card>
        </>
      </div>
      <Button
        disabled={loading}
        onClick={onExportAll} css={[exportAllButtonStyle, baseExportToggle]}
      >
        <FileExportIcon css={exportAllIconStyle} />
        Exportar tabela completa
      </Button>
    </div>
  )
}

ColumnsToExport.propTypes = {
  toggleMultiSelect: PropTypes.func,
  wantedFields: PropTypes.array,
  format: PropTypes.string,
  errors: PropTypes.string,
  isMultiSelectOpen: PropTypes.bool,
  multiSelectOptions: PropTypes.array,
  isChecked: PropTypes.bool,
  onOptionChange: PropTypes.func,
  onInputChange: PropTypes.func,
  allScheduleChange: PropTypes.func,
  areAllSelected: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onExportAll: PropTypes.func
}
