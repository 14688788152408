/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card } from '../Card/Card'
import { CollapsableSectionHeader } from './CollapsableSectionHeader/CollapsableSectionHeader'
import { CollapsableSectionBody } from './CollapsableSectionBody/CollapsableSectionBody'
import { identity } from '@bonitour/common-functions'
import { useState, useEffect } from 'react'

const wrapper = css`
  width: 100%;
  margin: 15px 0 15px 0;
  padding: 0;
`

export const CollapsableSection = ({
  indicator = 1,
  title = '',
  topics = '',
  tooltip = '',
  isExpanded = false,
  isCompleted = false,
  onClick: emitCollapseEvent = identity,
  children,
  ...other
}) => {
  const [shouldRender, setRenderState] = useState(false)

  useEffect(() => {
    if (!shouldRender && isExpanded) {
      setRenderState(true)
    }
  }, [shouldRender, isExpanded])

  return (
    <Card css={wrapper} {...other}>
      <CollapsableSectionHeader
        indicator={indicator}
        title={title}
        topics={topics}
        tooltip={tooltip}
        isExpanded={isExpanded}
        isCompleted={isCompleted}
        onClick={(...args) => emitCollapseEvent(isExpanded === true ? 0 : indicator, ...args)}
      />
      {shouldRender && <CollapsableSectionBody isExpanded={isExpanded}>{children}</CollapsableSectionBody>}
    </Card>
  )
}
