/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../../../assets/styles/colors'
import { flexColumn } from '../../../../assets/styles/layout'

const sidebarSection = css`
  ${flexColumn}
  min-width: 230px;
  box-sizing: border-box;
  border-right: 1px solid ${colors.gray8};
  padding: 15px;
`

export const SidebarSection = props => (<section {...props} css={sidebarSection} />)
