import { Company, CompanyListResponse, PartnerPayload, UserCompaniesListResponse } from '@/types/domains/Company'
import { extractData } from '@/utils/data-extractor'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { convertToFormData } from '@/utils/form-data'

const companyService = authenticatedService('/v1/companies')

companyService.setServiceMethods({
  get (companyId: string): Promise<Company> {
    return companyService.get({}, `${companyId}`)
      .then(extractData)
      .then(({ company }: { company: Company }) => company)
  },
  list ({ pagination }: { pagination: MetaOnRequest }): Promise<CompanyListResponse> {
    return companyService.get(pagination)
      .then(extractData)
  },
  listUserCompanies (active?: boolean): Promise<UserCompaniesListResponse> {
    return companyService.get({ active }, 'user_companies')
      .then(extractData)
  },
  partners (companyId: string): Promise<PartnerPayload> {
    return companyService.get({}, `${companyId}/partners`).then(extractData)
  },
  create (payload: Company) {
    return companyService.postFormData(convertToFormData(payload)).then(extractData)
  },
  update (companyId: string, payload: Company) {
    const hasImage = Boolean(payload.image)
    if (hasImage) return companyService.patchFormData(convertToFormData(payload), companyId)
    return companyService.patch(payload, companyId)
  }
})

export const companyDomain = companyService.initialize
