/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card } from '../../Card/Card.js'
import { flexRowCentered, flexHorizontallyCentered } from '../../../assets/styles/layout.js'

import { TrashIcon } from '../../Icons/Icons.js'
import { MaskedInputWithPrefix } from '../../Input/Input.js'
import { CircleThumbnail } from '../../Thumbnails/Thumbnails.js'
import { maskPhone, identity } from '@bonitour/common-functions'
import {
  clickable,
  width5,
  width95,
  iconSize,
  containedInput,
  sameLineInput,
  alignAtEnd,
  customCardPadding,
  overwriteSizes,
  stylishCard
} from './ContactPhone.style'

export const ContactPhone = ({
  errors = [],
  onChange: emitChangeEvent = identity,
  onDelete: emitDeleteEvent = identity,
  onBlur: emitBlurEvent = identity,
  contact,
  index,
  flag
}) => {
  const { number = '' } = contact

  return (
    <Card css={[customCardPadding, stylishCard]}>
      <div css={flexRowCentered}>
        <div css={[flexHorizontallyCentered, width95]}>
          <p css={sameLineInput}>O telefone é</p>
          <div css={sameLineInput}>
            <MaskedInputWithPrefix
              css={containedInput}
              value={number}
              error={Boolean(errors.number)}
              onBlur={emitBlurEvent(`phones.${index}.number`)}
              onChange={emitChangeEvent(`phones.${index}.number`)}
              formatterFunction={maskPhone}
              placeholder='(00) 00000-0000'
            >
              <CircleThumbnail image={flag} css={overwriteSizes} />
            </MaskedInputWithPrefix>
          </div>
        </div>
        <div css={[width5, alignAtEnd, flexHorizontallyCentered]}>
          <TrashIcon css={[iconSize, clickable]} onClick={emitDeleteEvent} />
        </div>
      </div>
    </Card>
  )
}
