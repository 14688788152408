import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { PaymentResponse } from '@/types/domains/Financial'

const reservationTotalizersService = authenticatedService('/v1/reservations')

reservationTotalizersService.setServiceMethods({
  getFinancialTransactionsTotalizers (reservationId: string): Promise<PaymentResponse> {
    return reservationTotalizersService.get({}, `${reservationId}/financial_transactions_totalizers`).then(extractData)
  }
})

export const reservationTotalizersDomain = reservationTotalizersService.initialize
