import React, { useCallback, useMemo } from 'react'

import { Select } from '../Select/Select'

import { PageSelector } from './PageSelector'
import {
  PageSelectorContainer,
  PaginationBarContainer as Container,
  PerPageSelectorContainer
} from './PaginationBar.styles'

export const PaginationBar = ({
  onPageChange,
  onPerPageChange,
  totalElements,
  currentPage,
  totalPages,
  perPage,
  perPageOptions = [5, 10, 25, 40],
  customCss = []
}) => {
  const perPageSizeOptions = useMemo(() => perPageOptions.map((perPage) => ({
    value: `${perPage}`,
    label: `${perPage}`,
    numValue: perPage
  })), [perPageOptions])

  const perPageSelectableOptions = useMemo(() => {
    const filteredOptions = perPageSizeOptions.filter(
      (option, idx, arr) =>
        option.numValue < totalElements ||
        arr[idx - 1]?.numValue < totalElements
    )

    return filteredOptions.length ? filteredOptions : [perPageSizeOptions[0]]
  }, [perPageSizeOptions, totalElements])

  const handlePerPageChange = useCallback(
    (newPerPage) => {
      const newPerPageNum = parseInt(newPerPage, 10)
      onPerPageChange(newPerPageNum || perPageSizeOptions[0].numValue)
    },
    [onPerPageChange, perPageSizeOptions]
  )

  return (
    <Container css={customCss}>
      <PerPageSelectorContainer>
        {totalElements > perPageSizeOptions[0].numValue
          ? (
            <>
              <span className="selector__text">Mostrar</span>
              <Select
                name="perPageSelector"
                className="per_page__selector"
                value={`${perPage}`}
                options={perPageSelectableOptions}
                onChange={handlePerPageChange}
                isOpenDrawer
              />
              <span className="selector__text">de {totalElements} itens</span>
            </>
          )
          : (
            <span className="selector__text">
            Mostrar {totalElements} de {totalElements} itens
            </span>
          )}
      </PerPageSelectorContainer>
      <PageSelectorContainer
        className={totalPages <= 1 ? 'hide_selection' : ''}
      >
        <span className="page_selector__label">Página</span>
        <div className="page_selectors__btns_container">
          <PageSelector
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      </PageSelectorContainer>
    </Container>
  )
}
