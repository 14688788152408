/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const backdrop = css`
  content: '';
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  background: ${colors.black};
  opacity: 0.3;
  z-index: 5;
`

export const Backdrop = props => <div {...props} css={backdrop} />
