import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  CreateDigitalAccountPayload
} from '@/types/domains/DigitalAccount'

const digitalAccountService = authenticatedService('v1/pay')

const create = (payload: CreateDigitalAccountPayload): Promise<any> => {
  return digitalAccountService.post(payload, 'digital-accounts').then(extractData)
}

digitalAccountService.setServiceMethods({
  create
})

export const digitalAccountDomain = digitalAccountService.initialize
