import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { DigitalAccountSearchParams, DigitalAccountListResponse } from '@/types/domains/DigitalAccount'

const currentDigitalAccountService = authenticatedService('/digital-accounts/search')

type ListParams = { searchParams: DigitalAccountSearchParams }

const list = ({ searchParams }: ListParams): Promise<DigitalAccountListResponse> => {
  return currentDigitalAccountService.get({
    ...cleanPayload(searchParams)
  }).then(extractData)
}

currentDigitalAccountService.setServiceMethods({ list })

export const currentDigitalAccountDomain = currentDigitalAccountService.initialize
