/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Portal } from '../Portal/Portal'
import { Backdrop } from '../Backdrop/Backdrop'
import { H1 } from '../Headings/Headings'
import { CloseIcon } from '../Icons/Icons'
import { flexCenteredSpaceBetweenRow } from '../../assets/styles/layout'
import { scrollBar } from '../../assets/styles/addons'
import { CSSTransition } from 'react-transition-group'
import { noMargin, backdrop, container, content, header, closeIcon, transition } from './Modal.style'
import { useEffect, useMemo } from 'react'

/**
 * @typedef {import('@emotion/react').SerializedStyles} SerializedStyles
 */

/**
 * @typedef ModalProps
 * @property { SerializedStyles | SerializedStyles[] } [customCss]
 * @property { SerializedStyles | SerializedStyles[] } [customHeaderCss]
 * @property { SerializedStyles | SerializedStyles[] } [customCloseIconCss]
 * @property { SerializedStyles | SerializedStyles[] } [customTitleCss]
 * @property { string } [maxHeight]
 * @property { string } [maxWidth]
 * @property { () => void } onCloseClick
 * @property { boolean } [isVisible]
 * @property { string } [title]
 */

/** @type { React.FC<ModalProps> } */
export const Modal = ({
  title = '',
  isVisible = false,
  maxWidth = '1368px',
  maxHeight = '95vh',
  customCss = [],
  customHeaderCss = [],
  customCloseIconCss = [],
  customTitleCss = [],
  children,
  onCloseClick,
  ...other
}) => {
  const className = 'modal'
  const duration = 200

  useEffect(() => {
    const bodyElement = document.querySelector('body')
    if (isVisible) {
      bodyElement.style = 'overflow:hidden;'
    } else {
      bodyElement.style = ''
    }
    return () => {
      bodyElement.style = ''
    }
  }, [isVisible])

  const containerMaxWidth = useMemo(() => (css`
    max-width: ${maxWidth};
    max-height: ${maxHeight};
    ${scrollBar(5)}
    overflow-y: auto;
    margin: auto;
  `), [maxWidth, maxHeight])

  return (
    <Portal>
      {isVisible && <Backdrop css={backdrop} onClick={onCloseClick} />}

      <CSSTransition
        in={isVisible}
        timeout={duration}
        classNames={className}
        css={transition(className, duration)}
        unmountOnExit
      >
        <div {...other} css={[container, ...customCss]}>
          <div css={containerMaxWidth}>
            <div css={[header, flexCenteredSpaceBetweenRow, ...customHeaderCss]}>
              <H1 css={[noMargin, ...customTitleCss]}>{title}</H1>
              <CloseIcon css={[closeIcon, ...customCloseIconCss]} onClick={onCloseClick} />
            </div>
            <div css={content}>{children}</div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  )
}
