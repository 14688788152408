import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import {
  RefundedTicketsListResponse,
  RefundedTicketsSearchParams,
  RefundedTicketsListAttributesToExport,
  RefundedTicketsGetAttributesToExport
} from '@/types/domains/Refund'

const refundedTicketsService = authenticatedService('v1/refund_tickets_report')

type ListParams = {
  searchParams: RefundedTicketsSearchParams
  pagination: MetaOnRequest
}

const listRefunds = ({
  searchParams,
  pagination
}: ListParams): Promise<RefundedTicketsListResponse> => {
  return refundedTicketsService
    .get({
      ...cleanPayload(searchParams),
      ...pagination
    })
    .then(extractData)
}

const totalizers = ({ searchParams }: ListParams) => {
  return refundedTicketsService
    .get(cleanPayload(searchParams), '/totalizers')
    .then(extractData)
}

const listAttributesToExport = (
  lang: string
): Promise<RefundedTicketsListAttributesToExport> => {
  return refundedTicketsService
    .get({}, `/${lang}/attributes_to_export`)
    .then(extractData)
}

const getAttributesToExport = ({
  format,
  wantedFields,
  filters
}: RefundedTicketsGetAttributesToExport): Promise<RefundedTicketsListAttributesToExport> => {
  return refundedTicketsService
    .get(
      {
        ...cleanPayload({
          ...filters,
          wanted_fields: wantedFields
        })
      },
      `${format}`,
      { responseType: format === 'xlsx' ? 'arraybuffer' : undefined }
    )
    .then(extractData)
}

refundedTicketsService.setServiceMethods({
  listRefunds,
  totalizers,
  listAttributesToExport,
  getAttributesToExport
})

export const refundedTicketsDomain = refundedTicketsService.initialize
