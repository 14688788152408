// @ts-check
import { compose } from './compose'

/**
 * @param { String } [creditCardNumber=''] unformatted cc number
 * @returns { Boolean } is American Express Card or not
 */
export const checkIfIsAmex = (creditCardNumber = '') => {
  return /^3[47][0-9]+$/gi.test(creditCardNumber.replace(/\D+/g, ''))
}

/**
 * Formats a credit card number into what we came to prefer to read
 * @param { String } [creditCardNumber=''] unformatted cc number
 * @returns { String } formatted credit card number
 */
export const formatCreditCard = (creditCardNumber = '') => {
  const isAmex = checkIfIsAmex(creditCardNumber)
  return isAmex
    ? creditCardNumber.replace(/^(X*)([\dX]{4})([\dX]{6,})([\dX]{5})$/, '$2 $3 $4')
    : creditCardNumber.replace(/^([\dX]{4})([\dX]{4})([\dX]{4})([\dX]{4})([\d]*)$/, '$1 $2 $3 $4 $5').trim()
}

/**
 * Masks given value to look like a credit card number, either formatting it, or rejecting it's characters
 * @param { String } [creditCardNumber = ''] value to format
 * @returns { String } String in format XXXX XXXX XXXX XXXX
 */
export const maskCreditCard = (creditCardNumber = '') => {
  const isAmex = checkIfIsAmex(creditCardNumber)
  return formatCreditCard(
    creditCardNumber
      .replace(/\D/g, '')
      .replace(/(\d{19})[\w\W]*/, '$1')
      .padStart(isAmex ? 15 : 16, 'X')
  )
}

/**
 * Checks if index is odd, if it is, double the value
 * @param { Number } value value to multiply
 * @param { Number } index index to check if odd
 * @returns { Number } result of checking and multiplying
 */
const doubleOnOddIndex = (value, index) => index % 2 !== 0 ? value * 2 : value

/**
 * If `value` is bigger than one digit, make it one digit again
 * @param { Number } value value to decrease
 * @returns { Number } single digit number
 */
const decreaseExtraValue = (value) => value > 9 ? value - 9 : value

/**
 * curried add to add two numbers
 * @param { Number } x number to add
 * @returns { function (Number) : Number } second function to actually add something
 */
const add = x => y => x + y
/**
 * Validates a credit card based in no rules or whatsoever
 * @param { String } [creditCardNumber=''] credit card numer to validate
 * @returns { Boolean } wether it's valid or not
 */
export function validateCreditCard (creditCardNumber = '') {
  const creditCardArray = creditCardNumber
    .replace(/\D/g, '')
    .split('')
    .map(Number)
    .reverse()

  if (creditCardArray.length < 13 || creditCardArray.length > 19) {
    return false
  }

  if (creditCardArray.every((number, _idx, arr) => number === arr[0])) {
    return false
  }

  return (
    creditCardArray.reduce(
      (acc, curr, index) =>
        compose(add(acc), compose(decreaseExtraValue, doubleOnOddIndex))(
          curr,
          index
        ),
      0
    ) % 10 === 0
  )
}
