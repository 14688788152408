/**
 * Makes a time into at least a four digit time
 * @private
 * @param { String } time raw time
 * @return { String } at least four digit time
 */
const padTime = time => time.replace(/\D/g, '').padStart(4, '0')

/**
 * Formats a String into time format but no limit for the numbers cause it's not a clock hour
 * @global
 * @param { String } [time=''] time string
 * @returns { String } formatted duration
 */
export const maskDuration = (time = '') => padTime(time).replace(/\w*(\d\d)(\d\d)$/i, '$1:$2')

/**
 * Converts the minutes just like a microwave does
 * @global
 * @param { String } [time=''] duration
 * @return { String } duration with formatted stuff
 */
export const treatDurationMinutes = (time = '') => {
  const matchedTime = padTime(time).match(/\w*(\d\d)(\d\d)$/i)
  const hours = matchedTime[1]
  const minutes = matchedTime[2]
  return Number(minutes) > 59 ? `${String(Number(hours) + 1)}:${String(Number(minutes) - 60)}` : `${hours}:${minutes}`
}
