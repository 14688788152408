/**
 * Create a .csv file based on a big string
 * @param { String } data value to be parsed as a csv
 */
export const exportCsv = (fileName = 'file', data) => {
  const encodeURIData = encodeURIComponent(data)
  const csvContent = `data:text/csv;charset=utf-8,\uFEFF${encodeURIData}`
  const link = document.createElement('a')
  link.setAttribute('href', csvContent)
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
}
