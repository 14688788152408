import { css } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { flexCenteredSpaceBetweenRow } from '../../../assets/styles/layout'
import { BREAK_POINTS } from '../../Layout'

export const header = css`
  ${flexCenteredSpaceBetweenRow};
  padding: 30px;
  cursor: pointer;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const indicatorStyle = css`
  min-width: 35px;
  background-color: ${colors.gray8};
  color: ${colors.white};
  font-size: 22px;
  font-weight: 600;
`

export const completed = css`
  background-color: ${colors.green3};
`

export const titleStyle = css`
  margin: 0 15px;
`

export const topicsStyle = css`
  color: ${colors.gray5};
  font-size: 12px;
  font-weight: 500;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    margin-top: 10px;
  }
`

export const toolTipStyle = css`
  margin-right: 15px;
`
