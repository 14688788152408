/**
 * Convert color hexadecimal string to standard RGB, a value between 0 and 1
 * @param { String } colorHexString The hexadecimal string color
 * @returns { Number[] } Standard rgb value
 */
const convertColorToStandardRGBArray = colorHexString => {
  return colorHexString
    .replace('#', '')
    .substring(0, 6)
    .match(/.{2}/g)
    .map(pigment => parseInt(pigment, 16) / 255)
}

/**
 * Calculate a hue luminance
 * @param { Number } standardRGB The color standard RGB value
 * @returns { Number } color luminance
 */
const calculateHueLuminance = standardRGB => {
  return standardRGB <= 0.03928 ? standardRGB / 12.92 : Math.pow((standardRGB + 0.055) / 1.055, 2.4)
}

/**
 * Calculate the color luminance
 * @param { Number } red Color red hue
 * @param { Number } green Color green hue
 * @param { Number } blue Color blue hue
 * @returns { Number } The color luminance
 */
function calculateColorLuminance (red, green, blue) {
  const [redLuminance, greenLuminance, blueLuminance] = [red, green, blue].map(calculateHueLuminance)
  return redLuminance * 0.2126 + greenLuminance * 0.7152 + blueLuminance * 0.0722
}

/**
 * Calculate the contrast between two colors
 * https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 * @param { Number[] } backgroundRgb Background RGB hue decomposition
 * @param { Number[] } foregroundRgb Foreground RGB hue decomposition
 * @returns { Number } The calculated contrast between the two colors
 */
function calculateColorContrast (backgroundRgb, foregroundRgb) {
  const backgroundLuminance = calculateColorLuminance(backgroundRgb[0], backgroundRgb[1], backgroundRgb[2])
  const foregroundLuminance = calculateColorLuminance(foregroundRgb[0], foregroundRgb[1], foregroundRgb[2])
  const brightest = Math.max(backgroundLuminance, foregroundLuminance)
  const darkest = Math.min(backgroundLuminance, foregroundLuminance)
  return (brightest + 0.05) / (darkest + 0.05)
}

/**
 * Calculate the color that has most contrast with the background
 * @param { String } bgColor The background color
 * @param { String } lightColor A lighter option of color
 * @param { String } darkColor A darker option of color
 * @returns { String } The color that has most contrast
 */
export const pickTextColorBasedOnBgColor = (bgColor, lightColor, darkColor) => {
  const bgStandard = convertColorToStandardRGBArray(bgColor)
  const lightStandard = convertColorToStandardRGBArray(lightColor)
  const darkStandard = convertColorToStandardRGBArray(darkColor)

  const lightContrast = calculateColorContrast(bgStandard, lightStandard)
  const darkContrast = calculateColorContrast(bgStandard, darkStandard)

  return lightContrast > darkContrast ? lightColor : darkColor
}
