import styled from '@emotion/styled'

import { depth } from '../../utils/depthBuilder'
import { colors } from '../../assets/styles/colors'

export const PageButton = styled.button`
  width: 2rem;
  height: 2rem;
  min-width: min-content;
  padding: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: var(--round-element-radius);
  border: 0;

  background: transparent;
  font-size: 0.875rem;

  &.selected {
    ${depth(2, 'light')}

    background: ${colors.white};
  }
`
