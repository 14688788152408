/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

const barStyle = css`
  height: 8px;
  border-radius: 4px;
`

const backgroundStyle = css`
  width: 100%;
  display: inline-block;
  background: ${colors.gray12};
  ${barStyle}
`

const progressBar = (fillPercentage, background) => css`
  width: ${fillPercentage}%;
  content: "";
  background: ${background};
  ${barStyle}
`

export const ProgressBar = ({ fillPercentage = '0', background, customCss = [], ...others }) => (
  <div css={[backgroundStyle, ...customCss]} {...others}>
    <div css={progressBar(fillPercentage, background)} />
  </div>
)

export const SuccessProgressBar = ({ fillPercentage = '0' }) => (
  <ProgressBar fillPercentage={fillPercentage} background={colors.greenGradient1} />
)

export const DangerProgressBar = ({ fillPercentage = '0' }) => (
  <ProgressBar fillPercentage={fillPercentage} background={colors.redGradient1} />
)
