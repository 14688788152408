import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'

export const bindingBarContentWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1ch;
  font-size: 14px;
  color: ${colors.gray3};
  font-weight: 600;
  flex-wrap: wrap;

  .selection__count {
    color: ${colors.indigo1};
    min-width: 1.375rem;
    height: 1.375rem;
    border-radius: var(--small-round-element-radius, 12px);
    padding: 0.1875rem 0.25rem;
    background-color: ${colors.indigo3};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1ch;
    flex-wrap: wrap;

    li {
      color: ${colors.indigo2};

      &:not(:last-of-type)::after {
        content: ',';
        color: ${colors.gray3};
      }
    }
  }
`
