/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { flexRowCentered } from '../../assets/styles/layout'
import { TooltipOnHover } from '../Tooltip/Tooltip'
import { InfoIcon } from '../Icons/Icons'
import { colors } from '../../assets/styles/colors'

const circle = (size = 30) => css`
  width: ${size}px;
  height: ${size}px;
  border-radius: var(--round-element-radius, 50%);
  padding: 0;
`

const overflowHidden = css`
  overflow: hidden;
`

const backgroundImage = (size = 30, image) => css`
  background: url(${image}) center;
  background-size: ${size}px;
`

const background = color => css`
  background: ${color};
`

const icon = css`
  color: ${colors.gray2};
  border: 1px solid ${colors.gray2};
  border-radius: var(--round-element-radius, 100%);
  transform: rotateX(180deg);

  &:hover {
    color: ${colors.white};
    border-color: ${colors.primary};
    background-color: ${colors.primary};
  }
`

export const Thumbnail = ({ customCss = [], ...props }) => (
  <div css={[flexRowCentered, overflowHidden, ...customCss]} {...props} />
)

export const CircleThumbnail = ({ size, image, ...other }) => (
  <Thumbnail {...other} css={[circle(size), backgroundImage(size, image)]} />
)

export const Swatch = ({ color, size = 30, ...other }) => (
  <CircleThumbnail size={size} {...other} css={background(color)} />
)

export const InfoSwatch = ({ tooltip = '', size, customTooltip = [], ...other }) => (
  <TooltipOnHover text={tooltip} size={size} tooltipStyle={customTooltip}>
    <CircleThumbnail size={20} css={icon} {...other}>
      <InfoIcon />
    </CircleThumbnail>
  </TooltipOnHover>
)
