import { css } from '@emotion/core'

export const centerPosition = css`
  transform: translateX(-50%);
  margin-left: 50%;
  left: 0;
  right: auto;
`

export const resetPosition = css`
  align-items:unset;
  justify-content:unset;
`

export const shortenFields = css`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`

export const truncateField = (size) => css`
  ${shortenFields}
  width: ${size};
`
