import { colors } from '../../../assets/styles/colors'
import { globals } from '../../../assets/styles/variables'
import { css } from '@emotion/core'

const roundedRightCorners = css`
  border-radius: 0 10px 10px 0;
`

const roundedLeftCorners = css`
  border-radius: 10px 0 0 10px;
`

export const tableCell = css`
  border: 1px solid transparent;
  border-color: transparent;
  font-size: 14px;
  vertical-align: middle;
  text-align: left;
  background: ${colors.white};
  color: ${globals.textColor};
  white-space: nowrap;
  padding: 20px;
  font-weight: 500;

  &:last-of-type {
    ${roundedRightCorners};
  }

  &:first-of-type {
    ${roundedLeftCorners};
  }
`
