import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { depth } from '../../utils/depthBuilder'
import { colors } from '../../assets/styles/colors'

export const MIN_TABLE_MOBILE_BREAKPOINT = 480

export const classContains = classNameFragment =>
  `[class*="${classNameFragment}"]`

const parseBreakpointProp = props =>
  Math.max(props.mobileBreakpoint, MIN_TABLE_MOBILE_BREAKPOINT)

const filterProps = {
  shouldForwardProp (prop) {
    return !['columnTemplate', 'mobileBreakpoint', 'customCss'].includes(prop)
  }
}

export const TableWrapper = styled(motion.div, filterProps)`
  --radius: 0.625rem;
  --column-template: ${props => props.columnTemplate};
  --table-padding: 1.875rem;

  --header-row-display: grid;
  --label-background: rgba(0, 0, 0, 0.045);
  --highlight-transition: 150ms ease-in-out;

  --row-padding: 0.5rem;
  --row-margin: 0;
  --row-border: none;
  --row-even-background: ${colors.gray14};
  --row-odd-background: ${colors.white};
  --row-hover-background: ${colors.gray11};

  --cell-column-template: 1fr;
  --cell-padding: 0.75rem;

  --row-annex-border: 1px solid ${colors.gray7};
  --row-annex-padding: calc(var(--row-padding) + var(--cell-padding));
  --row-annex-margin: 0 var(--row-annex-padding) var(--row-annex-padding);

  --mobile-decorators-content: none;
  --mobile-ref-width: 20ch;
  --mobile-ref-display: none;
  --mobile-sorting-display: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #2d2d2d;
  line-height: 1.25;
  border-collapse: collapse;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray11};
  padding: var(--table-padding);
  border-radius: var(--radius);
  font-size: 0.875rem;
  position: relative;

  &,
  * {
    box-sizing: border-box;
  }

  *,
  :before,
  :after {
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }

  @media (max-width: ${parseBreakpointProp}px) {
    --column-template: 1fr;

    --header-row-display: none;

    --row-padding: 0;
    --row-margin: 1rem 0;
    --row-border: 1px solid ${colors.gray7};
    --row-even-background: ${colors.gray15};
    --row-hover-background: ${colors.gray14};

    --cell-column-template: var(--mobile-ref-width) 1fr;
    --cell-padding: 1rem;

    --row-annex-padding: var(--cell-padding);
    --row-annex-margin: var(--row-annex-padding);

    --mobile-decorators-content: '';
    --mobile-ref-display: block;
    --mobile-sorting-display: flex;
  }

  @media (max-width: 480px) {
    --table-padding: 1.25rem;

    --cell-padding: 0.9rem 0.8rem;
    --mobile-ref-width: 15ch;
  }

  @media (max-width: 360px) {
    --table-padding: 0.7rem;
    --cell-padding: 0.8rem 0.65rem;
  }

  .empty_result {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    padding: 50px;
  }

  ${props => props.customCss}
`

export const TableRowAnnexWrapper = styled(motion.div)`
  width: auto;
  position: relative;
  border: var(--row-annex-border);
  border-radius: var(--radius);
  margin: var(--row-annex-margin);
  padding: var(--row-annex-padding);
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color var(--highlight-transition);
`

const tableRowStyle = css`
  display: grid;
  width: 100%;
  grid-template-columns: var(--column-template);
  padding: var(--row-padding);
  border-radius: var(--radius);
  transition: background-color var(--highlight-transition);
`

export const TableRowContainer = styled(motion.li)`
  margin: var(--row-margin);
  border: var(--row-border);
  border-radius: var(--radius);
  padding: 0.01px 0;
  transition: background-color var(--highlight-transition);
  background-color: ${colors.white};

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &:nth-of-type(even) {
    background-color: var(--row-even-background);
  }
  &:nth-of-type(odd) {
    background-color: var(--row-odd-background);
  }
  &:hover {
    background-color: var(--row-hover-background);
  }
`

export const TableRowWrapper = styled(motion.div)`
  ${tableRowStyle}

  &:last-of-type .TableCellWrapper:last-of-type::before {
    border-radius: 0 0 var(--radius) var(--radius);
  }
  &:last-of-type .TableCellWrapper:last-of-type .TableCellRef {
    border-radius: 0 0 0 var(--radius);
  }
`

export const TableHeaderRow = styled(motion.div)`
  ${tableRowStyle}
  background-color: var(--label-background);
  font-weight: bold;
  color: ${colors.gray3};
  border-radius: var(--radius);
  display: var(--header-row-display);
`

export const TableBody = styled(motion.ul)`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const tableCellStyle = css`
  padding: var(--cell-padding);
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  &:last-of-type {
    padding-right: 0;
  }
`

// Styles for mobile table cells decorators (highlit hover and even cells)
// can be enabled by adding a content to the ::before pseudo element (default value: `var(--mobile-decorators-content)`)
const nonHeaderCellStyle = css`
  position: relative;

  &::before {
    content: var(--mobile-decorators-content);
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 0;
    background-color: transparent;
    transition: background-color var(--highlight-transition);
  }

  &:nth-of-type(even) {
    &::before {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  &:hover {
    &::before {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  &:first-of-type::before {
    border-radius: var(--radius) var(--radius) 0 0;
  }
`

export const TableCell = styled.div`
  ${tableCellStyle}
`

export const TableCellWrapper = styled.div`
  ${nonHeaderCellStyle}

  align-items: center;
  margin: auto 0;

  display: grid;
  width: 100%;
  grid-template-columns: var(--cell-column-template);

  & > * {
    border-radius: none;
  }

  &:first-of-type {
    .TableCellRef {
      border-radius: var(--radius) 0 0 0;
    }
  }

  .TableCell {
    display: inline;
    overflow: hidden;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const TableCellRef = styled.div`
  ${tableCellStyle}

  display: var(--mobile-ref-display);
  font-weight: bold;
  color: ${colors.gray3};
`

// Styles for sortable table headers
// arrows can be toggled by adding the classes sortable, sorted-asc, or sort-desc
const sortableStyles = css`
  &.sortable,
  &.sorted-asc,
  &.sorted-desc {
    cursor: pointer;
    position: relative;
    padding-left: 3.5ch;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 0.8ch;
    width: 0.8ch;
    border: 0 solid ${colors.gray3};
    transform: rotate(45deg);
    left: var(--cell-padding);
  }
  &::before {
    border-width: 1.5px 0 0 1.5px;
    margin-bottom: 1.2ch;
  }
  &::after {
    border-width: 0 1.5px 1.5px 0;
    margin-top: 0.8ch;
  }
  &:not(.sortable) {
    cursor: default;
    &::before,
    &::after {
      content: none;
    }
  }
  &.sorted-desc {
    &::before {
      content: none;
    }
    &::after {
      content: '';
      margin-top: 0;
      margin-bottom: 0.65ch;
    }
  }
  &.sorted-asc {
    &::after {
      content: none;
    }
    &::before {
      content: '';
      margin-bottom: 0;
      margin-top: 0.2ch;
    }
  }
`

export const TableHeaderWrapper = styled.div`
  ${tableCellStyle}
  transition: background-color var(--highlight-transition);
  border-radius: var(--radius);

  &.sortable {
    &:hover {
      background-color: ${colors.gray8};
    }

    ${sortableStyles}
  }
`

export const MobileSortButton = styled(motion.button)`
  display: var(--mobile-sorting-display);
  align-items: center;
  color: ${colors.gray3};
  margin: 0.5rem;
  background-color: ${colors.gray12};
  border-radius: var(--radius);
  padding: 0.5rem;
  margin: -0.5rem 0 1rem auto;
  z-index: 2;
  cursor: default;

  > svg {
    margin-right: 0.5rem;
  }

  > p {
    margin-right: 0.25rem;
  }

  &.sortable {
    cursor: pointer;
    &:hover {
      background-color: ${colors.gray8};
    }

    &::after {
      content: '';
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      border: 1px solid ${colors.gray4};
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      margin: 0 0.25rem;
      transition: transform 300ms, margin 300ms;
      margin-bottom: 0.25rem;
    }

    &.open::after {
      transform: rotate(225deg);
      margin-top: 0.1rem;
      margin-bottom: 0;
    }
  }
`

export const MobileSortTooltip = styled(motion.div)`
  position: absolute;
  top: 2.5rem;
  right: 0;
  background-color: ${colors.white};
  color: ${colors.gray3};
  margin: var(--table-padding);
  padding: 0.5rem;
  border-radius: var(--radius);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: 1px solid ${colors.gray10};
  overflow: auto;
  max-height: calc(100vh - 5rem);
  transform-origin: top right;
  ${depth({ depthValue: 8 })}
`

export const MobileSortTooltipOption = styled.button`
  background-color: ${colors.white};
  padding: 0.5rem;
  border-radius: var(--radius);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

  > p {
    margin: 0.17rem 0;
    margin-right: 0.25rem;
    margin-left: 1.75rem;
    &:not(:last-child) {
      margin-left: 0.5rem;
    }
  }
  &:hover {
    background-color: ${colors.gray8};
  }
`

export const MobileSortTooltipBackdrop = styled(motion.button)`
  position: fixed;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: default;
`

export const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;

  .checkbox {
    border: 1px solid ${colors.gray8};
    transition: 0.2s ease-in-out;

    ::after {
      top: 0.4rem;
      left: 0.25rem;
    }
  }
`

export const submitButton = css`
  gap: 10px;
  display: flex;
  min-width: 200px;
  min-height: 40px;
  align-items: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  font-weight: 500;
  margin: 0;
`

export const TableContainer = ({ isSelected = false }) => css`
  max-height: 92.5vh;

  ul {
    overflow-y: scroll;

    ${classContains('TableCellRef')} {
      padding: 5px 10px;
    }
  }

  ${classContains('TableHeader')} {
    padding: 10px 0;
    font-size: 16px;

    &:first-of-type {
      padding-left: 10px;
    }

    &:hover {
      background-color: unset;
    }
  }

  ${classContains('TableCell')} {
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  @media (min-width: 901px) {
    ${classContains('TableCellWrapper')}:not(:first-of-type) {
      ${classContains('TableCell')} {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 900px) {
    button {
      margin-top: 20px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;

      ${classContains('TableCell column')} {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
      }

      ${classContains('TableCellWrapper')} {
        padding: 0;
      }

      ${classContains('TableCellRef')} {
        padding: 8px 13px;
      }

      .table_row__selected {
        background-color: ${colors.primaryVariant};

        ${classContains('TableCell')} {
          color: white;
        }

        ${classContains('TableCellRef')} {
          padding: 5px 10px;
        }
      }
    }

    .checkbox {
      display: none;
    }
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`
