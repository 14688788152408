/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { tableHeaderCell, tableHeaderCellFixed } from './TableHeaderCell.style'
import { tableCell } from '../TableCell/TableCell.style'

export function TableHeaderCell ({ fixed = false, colSpan, ...props }) {
  return (
    <th
      colSpan={colSpan}
      css={[tableCell, tableHeaderCell, fixed && tableHeaderCellFixed]}
      {...props}
    />
  )
}
