import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { ReservationHistoryParams, ReservationHistoryParamsV2, ReservationHistoryResponseV2 } from '@/types/domains/ReservationHistory'

const reservationHistoryService = authenticatedService('/v1/reservations')

const reservationHistoryServiceV2 = authenticatedService('/v2/reservations')

reservationHistoryService.setServiceMethods({
  get (reservationId: string, params: ReservationHistoryParams) {
    return reservationHistoryService.get(params, `${reservationId}/historic`).then(extractData)
  }
})

reservationHistoryServiceV2.setServiceMethods({
  get (reservationId: string, params: ReservationHistoryParamsV2): Promise<ReservationHistoryResponseV2> {
    return reservationHistoryServiceV2.get(params, `${reservationId}/historic`).then(extractData)
  }
})

export const reservationHistoryDomain = reservationHistoryService.initialize

export const reservationHistoryDomainV2 = reservationHistoryServiceV2.initialize
