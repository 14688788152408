import { authenticatedService } from '@/interfaces/authenticatedService'
import {
  GetBankTransfersParams,
  GetBankTransfersRes,
  PostBankTransferPayload,
  PostBankTransferRes,
  PatchBankTransferPayload,
  PatchBankTransferRes,
  GetBankTransferRes,
  GetBankTransferAuditsRes
} from '@/types/domains/BankTransfer'
import { extractData } from '@/utils/data-extractor'

const bankTransfersService = authenticatedService('v1/bank_transfers')

const getBankTransfers = async (
  searchParams: GetBankTransfersParams
): Promise<GetBankTransfersRes> => {
  return await bankTransfersService.get(searchParams, '').then(extractData)
}

const postBankTransfer = async (postBankTransferPayload: PostBankTransferPayload): Promise<PostBankTransferRes> => {
  return await bankTransfersService.post(
    postBankTransferPayload, ''
  ).then(extractData)
}

const patchBankTransfer = async (
  patchBankTransferPayload: PatchBankTransferPayload,
  bankTransferId: string
): Promise<PatchBankTransferRes> => {
  return await bankTransfersService.patch(
    patchBankTransferPayload, `/${bankTransferId}`
  ).then(extractData)
}

const getBankTransfer = async (
  bankTransferId: string
): Promise<GetBankTransferRes> => {
  return await bankTransfersService.get(
    {}, `/${bankTransferId}`
  ).then(extractData)
}

const getBankTransferAudits = async (
  bankTransferId: string
): Promise<GetBankTransferAuditsRes> => {
  return await bankTransfersService.get(
    {}, `/${bankTransferId}/audit`
  )
}

bankTransfersService.setServiceMethods(
  {
    getBankTransfers,
    postBankTransfer,
    patchBankTransfer,
    getBankTransfer,
    getBankTransferAudits
  }
)

export const bankTransferDomain = bankTransfersService.initialize
