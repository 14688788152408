import { identity } from '@bonitour/common-functions'

export const ConfirmationDialog = (
  message = '',
  successCallback = identity,
  errorCallback = identity
) => {
  return function (data) {
    let promptMessage = message
    if (typeof message === 'function') {
      promptMessage = promptMessage(data)
    }
    if (window.confirm(promptMessage)) {
      return successCallback(data)
    } else {
      return errorCallback(data)
    }
  }
}
