import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { InvoiceId, InvoicePayload, InvoicePayment, InvoiceIdPayment, Invoice, InvoiceSearchParams, InvoiceGetByIdPayload, InvoiceEditInfo, EditInvoiceResponse } from '@/types/domains/Invoice'
import { convertToFormData } from '@/utils/form-data'
import { parseError } from '@/utils/parse-error'
import { ErrorMap } from '@/types/domains/Error'
import { cleanPayload } from '@/utils/clean-payload'

const invoiceService = authenticatedService('v1')

const InvoiceErrorMapping: ErrorMap = {
  'uc_approve_invoice_payment::overpayment': () => 'Valor excede o total da fatura',
  'financial::overpayment': () => 'Total do pagamento não pode ser maior que o total da fatura'
}

type ListParams = { searchParams: InvoiceSearchParams; pagination: MetaOnRequest }

invoiceService.setServiceMethods({
  getInvoiceRevenues ({ searchParams, pagination }: ListParams): Promise<Invoice> {
    return invoiceService.get({
      ...cleanPayload(searchParams),
      ...pagination
    }, 'invoices-revenues').then(extractData)
  },
  getInvoiceRevenuesTotals ({ searchParams, pagination }: ListParams): Promise<Invoice> {
    return invoiceService.get({ ...cleanPayload(searchParams), ...pagination }, 'invoices-revenues-totalizers').then(extractData)
  },
  getInvoiceDebts ({ searchParams, pagination }: ListParams): Promise<Invoice> {
    return invoiceService.get({
      ...cleanPayload(searchParams),
      ...pagination
    }, 'invoices-debts').then(extractData)
  },
  getById (invoiceId: string): Promise<InvoiceGetByIdPayload> {
    return invoiceService.get({}, `invoices/${invoiceId}`).then(extractData)
  },
  getInvoiceDebtsTotals ({ searchParams, pagination }: ListParams): Promise<Invoice> {
    return invoiceService.get({ ...cleanPayload(searchParams), ...pagination }, 'invoices-debts-totalizers').then(extractData)
  },
  getInvoiceItems (invoiceId: string, meta: MetaOnRequest = {}): Promise<any> {
    return invoiceService.get(meta, `invoices/${invoiceId}/invoice_items`).then(extractData)
  },
  getInvoiceItemsExportColumn (): Promise<any> {
    return invoiceService.get({}, 'invoices/invoice_items/pt-br/attributes_to_export').then(extractData)
  },
  getInvoiceItemsExportCSV (invoiceId: string, exportColumns = {}): Promise<any> {
    return invoiceService.get({ ...cleanPayload(exportColumns) }, `invoices/invoice_items/${invoiceId}/csv`).then(extractData)
  },
  updateInvoice (invoiceId: InvoiceId, payload: InvoicePayload): Promise<void> {
    return invoiceService.patchFormData(convertToFormData(payload), `invoices/${invoiceId}`).then(extractData)
  },
  sendInvoice (invoiceId: InvoiceId, payload: InvoicePayload): Promise<void> {
    return invoiceService.putFormData(convertToFormData(payload), `invoices/${invoiceId}/send`).then(extractData)
  },
  payInvoice (invoiceId: InvoiceId, payload: InvoicePayment) {
    return invoiceService.postFormData(convertToFormData(payload), `invoices/${invoiceId}/invoice_payments`)
      .catch(parseError(InvoiceErrorMapping))
  },
  payOffInvoice (invoiceId: InvoiceId) {
    return invoiceService.patch({}, `invoices/${invoiceId}/pay_off`)
  },
  approvePayment (invoiceId: InvoiceId, invoicePaymentId: InvoiceIdPayment) {
    return invoiceService.patch({}, `invoices/${invoiceId}/invoice_payments/${invoicePaymentId}/approve`)
      .catch(parseError(InvoiceErrorMapping))
  },
  rejectPayment (invoiceId: InvoiceId, invoicePaymentId: InvoiceIdPayment) {
    return invoiceService.patch({}, `invoices/${invoiceId}/invoice_payments/${invoicePaymentId}/reject`)
      .catch(parseError(InvoiceErrorMapping))
  },
  editInvoice (invoiceId: InvoiceId, invoicePaymentId: InvoiceIdPayment, invoiceEditinfo: InvoiceEditInfo): Promise<EditInvoiceResponse> {
    return invoiceService.patch(invoiceEditinfo, `invoices/${invoiceId}/invoice_payments/${invoicePaymentId}/bank_accounts`)
      .then(extractData)
  }
})

export const invoiceDomain = invoiceService.initialize
