import { authenticatedService } from '@/interfaces/authenticatedService'
import { Category, CommuniqueCreation, CommuniqueResponse, CommuniqueQuery } from '@/types/domains/Communiques'
import { extractData } from '@/utils/data-extractor'

const communiquesCategoriesService = authenticatedService('/v1/ally_communiques/categories')
const communiquesNotificationsService = authenticatedService('/v1/fcm/send_push_notification')
const companyCommuniquesService = authenticatedService('/v1/ally_communiques/company_communiques')

const listCategories = (): Promise<Category[]> => communiquesCategoriesService.get().then(extractData)

const create = (communique: CommuniqueCreation): Promise<any> => {
  return communiquesNotificationsService.post(communique)
    .then(extractData)
}

const list = (queryParams: CommuniqueQuery): Promise<CommuniqueResponse> => {
  return companyCommuniquesService.get(queryParams)
    .then(extractData)
}

communiquesCategoriesService.setServiceMethods({ listCategories })
communiquesNotificationsService.setServiceMethods({ create })
companyCommuniquesService.setServiceMethods({ list })

export const communiquesCategoriesDomain = communiquesCategoriesService.initialize
export const communiquesNotificationsDomain = communiquesNotificationsService.initialize
export const companyCommuniquesDomain = companyCommuniquesService.initialize
