import {
  MONTH_ABREV_LIST,
  MONTH_LIST,
  DAY_OF_WEEK_LIST,
  daysMonthDistribution
} from '../constants/date'

/** @type {(date: Date, options: {abbreviated?: boolean, lang?: string}) => string} */
export function normalizeMonth (date, options = {}) {
  const { abbreviated = false, lang = 'pt_BR' } = options

  const monthIndex = date.getMonth()

  const monthAbrevList = MONTH_ABREV_LIST[lang] || MONTH_ABREV_LIST.pt_BR
  const monthList = MONTH_LIST[lang] || MONTH_LIST.pt_BR

  return abbreviated ? monthAbrevList[monthIndex] : monthList[monthIndex]
}

export function normalizeDay (date) {
  return date
    .getDate()
    .toString()
    .padStart(2, '0')
}

/** @type {(date: Date, options: {lang?: string}) => string} */
export function normalizeWeekday (date, options = {}) {
  const { lang = 'pt_BR' } = options
  const dayIndex = date.getDay()
  const dayOfWeekList = DAY_OF_WEEK_LIST[lang] || DAY_OF_WEEK_LIST.pt_BR

  return dayOfWeekList[dayIndex]
}

export const calendarMap = rangeArray => rangeArray.map((label, index) => ({ label, number: index + 1 }))

export const splitDate = (date = new Date()) => {
  return (date instanceof Date ? date : new Date(date || 0))
    .toISOString()
    .slice(0, 10)
    .split('-')
    .map(Number)
}

export const firstWeekdayInMonth = (year, month) => new Date(year, month - 1, 1).getDay() + 1
export const isLeapYear = year => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
export const daysInMonth = (currentYear, currentMonth) => {
  if (currentMonth === 2 && isLeapYear(currentYear)) return 29
  return daysMonthDistribution[currentMonth - 1]
}
