/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import { colors } from '../../../assets/styles/colors'
import { scrollBar } from '../../../assets/styles/addons'
import { genericContainer } from './Layout.style'

const body = css`
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    background-color: ${colors.gray13};
    ${scrollBar()}
  }
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
  }
`

const maxWidthContainer = css`
  ${genericContainer}
  flex-direction: column;
  align-self: center;
  width: 100%;
  padding: 20px;
  padding-bottom: 15px;
  box-sizing: border-box;
`

export const Content = ({ children, customCss = [], ...props }) => {
  return (
    <>
      <Global styles={body} />
      <div css={[maxWidthContainer, ...customCss]} {...props}>
        {children}
      </div>
    </>
  )
}
