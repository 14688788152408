const weightRegex = /^(\d{3})(\d{1})$/g
const weightMask = '$1,$2'

/**
 * Formats a 3 length number into human readable Weight
 * @param { String } [weight=''] number of 3 digits length
 * @returns { String } formatted weight
 */
export const formatWeight = (weight = '') => weight.replace(weightRegex, weightMask)

/**
 * Masks given value to weight, either formatting it, or rejecting it's characters
 * @param { String } [value=''] value to format
 * @returns { String } String in format (\d{2}),(\d{1})
 */
export const maskWeight = (value = '') =>
  formatWeight(
    value
      .replace(/[^\d.,]/g, '')
      .replace(/(\d{1,3})[.,]?(\d?)$/, '$1,$2')
      .replace(/,$/, '')
  )
