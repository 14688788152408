import { ErrorMap } from '@/types/domains/Error'
import { authenticatedService } from '@/interfaces/authenticatedService'
import { ForgotPassword, InviteSignup, Login, ResetPassword, Signup } from '@/types/domains/Auth'
import { parseError } from '@/utils/parse-error'
import { convertToFormData } from '@/utils/form-data'

const authService = authenticatedService('')

const ResetPasswordErrorMapping: ErrorMap = {
  'authentication::user::reset_password_token_expired': () => 'Token inválido ou expirado',
  'account::user::reset_password_token_expired': () => 'Token inválido ou expirado',
  'authentication::user::password::translation missing: errors_en.codes.too_short': () => 'Senha muito curta, informe uma senha mais forte'
}

authService.setServiceMethods({
  userInviteSignup (payload: InviteSignup) {
    return authService.post(payload, 'user_invite_signup')
  },
  resetPassword (payload: ResetPassword) {
    return authService.post(payload, 'passwords/reset_password')
      .catch(parseError(ResetPasswordErrorMapping))
  },
  forgotPassword (payload: ForgotPassword) {
    return authService.post(payload, 'passwords/forgot_password')
  },
  login (payload: Login | { authorization: string }) {
    if ('authorization' in payload) {
      return authService.post({}, 'login', {
        headers: {
          Authorization: payload.authorization
        }
      })
    }
    return authService.post(payload, 'login')
  },
  oauthCallback (payload: { temp_token: string }) {
    return authService.get(payload, 'login/external_providers/oauth_callback')
  },
  signup (payload: Signup) {
    return authService.postFormData(convertToFormData(payload), 'signup')
  },
  switchCompany (companyId: string) {
    return authService.patch({ company_id: companyId }, 'sessions/switch_company')
  }
})

export const authDomain = authService.initialize
