import { authenticatedService } from '@/interfaces/authenticatedService'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { DigitalAccountMovementSearchParams, DigitalAccountMovementListResponse } from '@/types/domains/DigitalAccountMovement'

const digitalAccountMovementService = authenticatedService('v1/bank_account_movements')

type ListParams = { searchParams: DigitalAccountMovementSearchParams; pagination: MetaOnRequest }

const list = ({ searchParams, pagination }: ListParams): Promise<DigitalAccountMovementListResponse> => {
  return digitalAccountMovementService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }).then(extractData)
}

digitalAccountMovementService.setServiceMethods({
  list
})

export const digitalAccountMovementDomain = digitalAccountMovementService.initialize
